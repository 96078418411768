import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  nome: "",
  nome_fant: "",
  endereco: "",
  numero: "",
  complemento: "",
  bairro: "",
  referencia: "",
  cidade: 0,
  cep: "",
  classif_cli: 0,
  fone1: "",
  fone2: "",
  fone3: "",
  fax: "",
  whatsapp: "",
  id_tipo_cli: null,
  email: "",
  pessoa: "F",
  cpf_cnpj: "",
  identidade: "",
  razao_soc_nfe: "",
  insc_est: "",
  insc_produtor_rural: "",
  obs: "",
  status: true,
  bloqueado: false,
  id_repr_externo: null,
  emite_nfe_mobile: false,
  revenda: false,
  contato_princ: "",
  id_rede: null,
  cob_conta_banc: null,
  cob_gerar_desp_financ: true,
  perc_desc_financ: 0,
  gera_boleto_automaticamente: true,
  obs_fixa_nfe: "",
  data_desde: new Date(),
  obrig_ord_compra_nf: false,
  id_ramo_atividade: null,
  lim_cheque: null,
  tem_limite_contas_receber: false,
  lim_crediario: null,
  tipo_venda: "N",
  observ_financ_especial: null,
  data_aniver: null,
  campos_personalizados: [],
  obs_app_venda: null,
  // gera_nfse_na_geracao_boleto: false,
};

export const cadastroClientes = createSlice({
  name: "cadastro_clientes",
  initialState: initialState,
  reducers: {
    init: () => initialState,
    setup: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setNome: (state, action) => {
      state.nome = action.payload;
    },
    setNomeFant: (state, action) => {
      state.nome_fant = action.payload;
    },
    setEndereco: (state, action) => {
      state.endereco = action.payload;
    },
    setNumero: (state, action) => {
      state.numero = action.payload;
    },
    setComplemento: (state, action) => {
      state.complemento = action.payload;
    },
    setBairro: (state, action) => {
      state.bairro = action.payload;
    },
    setReferencia: (state, action) => {
      state.referencia = action.payload;
    },
    setCidade: (state, action) => {
      state.cidade = action.payload;
    },
    setCep: (state, action) => {
      state.cep = action.payload;
    },
    setClassifCli: (state, action) => {
      state.classif_cli = action.payload;
    },
    setFone1: (state, action) => {
      state.fone1 = action.payload;
    },
    setFone2: (state, action) => {
      state.fone2 = action.payload;
    },
    setFone3: (state, action) => {
      state.fone3 = action.payload;
    },
    setFax: (state, action) => {
      state.fax = action.payload;
    },
    setWhatsapp: (state, action) => {
      state.whatsapp = action.payload;
    },
    setIdTipoCli: (state, action) => {
      state.id_tipo_cli = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setPessoa: (state, action) => {
      state.pessoa = action.payload;
      state.cpf_cnpj = "";
      state.insc_est = "";
      state.identidade = "";
      state.id_ramo_atividade = null;
    },
    setCpfCnpj: (state, action) => {
      state.cpf_cnpj = action.payload;
    },
    setIdentidade: (state, action) => {
      state.identidade = action.payload;
    },
    setInscEst: (state, action) => {
      state.insc_est = action.payload;
    },
    setInscProdutorRural: (state, action) => {
      state.insc_produtor_rural = action.payload;
    },
    setObs: (state, action) => {
      state.obs = action.payload;
    },
    setObsFixaNfe: (state, action) => {
      state.obs_fixa_nfe = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setBloqueado: (state, action) => {
      state.bloqueado = action.payload;
    },
    setIdReprExterno: (state, action) => {
      state.id_repr_externo = action.payload;
    },
    setEmiteNfeMobile: (state, action) => {
      state.emite_nfe_mobile = action.payload;
    },
    setRevenda: (state, action) => {
      state.revenda = action.payload;
    },
    setRazaoSocNfe: (state, action) => {
      state.razao_soc_nfe = action.payload;
    },
    setContatoPrinc: (state, action) => {
      state.contato_princ = action.payload;
    },
    setIdRede: (state, action) => {
      state.id_rede = action.payload;
    },
    setCamposPersonalizados: (state, action) => {
      state.campos_personalizados = action.payload;
    },
    setCobContaBanc: (state, action) => {
      state.cob_conta_banc = action.payload;
    },
    setCobGerarDespFinanc: (state, action) => {
      state.cob_gerar_desp_financ = action.payload;
    },
    setPercDescFinanc: (state, action) => {
      state.perc_desc_financ = action.payload;
    },
    setGeraBoletoAutomaticamente: (state, action) => {
      state.gera_boleto_automaticamente = action.payload;
    },
    setDataDesde: (state, action) => {
      state.data_desde = action.payload;
    },
    setObrigOrdCompraNf: (state, action) => {
      state.obrig_ord_compra_nf = action.payload;
    },
    setIdRamoAtividade: (state, action) => {
      state.id_ramo_atividade = action.payload;
    },
    setLimCheque: (state, action) => {
      state.lim_cheque = action.payload;
    },
    setTemLimiteContasReceber: (state, action) => {
      state.tem_limite_contas_receber = action.payload;
    },
    setLimCrediario: (state, action) => {
      state.lim_crediario = action.payload;
    },
    setTipoVenda: (state, action) => {
      state.tipo_venda = action.payload;
    },
    setObservFinancEspecial: (state, action) => {
      state.observ_financ_especial = action.payload;
    },
    setDataAniver: (state, action) => {
      state.data_aniver = action.payload;
    },
    setObsAppVenda: (state, action) => {
      state.obs_app_venda = action.payload;
    },
    // setGeraNfseNaGeracaoBoleto: (state, action) => {
    //   state.gera_nfse_na_geracao_boleto = action.payload;
    // },
  },
});

export const {
  init,
  setup,
  setNome,
  setNomeFant,
  setEndereco,
  setNumero,
  setComplemento,
  setBairro,
  setReferencia,
  setCidade,
  setCep,
  setClassifCli,
  setFone1,
  setFone2,
  setFone3,
  setFax,
  setWhatsapp,
  setIdTipoCli,
  setEmail,
  setPessoa,
  setCpfCnpj,
  setIdentidade,
  setInscEst,
  setInscProdutorRural,
  setObs,
  setObsFixaNfe,
  setStatus,
  setBloqueado,
  setCamposPersonalizados,
  setIdPlanoContratado,
  setIdReprExterno,
  setEmiteNfeMobile,
  setRevenda,
  setRazaoSocNfe,
  setContatoPrinc,
  setCobContaBanc,
  setCobGerarDespFinanc,
  setGeraBoletoAutomaticamente,
  setDataDesde,
  setObrigOrdCompraNf,
  setIdRamoAtividade,
  setIdRede,
  setPercDescFinanc,
  setLimCheque,
  setTemLimiteContasReceber,
  setLimCrediario,
  setTipoVenda,
  setObservFinancEspecial,
  setDataAniver,
  setObsAppVenda,
  // setGeraNfseNaGeracaoBoleto,
} = cadastroClientes.actions;

export default cadastroClientes.reducer;
