import React, { useState } from "react";
import { Row } from "reactstrap";
import { ModalBase } from "../../../../../../components/ModalBase";
import RemessaBancariaService from "../../../../../../services/bancario/RemessaBancariaService";

export const ConfirmarReabRemessaModal = ({ id, notifyEvent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const reabrirRemessa = async () => {
    setLoading(true);
    const [ok] = await RemessaBancariaService.reabrirRemessa(id);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  return (
    <>
      <div id={`reab-rem-${id}`} onClick={() => toggle()} />
      {isOpen && (
        <ModalBase
          isOpen={isOpen}
          size="md"
          title="Reabertura de Remessa"
          onConfirm={reabrirRemessa}
          loadingConfirm={loading}
          toggle={toggle}
          autoFocus
        >
          <Row className="justify-content-center px-2">
            <p
              style={{ textAlign: "left", paddingInline: "5px" }}
              className="mb-0"
            >
              Advertência!
              <br />
              Caso o boleto tenha sido enviado e registrado no banco, é
              necessário que você entre no Internet Banking e cancele o boleto
              no sistema do banco.
              <br />
              Atenção: o cliente pode ter recebido a cobrança do boleto que você
              está reabrindo.
              <br />
              <br />
              Tem certeza de que deseja reabrir a remessa {id}?
            </p>
          </Row>
        </ModalBase>
      )}
    </>
  );
};
