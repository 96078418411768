import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  FixedField,
  FormButton,
  FormCheckbox,
  MaskedInput,
  NumberInput,
  TextInput,
  UnlockToEdit,
} from "../../../../../../components";
import { Row } from "reactstrap";
import UteisService from "../../../../../../services/uteis/UteisService";
import { formatNumber, roundFloat } from "../../../../../../coreUtils";
import { toastr } from "react-redux-toastr";
import PesqProduto from "../../../../../../components/form/pesq_produto/PesqProduto";
import NotaFiscalConsumidorService from "../../../../../../services/docs_eletron/NotaFiscalConsumidorService";

export const IncluirItemNFConsForm = forwardRef(
  (
    {
      idCliente,
      finalidadeOperacao,
      notifyEvent,
      verificarIncluirCab,
      formaSistemaTribut,
      regimeTribut,
    },
    ref
  ) => {
    // Controle
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const refPesqProd = useRef();
    const refDescAdic = useRef();
    // Dados do Item
    const [idProduto, setIdProduto] = useState(0);
    const [nomeProduto, setNomeProduto] = useState("");
    const [cfop, setCfop] = useState("");
    const [descricaoAdicional, setDescricaoAdicional] = useState("");
    const [unidade, setUnidade] = useState("");
    const [ncm, setNcm] = useState("");
    const [vlrUnit, setVlrUnit] = useState(0);
    const [quantidade, setQuantidade] = useState(0);
    const [vlrDesc, setVlrDesc] = useState(0);
    const [vlrItem, setVlrItem] = useState(0);
    const [vlrTotProd, setVlrTotProd] = useState(0);
    const [verDescNfe, setVerDescNfe] = useState(false);
    const [vlrTotal, setVlrTotal] = useState(0);
    const [impostos, setImpostos] = useState({});
    const semProduto = [0, null, undefined].includes(idProduto);
    const semCliente = [0, null, undefined].includes(idCliente);

    const calcularImpostos = async (
      _idProduto,
      _quantidade,
      _vlrItem,
      _cfop
    ) => {
      if (_idProduto && _quantidade > 0 && vlrItem > 0) {
        if (formaSistemaTribut === "CFOP") {
          if (["", null, undefined].includes(_cfop)) {
            toastr.warning(
              "Atenção",
              "Por Favor, Verifique a CFOP do Item no Cadastro de Produto."
            );
            return false;
          }
        } else {
          _cfop = "";
        }
        const _vlrTotal = roundFloat(_vlrItem * _quantidade, 2);

        const trib = await UteisService.calcularImpostosProduto(
          _idProduto,
          idCliente,
          finalidadeOperacao,
          65,
          true,
          _cfop?.trim(),
          _quantidade,
          _vlrTotal,
          0
        );

        if (trib && Object.keys(trib).length > 0) {
          setImpostos(trib);
          if (formaSistemaTribut === "R_TRIB") {
            setCfop(trib.cfop);
          }
          setVlrTotal(roundFloat(parseFloat(trib.vlr_total_com_impostos), 2));
          return true;
        } else {
          setVlrTotal(_vlrTotal);
          return false;
        }
      } else {
        return true;
      }
    };

    const handleSelectProduto = async (
      id,
      nome,
      referencia,
      precoVenda,
      unidade,
      __,
      cfop,
      ncm
    ) => {
      cfop = (cfop ?? "").trim();
      setIdProduto(id);
      setNomeProduto(nome);
      setQuantidade(0);
      setVlrDesc(0);
      setVlrItem(precoVenda);
      setVlrUnit(precoVenda);
      setUnidade(unidade);
      setNcm(ncm);
      handleSetCfop(cfop);
      refPesqProd.current.setDescricao(nome);
      refPesqProd.current.setId(String(id));
      refPesqProd.current.setReferencia(String(referencia));

      calcularImpostos(id, 0, precoVenda, cfop, 0, 0);
      setImpostos({});
    };

    const handleSetQuantidade = (v) => {
      setQuantidade(v);
      setVlrItem(vlrUnit);
      setVlrDesc(0);
      setVlrTotProd(roundFloat(vlrItem * v, 2));
      if (v > 0) calcularImpostos(idProduto, v, vlrItem, cfop);
    };

    const handleSetCfop = (v) => {
      setCfop(v);
      if (v !== cfop && v && quantidade > 0) {
        calcularImpostos(idProduto, quantidade, vlrItem, v);
      }
    };

    const handleSetVlrItem = (v) => {
      setVlrItem(v);

      const vDescUnit = v < vlrUnit ? vlrUnit - v : 0;
      const vTotDesc = vDescUnit * quantidade;
      const vTotProd = v * quantidade;
      setVlrDesc(roundFloat(vTotDesc, 2));
      setVlrTotProd(roundFloat(vTotProd, 2));

      if (vTotProd > 0) calcularImpostos(idProduto, quantidade, v, cfop);
    };

    const handleSetVlrDesc = (v) => {
      let vItem = 0;
      let vTotProd = vlrUnit * quantidade;
      if (v <= vTotProd) {
        setVlrDesc(v);
        vTotProd -= v;
        vItem = vTotProd / quantidade;
        setVlrItem(roundFloat(vItem, 4));
        setVlrTotProd(roundFloat(vTotProd, 2));
        calcularImpostos(idProduto, quantidade, vItem, cfop);
      } else {
        toastr.warning(
          "Atenção",
          "O Valor de Desconto não pode ser superior ao Total Bruto de Produtos"
        );
        setVlrDesc(0);
        setVlrItem(0);
        setVlrTotProd(0);
      }
    };

    const handleSetVlrTotProd = (v) => {
      setVlrTotProd(v);
      if (quantidade > 0)
        calcularImpostos(idProduto, quantidade, vlrItem, cfop);
    };

    const handleSubmit = async () => {
      if (semCliente) {
        toastr.warning("Atenção", "Por favor, informe o Cliente da NFCe");
        return false;
      }

      if ([0, null, undefined].includes(idProduto)) {
        toastr.warning("Atenção", "Por Favor, informe o Produto.");
        return false;
      }

      let idRegraTribut = 0;

      if (formaSistemaTribut === "CFOP") {
        if (["", null, undefined].includes(cfop)) {
          toastr.warning("Atenção", "Por Favor, informe a CFOP do Item.");
          return false;
        }
      } else {
        idRegraTribut = impostos.id_regra;
      }

      if ([0, null, undefined].includes(quantidade)) {
        toastr.warning("Atenção", "Por Favor, informe a Quantidade do Item.");
        return false;
      }

      setLoadingSubmit(true);

      const okTrib = await calcularImpostos(
        idProduto,
        quantidade,
        vlrItem,
        cfop
      );
      if (!okTrib) {
        setLoadingSubmit(false);
        return false;
      }

      const idNfce = await verificarIncluirCab();

      if ([0, null, undefined].includes(idNfce)) {
        setLoadingSubmit(false);
        return false;
      }

      const commonPayload = {
        id_nfce: idNfce,
        id_produto: idProduto,
        nome_produto: `${nomeProduto} ${descricaoAdicional}`.trim(),
        cfop: cfop,
        quantidade: quantidade,
        ncm: ncm,
        vlr_unit: vlrUnit,
        vlr_item: vlrItem,
        // perc_desc: percDesc,
        vlr_desc: vlrDesc,
        // vlr_acre: vlrAcre,
        vlr_total: vlrTotal,
        vlr_seguro: 0,
        vlr_outros: 0,
        vlr_tot_prod: vlrTotProd,
        mostrar_desc: verDescNfe,
        id_regra_tribut: idRegraTribut,
        unidade: unidade,
        impostos: {
          base_calc_icms: parseFloat(impostos.base_calc_icms),
          sit_trib_icms: impostos.sit_trib_icms,
          origem: impostos.origem,
          modal_bc_icms: impostos.modal_bc_icms,
          perc_icms: parseFloat(impostos.perc_icms),
          perc_red_bc_icms: parseFloat(impostos.perc_red_bc_icms),
          vlr_icms: parseFloat(impostos.vlr_icms),
          base_calc_icms_st: parseFloat(impostos.base_calc_icms_st),
          perc_red_icms_st: parseFloat(impostos.perc_red_icms_st),
          modal_bc_icms_st: impostos.modal_bc_icms_st,
          perc_icms_st: parseFloat(impostos.perc_icms_st),
          mva_icms_st: parseFloat(impostos.mva_icms_st),
          vlr_icms_st: parseFloat(impostos.vlr_icms_st),
          vlr_cred_icms: parseFloat(impostos.vlr_cred_icms),
          perc_cred_icms: parseFloat(impostos.perc_cred_icms),
          vlr_icms_oper: parseFloat(impostos.vlr_icms_oper),
          vlr_difer_icms: parseFloat(impostos.vlr_difer_icms),
          perc_difer_icms: parseFloat(impostos.perc_difer_icms),
          base_calc_ipi: parseFloat(impostos.base_calc_ipi),
          sit_trib_ipi: impostos.sit_trib_ipi,
          enquad_ipi: impostos.enquad_ipi,
          perc_ipi: parseFloat(impostos.perc_ipi),
          vlr_ipi: parseFloat(impostos.vlr_ipi),
          base_calc_pis: parseFloat(impostos.base_calc_pis),
          sit_trib_pis: impostos.sit_trib_pis,
          perc_pis: parseFloat(impostos.perc_pis),
          vlr_pis: parseFloat(impostos.vlr_pis),
          base_calc_cofins: parseFloat(impostos.base_calc_cofins),
          sit_trib_cofins: impostos.sit_trib_cofins,
          perc_cofins: parseFloat(impostos.perc_cofins),
          vlr_cofins: parseFloat(impostos.vlr_cofins),
          vlr_fcp: parseFloat(impostos.vlr_fcp),
          base_calc_fcp: parseFloat(impostos.base_calc_fcp),
          perc_deson_icms: parseFloat(impostos.perc_deson_icms),
          vlr_deson_icms: parseFloat(impostos.vlr_deson_icms),
          base_calc_st_ret: parseFloat(impostos.base_calc_st_ret),
          vlr_icms_substituto: parseFloat(impostos.vlr_icms_substituto),
          vlr_icms_st_ret: parseFloat(impostos.vlr_icms_st_ret),
          perc_red_bc_icms_efet: parseFloat(impostos.perc_red_bc_icms_efet),
          perc_icms_efet: parseFloat(impostos.perc_icms_efet),
          base_calc_icms_efet: parseFloat(impostos.base_calc_icms_efet),
          vlr_icms_efet: parseFloat(impostos.vlr_icms_efet),
          c_benef: impostos.c_benef,
          base_calc_icms_uf_dest: parseFloat(impostos.base_calc_icms_uf_dest),
          base_calc_fcp_uf_dest: parseFloat(impostos.base_calc_fcp_uf_dest),
          perc_fcp_uf_dest: parseFloat(impostos.perc_fcp_uf_dest),
          aliq_icms_uf_dest: parseFloat(impostos.aliq_icms_uf_dest),
          aliq_icms_inter: parseFloat(impostos.aliq_icms_inter),
          aliq_icms_inter_partilha: parseFloat(
            impostos.aliq_icms_inter_partilha
          ),
          vlr_fcp_uf_dest: parseFloat(impostos.vlr_fcp_uf_dest),
          vlr_icms_uf_dest: parseFloat(impostos.vlr_icms_uf_dest),
          vlr_icms_uf_remet: parseFloat(impostos.vlr_icms_uf_remet),
          perc_red_bc_icms_st: parseFloat(impostos.perc_red_bc_icms_st),
          mva_st_ret: parseFloat(impostos.mva_st_ret),
          perc_reten_pis: parseFloat(impostos.perc_reten_pis),
          vlr_reten_pis: parseFloat(impostos.vlr_reten_pis),
          perc_reten_cofins: parseFloat(impostos.perc_reten_cofins),
          vlr_reten_cofins: parseFloat(impostos.vlr_reten_cofins),
          perc_reten_csll: parseFloat(impostos.perc_reten_csll),
          vlr_reten_csll: parseFloat(impostos.vlr_reten_csll),
          bc_reten_irpj: parseFloat(impostos.bc_reten_irpj),
          perc_reten_irpj: parseFloat(impostos.perc_reten_irpj),
          vlr_reten_irpj: parseFloat(impostos.vlr_reten_irpj),
        },
      };

      const [ok] = await NotaFiscalConsumidorService.inclusao.incluirItem(
        commonPayload
      );
      if (ok) {
        limparDados();
        refPesqProd.current.focus();
        notifyEvent(idNfce);
      }
      setLoadingSubmit(false);
    };

    const limparDados = () => {
      setIdProduto(0);
      setNomeProduto("");
      setCfop("");
      setDescricaoAdicional("");
      setUnidade("");
      setNcm("");
      setVlrUnit(0);
      setQuantidade(0);
      setVlrDesc(0);
      setVlrItem(0);
      setVlrTotProd(0);
      setVerDescNfe(false);
      setVlrTotal(0);
      setImpostos({});
      refPesqProd.current.clear();
    };

    const focarProximoCampo = () => {
      refDescAdic.current.focus();
    };

    useImperativeHandle(ref, () => ({
      handleSubmit: handleSubmit,
    }));

    return (
      <>
        <Row>
          <PesqProduto
            md={8}
            onConfirm={handleSelectProduto}
            selected={idProduto}
            ref={refPesqProd}
            selectNextField={focarProximoCampo}
            disabled={semCliente}
            disabledHint="Selecione o Destinatário da Nota Fiscal"
            auxAsLabel
            showShortcut
            idCliente={idCliente}
          />
          <FixedField
            label="CFOP"
            value={cfop}
            horizontal
            divStyle={{ paddingTop: "1.5rem" }}
          />
        </Row>
        <Row>
          <TextInput
            md={6}
            label="Descrição Adicional"
            value={descricaoAdicional}
            onChange={(e, v) => setDescricaoAdicional(v)}
            disabled={semProduto}
            ref={refDescAdic}
          />
          <FixedField label="Unidade" value={unidade} />
          <UnlockToEdit enabled={!semProduto}>
            <MaskedInput
              md={2}
              label="NCM"
              value={ncm}
              onChange={(e, v) => setNcm(v)}
              mask="99999999"
              maskChar={null}
              disabled={semProduto}
              inputStyle={{ textAlign: "center" }}
            />
          </UnlockToEdit>
          <FixedField
            divClassName="ml-auto"
            label="Valor Unitário"
            value={formatNumber(vlrUnit, true, 2)}
            align="right"
          />
        </Row>
        <Row>
          <NumberInput
            md={2}
            label="Quantidade"
            value={quantidade}
            onChange={handleSetQuantidade}
            decimalPlaces={2}
            disabled={semProduto}
          />
          <NumberInput
            md={2}
            label="Desconto"
            value={vlrDesc}
            onChange={handleSetVlrDesc}
            disabled={semProduto}
          />
          <FormCheckbox
            divClassName="px-0"
            label="Mostrar na NF"
            checked={verDescNfe}
            onChange={() => setVerDescNfe(!verDescNfe)}
            disabled={semProduto}
          />
          <NumberInput
            md={2}
            label="Vlr. Item"
            value={vlrItem}
            onChange={handleSetVlrItem}
            decimalPlaces={4}
            disabled={semProduto}
          />
          <NumberInput
            md={2}
            label="Total Produtos"
            value={vlrTotProd}
            onChange={handleSetVlrTotProd}
            disabled={semProduto}
          />
          <FixedField
            divClassName="ml-auto"
            label="Valor Total"
            value={formatNumber(vlrTotal, true, 2)}
            boldValue
            align="right"
          />
        </Row>
        <Row className="mb-2">
          {regimeTribut !== "SIM" && (
            <NumberInput
              md={2}
              label="Vlr. ICMS"
              value={parseFloat(impostos.vlr_icms ?? 0)}
              disabled
            />
          )}
          <FormButton
            padded={false}
            md="auto"
            color="success"
            onClick={handleSubmit}
            loading={loadingSubmit}
            disabled={semProduto}
            disabledHint={"Selecione o Produto"}
            divClassName="ml-auto"
          >
            F6 - Incluir Produto
          </FormButton>
        </Row>
      </>
    );
  }
);
