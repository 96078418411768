import React, { useState, useEffect, useRef } from "react";
import { ManifEletronMDFeGrid } from "./components/ManifEletronMDFeGrid";
import { OutrasOpcoes } from "./components/OutrasOpcoes";
import ManifEletronMDFeService from "../../../../services/faturamento/ManifEletronMDFeService";
import {
  BotaoPesquisar,
  ComboBox,
  FiltroPeriodoDatas,
  FormButton,
  LinkButton,
  PageContainer,
} from "../../../../components";
import { EventosAlertasMDFe } from "./components/EventosAlertasMDFe";
import { Card, Row } from "reactstrap";
import { MODAL_ACTIONS, downloadFileFromBlob } from "../../../../coreUtils";
import { toastr } from "react-redux-toastr";
import { conemb } from "./conemb/conemb";
import {
  cadastroCondutorMDFeRoute,
  cadastroPercursoMDFeRoute,
  cadastroTomadorMDFeRoute,
  incluirAlterarMDFeRoute,
} from "../../../../routes/modules/docsEletron";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AjustarDuplicidadeModal } from "./components/AjustarDuplicidadeModal";

const situacoes = [
  { label: "Todas as Situações", value: "T" },
  { label: "Aguardando Emissão e Salvos", value: "AGES" },
  { label: "Aguardando Emissão", value: "AGEM" },
  { label: "Manifestos Emitidos", value: "EMIT" },
  { label: "Manifestos Encerrados", value: "ENCE" },
  { label: "Manifestos Cancelados", value: "CANC" },
  { label: "Manifestos Inutilizados", value: "INUT" },
  { label: "Em Digitação (Salvos)", value: "DIGI" },
];

export const ManifestoEletronicoMDFe = ({ location }) => {
  const [dataIni, setDataIni] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [situacao, setSituacao] = useState(situacoes[1].value);
  const [dados, setDados] = useState([]);
  const [lastSelected, setLastSelected] = useState(null);
  const [selected, setSelected] = useState([]);
  const [loadingPesquisar, setLoadingPesquisar] = useState(false);
  const [loadingEmissao, setLoadingEmissao] = useState(false);
  const [loadingConemb, setLoadingConemb] = useState(false);
  const [eventosAlertasOpen, setEventosAlertasOpen] = useState(false);
  const [ajustarDuplicidadeOpen, setAjustarDuplicidadeOpen] = useState(false);
  const history = useHistory();

  const filtroPeriodoRef = useRef();

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const carregarDados = async () => {
    if (
      !["AGES", "AGEM"].includes(situacao) &&
      !(dataIni instanceof Date) &&
      !(dataFim instanceof Date)
    ) {
      toastr.warning(
        "Atenção",
        "Por favor, informe datas válidas de início e fim."
      );
      return false;
    }

    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
      situacao: situacao !== "T" ? situacao : null,
    };

    setLoadingPesquisar(true);
    const [ok, ret] = await ManifEletronMDFeService.listar(params);
    setDados(ok ? ret : []);
    setLoadingPesquisar(false);
  };

  const emitirMDFe = async () => {
    setLoadingEmissao(true);
    const [ok] = await ManifEletronMDFeService.emitir(lastSelected);
    if (ok) {
      await ManifEletronMDFeService.imprimir(lastSelected);
      setLastSelected(null);
      await carregarDados();
    }
    setLoadingEmissao(false);
  };

  const enviarConemb = async () => {
    setLoadingConemb(true);
    const [ok, ret] = await ManifEletronMDFeService.buscarDadosConemb(selected);
    if (ok && ret.length > 0) {
      const [blob, filename] = conemb(ret);
      let idManifEmail = ret.filter(
        (e) => (e.email_conemb_tomador ?? "").trim() !== ""
      );
      idManifEmail = idManifEmail?.length > 0 ? idManifEmail[0].id_mdfe : null;

      if (![0, null, undefined].includes(idManifEmail)) {
        let formData = new FormData();
        formData.append("arquivo", blob, filename);
        await ManifEletronMDFeService.enviarEmailConemb(idManifEmail, formData);
      } else {
        downloadFileFromBlob(blob, filename);
        toastr.warning(
          "Atenção",
          "O Tomador não possui e-mail para envio do CONEMB.\n" +
            "O arquivo foi gerado e baixado."
        );
      }
    }
    setLoadingConemb(false);
  };

  const handleSetSelected = (v, isSelected) => {
    setSelected(
      isSelected ? [...selected, v] : selected.filter((e) => e !== v)
    );
    setLastSelected(isSelected ? v : null);
  };

  const handleSelectAll = (isSelected) => {
    setSelected(isSelected ? dados.map((e) => e.id) : []);
  };

  const handleSetStatus = (v) => {
    setSituacao(v);
    if (filtroPeriodoRef.current) {
      if (!["AGES", "AGEM"].includes(v)) {
        if (!(dataIni instanceof Date) || !(dataFim instanceof Date)) {
          filtroPeriodoRef.current.selecionarPeriodo("HOJE");
        }
      } else {
        // Limpa filtro de data
        filtroPeriodoRef.current.selecionarPeriodo(null);
      }
    }
  };

  const eventosAlertasToggle = () => setEventosAlertasOpen(!eventosAlertasOpen);
  const ajustarDuplicidadeToggle = () =>
    setAjustarDuplicidadeOpen(!ajustarDuplicidadeOpen);

  const cancelarMDFe = async (id) => {
    const [ok] = await ManifEletronMDFeService.cancelar(id);
    if (ok) {
      carregarDados();
    }
  };

  const encerrar = async (id) => {
    const [ok] = await ManifEletronMDFeService.encerrar(id);
    if (ok) {
      carregarDados();
    }
  };

  const eventosAlertas = (id) => {
    setLastSelected(id);
    eventosAlertasToggle();
  };

  const corrigirDuplicidade = (id) => {
    setLastSelected(id);
    ajustarDuplicidadeToggle();
  };

  const imprimir = (id) => ManifEletronMDFeService.imprimir(id);

  const alterar = (id, status, webPodeAlterar) => {
    if (!["S", "A"].includes(status)) {
      toastr.warning(
        "Atenção",
        "Somente é possível alterar MDFe's Aguardando Emissão ou Em Digitação."
      );
      return false;
    }

    if (!webPodeAlterar) {
      toastr.warning(
        "Atenção",
        "Somente é possível alterar MDFe's de origem Manual"
      );
      return false;
    }

    history.push(incluirAlterarMDFeRoute.path, {
      id: id,
      action: MODAL_ACTIONS.EDIT,
    });
  };

  useEffect(() => {
    if (location.state?.refresh) {
      carregarDados();
    }
  }, []);

  return (
    <PageContainer
      title="Manifesto Eletrônico - MDFe"
      number="0042"
      topOptions={<OutrasOpcoes />}
      canGoBack
    >
      <Card body>
        <Row>
          <FiltroPeriodoDatas
            defaultOption={null}
            defaultStart={null}
            defaultEnd={true}
            onChange={handleDate}
            ref={filtroPeriodoRef}
          />
          <ComboBox
            md={3}
            label="Situação"
            options={situacoes}
            onChange={(s) => handleSetStatus(s?.value)}
            defaultValue={situacao}
          />
          <BotaoPesquisar
            onClick={carregarDados}
            loading={loadingPesquisar}
            disabled={
              !["AGES", "AGEM"].includes(situacao) &&
              !(dataIni instanceof Date) &&
              !(dataFim instanceof Date)
            }
            disabledHint="Informe datas válidas de início e fim"
          />
        </Row>
        <Row>
          <LinkButton
            pathname={incluirAlterarMDFeRoute.path}
            state={{ action: MODAL_ACTIONS.ADD }}
            md="auto"
            color="info"
          >
            Incluir MDFe
          </LinkButton>
          <EventosAlertasMDFe
            id={lastSelected}
            isOpen={eventosAlertasOpen}
            toggle={eventosAlertasToggle}
          />
          <AjustarDuplicidadeModal
            selected={lastSelected}
            isOpen={ajustarDuplicidadeOpen}
            toggle={ajustarDuplicidadeToggle}
            notifyEvent={carregarDados}
          />
          <FormButton
            md="auto"
            color="success"
            disabled={[0, null, undefined].includes(lastSelected)}
            onClick={emitirMDFe}
            loading={loadingEmissao}
          >
            Emitir MDFe
          </FormButton>
          <FormButton
            md="auto"
            color="secondary"
            disabled={selected?.length === 0}
            disabledHint="Selecione ao menos um manifesto da lista"
            onClick={enviarConemb}
            loading={loadingConemb}
          >
            Enviar CONEMB
          </FormButton>
          <LinkButton
            pathname={cadastroCondutorMDFeRoute.path}
            color="primary"
            md="auto"
            padded={true}
          >
            Condutores
          </LinkButton>
          <LinkButton
            pathname={cadastroPercursoMDFeRoute.path}
            color="primary"
            md="auto"
            padded={true}
          >
            Percursos
          </LinkButton>
          <LinkButton
            pathname={cadastroTomadorMDFeRoute.path}
            color="primary"
            md="auto"
            padded={true}
          >
            Tomadores
          </LinkButton>
        </Row>
      </Card>
      <ManifEletronMDFeGrid
        dados={dados}
        setSelected={handleSetSelected}
        handleSelectAll={handleSelectAll}
        encerrar={encerrar}
        eventosAlertas={eventosAlertas}
        imprimir={imprimir}
        alterar={alterar}
        cancelar={cancelarMDFe}
        corrigirDuplicidade={corrigirDuplicidade}
      />
    </PageContainer>
  );
};
