import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
} from "react";
import { Row } from "reactstrap";
import {
  FormButton,
  NumberInput,
  TextInput,
  ModalBase,
  FixedField,
} from "../../../../../../components";
import {
  formatNumber,
  formatValueFromAPI,
  MODAL_ACTIONS,
  roundFloat,
} from "../../../../../../coreUtils";
import UteisService from "../../../../../../services/uteis/UteisService";
import PesqProduto from "../../../../../../components/form/pesq_produto/PesqProduto";
import { showWarning } from "../../../../../../components/AlertaModal";
import ModalAlterarTipoItem from "./ModalAlterarTipoItem";

export const TIPO_ITEM_VENDA = "VDA";
export const TIPO_ITEM_TROCA = "TRO";
export const TIPO_ITEM_BONIFICACAO = "BON";

const DESCRICOES_TIPOS = {
  [TIPO_ITEM_VENDA]: "Venda",
  [TIPO_ITEM_TROCA]: "Troca",
  [TIPO_ITEM_BONIFICACAO]: "Bonificação",
};

export const IncluirAlterarProdutoModal = forwardRef(
  (
    {
      idCliente,
      obrigInfoOrdemCompraNfe,
      vlrFrete,
      handleAdd,
      handleEdit,
      itens,
      permiteManipularVlrUnitario,
    },
    ref
  ) => {
    const [action, setAction] = useState(MODAL_ACTIONS.ADD);
    const [isOpen, setIsOpen] = useState(false);
    const [tipo, setTipo] = useState(TIPO_ITEM_VENDA);
    const [idProduto, setIdProduto] = useState(null);
    const [nomeProduto, setNomeProduto] = useState("");
    const [qtdEstoque, setQtdEstoque] = useState(0);
    const [revenda, setRevenda] = useState(false);
    const [nroOrdemCompra, setNroOrdemCompra] = useState(null);
    const [cfop, setCfop] = useState("");
    const [quantidade, setQuantidade] = useState(0);
    const [vlrUnit, setVlrUnit] = useState(0);
    const [vlrItem, setVlrItem] = useState(0);
    const [percDesc, setPercDesc] = useState(0);
    const [vlrTotDesc, setVlrTotDesc] = useState(0);
    const [vlrTotAcre, setVlrTotAcre] = useState(0);
    const [vlrIcmsStUnit, setVlrIcmsStUnit] = useState(0);
    const [vlrTotIcmsSt, setVlrTotIcmsSt] = useState(0);
    const [percIpi, setPercIpi] = useState(0);
    const [vlrTotIpi, setVlrTotIpi] = useState(0);
    const [vlrTotProd, setVlrTotProd] = useState(0);
    const [vlrTotal, setVlrTotal] = useState(0);
    const [vlrTotTroca, setVlrTotTroca] = useState(0);
    const [vlrTotBonificacao, setVlrTotBonificacao] = useState(0);
    const refPesqProd = useRef();
    const quantidadeRef = useRef(null);

    const limparDados = () => {
      setTipo(TIPO_ITEM_VENDA);
      setIdProduto(null);
      setNomeProduto("");
      setQtdEstoque(0);
      setRevenda(false);
      setNroOrdemCompra(null);
      setCfop("");
      setQuantidade(0);
      setVlrUnit(0);
      setVlrItem(0);
      setPercDesc(0);
      setVlrTotDesc(0);
      setVlrTotAcre(0);
      setVlrIcmsStUnit(0);
      setVlrTotIcmsSt(0);
      setPercIpi(0);
      setVlrTotIpi(0);
      setVlrTotProd(0);
      setVlrTotal(0);
      setVlrTotTroca(0);
      setVlrTotBonificacao(0);
      if (refPesqProd.current) {
        refPesqProd.current.clear();
      }
    };

    const carregarDados = (idProdutoEdit, tipoProdutoEdit) => {
      const data = itens.find(
        (e) => e.id_produto === idProdutoEdit && e.tipo === tipoProdutoEdit
      );

      setTipo(data.tipo);
      setIdProduto(data.id_produto);
      setNomeProduto(data.nome_produto);
      setRevenda(data.revenda);
      setNroOrdemCompra(data.nro_ord_compra);
      setCfop(data.cfop);
      setQuantidade(data.quantidade);
      setVlrUnit(data.vlr_unit);
      setVlrItem(data.vlr_item);
      setPercDesc(data.perc_desc);
      setVlrTotDesc(data.vlr_tot_desc);
      setVlrTotAcre(data.vlr_tot_acre);
      setVlrIcmsStUnit(data.vlr_icms_st_unit);
      setVlrTotIcmsSt(data.vlr_tot_icms_st);
      setPercIpi(data.perc_ipi);
      setVlrTotIpi(data.vlr_tot_ipi);
      setVlrTotProd(data.vlr_tot_prod);
      setVlrTotal(data.vlr_total);
      setVlrTotTroca(data.vlr_tot_troca);
      setVlrTotBonificacao(data.vlr_tot_bonificacao);
    };

    const toggle = (action, idProdutoEdit, tipoProdutoEdit) => {
      if (action === MODAL_ACTIONS.EDIT) {
        carregarDados(idProdutoEdit, tipoProdutoEdit);
      }
      setAction(action);
      setIsOpen(!isOpen);
    };

    const calcularImpostos = async (idProduto, quantidade, vlrItem, cfop) => {
      const _vlrTotProd = vlrItem * quantidade;

      if (tipo === "VDA") {
        const trib = await UteisService.calcularImpostosProduto(
          idProduto,
          idCliente,
          "VENDA",
          55,
          true,
          cfop,
          quantidade,
          _vlrTotProd,
          vlrFrete
        );

        if (trib && Object.keys(trib).length > 0) {
          const _vlrIcmsSt = parseFloat(trib.vlr_icms_st);
          const _vlrIpi = parseFloat(trib.vlr_ipi);
          const _percIpi = parseFloat(trib.perc_ipi);

          setVlrIcmsStUnit(roundFloat(_vlrIcmsSt / quantidade, 2));
          setVlrTotIcmsSt(roundFloat(_vlrIcmsSt, 2));
          setPercIpi(roundFloat(_percIpi, 2));
          setVlrTotIpi(roundFloat(_vlrIpi, 2));
          setVlrTotProd(roundFloat(_vlrTotProd, 4));
          setVlrTotal(roundFloat(_vlrTotProd + _vlrIcmsSt + _vlrIpi, 2));
          setVlrTotTroca(0);
          setVlrTotBonificacao(0);
        } else {
          return false;
        }
      } else if (tipo === "TRO") {
        setVlrTotTroca(_vlrTotProd);
      } else if (tipo === "BON") {
        setVlrTotBonificacao(_vlrTotProd);
      }
      return true;
    };

    const handleSelectProduto = async (
      id,
      nome,
      referencia,
      precoVenda,
      unidade,
      custoUe,
      cfop,
      clasFiscal,
      curinga,
      quantidade,
      cod_bar,
      qtd_casas_decimais_qtd,
      qtd_casas_decimais_vlrs,
      tamanho,
      cor
    ) => {
      // Não deixa alterar o produto na alteração do item
      if (action === MODAL_ACTIONS.EDIT) return;

      if (idProduto !== id) {
        if (id) {
          if (await calcularImpostos(id, 1, precoVenda, cfop)) {
            setIdProduto(id);
            setNomeProduto(nome);
            setQtdEstoque(quantidade);
            setVlrUnit(roundFloat(precoVenda, 10));
            setVlrItem(roundFloat(precoVenda, 10));
            setQuantidade(1);
            setPercDesc(0);
            setVlrTotDesc(0);
            setVlrTotAcre(0);
            setCfop(cfop);
            if (refPesqProd.current) {
              refPesqProd.current.setId(String(id));
              refPesqProd.current.setDescricao(nome);
            }
            selectNextField();
          }
        } else {
          limparDados();
        }
      }
    };

    const selectNextField = () => {
      setTimeout(() => {
        if (quantidadeRef.current) {
          quantidadeRef.current.focus();
        }
      }, 35);
    };

    const handleSetQuantidade = async (v) => {
      if (await calcularImpostos(idProduto, v, vlrItem, cfop)) {
        setQuantidade(v);

        if (vlrItem <= vlrUnit) {
          setVlrTotDesc(roundFloat(vlrUnit * (percDesc / 100) * v, 2));
          setVlrTotAcre(0);
        } else {
          setPercDesc(0);
          setVlrTotDesc(0);
          setVlrTotAcre(roundFloat((vlrItem - vlrUnit) * v, 2));
        }
      }
    };

    const handleSetVlrUnit = async (v) => {
      setVlrUnit(v);

      if (vlrItem <= v) {
        const _vlrDesc = v - vlrItem;
        setPercDesc(roundFloat((_vlrDesc / v) * 100, 2));
        setVlrTotDesc(roundFloat(_vlrDesc * quantidade, 2));
        setVlrTotAcre(0);
      } else {
        setPercDesc(0);
        setVlrTotDesc(0);
        setVlrTotAcre(roundFloat((vlrItem - v) * quantidade, 2));
      }
    };

    const handleSetVlrItem = async (v) => {
      if (await calcularImpostos(idProduto, quantidade, v, cfop)) {
        setVlrItem(v);

        if (v <= vlrUnit) {
          const _vlrDesc = vlrUnit - v;
          setPercDesc(roundFloat((_vlrDesc / vlrUnit) * 100, 2));
          setVlrTotDesc(roundFloat(_vlrDesc * quantidade, 2));
          setVlrTotAcre(0);
        } else {
          setPercDesc(0);
          setVlrTotDesc(0);
          setVlrTotAcre(roundFloat((v - vlrUnit) * quantidade, 2));
        }
      }
    };

    const handleSetPercDesc = async (v) => {
      if (v === percDesc) return;
      if (vlrUnit > 0) {
        const _vlrDesc = (v / 100) * vlrUnit;
        const _vlrItem = vlrUnit - _vlrDesc;

        if (await calcularImpostos(idProduto, quantidade, _vlrItem, cfop)) {
          setPercDesc(v);
          setVlrItem(_vlrItem);
          setVlrTotDesc(_vlrDesc * quantidade);
          setVlrTotAcre(0);
        }
      } else {
        setPercDesc(0);
        setVlrTotDesc(0);
        setVlrTotAcre(vlrItem);
      }
    };

    const semProduto = [0, null, undefined].includes(idProduto);

    const handleSubmit = () => {
      if (semProduto) {
        return showWarning("Por favor, selecione um produto.");
      }

      // Verifica se o produto já foi adicionado
      if (action === MODAL_ACTIONS.ADD) {
        const itemJaExiste = itens.find(
          (e) => e.id_produto === idProduto && e.tipo === tipo
        );
        if (itemJaExiste) {
          return showWarning(
            "Esse item já existe no pedido. Altere o tipo ou, se necessário, " +
              "feche a janela de inclusão e altere o item " +
              "clicando no ícone ao final da linha correspondente na grade."
          );
        }
      }

      if (quantidade <= 0) {
        return showWarning("A quantidade não pode ser zero.");
      }

      if (vlrItem < 0) {
        return showWarning("Por favor, verifique o valor do item.");
      }

      const payload = {
        tipo: tipo,
        desc_tipo: DESCRICOES_TIPOS[tipo],
        id_produto: idProduto,
        nome_produto: nomeProduto,
        revenda: revenda,
        cfop: cfop,
        quantidade: quantidade,
        vlr_unit: vlrUnit,
        vlr_item: vlrItem,
        perc_desc: percDesc,
        vlr_tot_desc: vlrTotDesc,
        vlr_tot_acre: vlrTotAcre,
        vlr_icms_st_unit: vlrIcmsStUnit,
        vlr_tot_icms_st: vlrTotIcmsSt,
        perc_ipi: percIpi,
        vlr_tot_ipi: vlrTotIpi,
        vlr_tot_prod: vlrTotProd,
        vlr_total: vlrTotal,
        vlr_tot_troca: vlrTotTroca,
        vlr_tot_bonif: vlrTotBonificacao,
        nro_ord_compra: nroOrdemCompra ?? null,
      };

      if (action === MODAL_ACTIONS.ADD) {
        handleAdd(payload);
      } else {
        handleEdit(payload);
      }

      toggle();
    };

    const atuValoresTrocaTipo = () => {
      if (semProduto) return;

      if (tipo !== TIPO_ITEM_VENDA) {
        setVlrItem(vlrUnit);
        setPercDesc(0);
        setVlrTotDesc(0);
        setVlrTotAcre(0);
        setVlrIcmsStUnit(0);
        setVlrTotIcmsSt(0);
        setPercIpi(0);
        setVlrTotIpi(0);
        setVlrTotProd(0);
        setVlrTotal(0);
        setVlrTotTroca(0);
        setVlrTotBonificacao(0);
        if (tipo === TIPO_ITEM_TROCA) {
          setVlrTotTroca(vlrUnit * quantidade);
        } else if (tipo === TIPO_ITEM_BONIFICACAO) {
          setVlrTotBonificacao(vlrUnit * quantidade);
        }
      } else {
        // Força recalculo dos valores do item
        handleSetQuantidade(quantidade);
      }
    };

    useEffect(atuValoresTrocaTipo, [tipo]);

    useImperativeHandle(ref, () => ({
      toggle: toggle,
    }));

    return (
      <>
        <FormButton
          md="auto"
          divClassName="ml-auto pr-0"
          color="info"
          padded={false}
          onClick={() => toggle(MODAL_ACTIONS.ADD)}
          disabled={[0, null, undefined].includes(idCliente)}
          disabledHint="Selecione o cliente do pedido"
        >
          Adicionar Item
        </FormButton>
        <ModalBase
          isOpen={isOpen}
          toggle={toggle}
          onClosed={limparDados}
          autoFocus={action === MODAL_ACTIONS.EDIT}
          size="lg"
          title={
            action === MODAL_ACTIONS.ADD ? "Adicionar Item" : "Alterar Item"
          }
          number="0034_41"
          footerActions
          onConfirm={handleSubmit}
        >
          <Row>
            {action === MODAL_ACTIONS.ADD ? (
              <>
                <PesqProduto
                  md={8}
                  onConfirm={handleSelectProduto}
                  ref={refPesqProd}
                  selectNextField={selectNextField}
                  onChangeDescricao={setNomeProduto}
                  mostrarAux={false}
                  idCliente={idCliente}
                />
                {!semProduto && (
                  <FixedField
                    label="Estoque"
                    value={formatNumber(qtdEstoque, true, 2, true)}
                    divClassName="pt-4"
                    horizontal
                  />
                )}
              </>
            ) : (
              <FixedField
                md={8}
                label="Produto"
                value={formatValueFromAPI(nomeProduto, idProduto)}
                divClassName="mb-2"
                horizontal
              />
            )}
            <FixedField
              md="auto"
              label="Tipo"
              value={DESCRICOES_TIPOS[tipo]}
              divClassName={`mb-2 ${
                action === MODAL_ACTIONS.ADD ? "pt-4" : ""
              }`}
              horizontal
            />
            {action === MODAL_ACTIONS.ADD && (
              <ModalAlterarTipoItem tipo={tipo} setTipo={setTipo} />
            )}
          </Row>
          <Row>
            <NumberInput
              md={2}
              label="Quantidade"
              value={quantidade}
              onChange={handleSetQuantidade}
              disabled={semProduto}
              decimalPlaces={4}
              ref={quantidadeRef}
            />
            <NumberInput
              md={2}
              label="Vlr. Unitário"
              value={vlrUnit}
              disabled={
                !permiteManipularVlrUnitario || semProduto || tipo !== "VDA"
              }
              decimalPlaces={10}
              onChange={handleSetVlrUnit}
            />
            <NumberInput
              md={2}
              label="Vlr. Item"
              value={vlrItem}
              onChange={handleSetVlrItem}
              disabled={semProduto || tipo !== "VDA"}
              decimalPlaces={10}
            />
            <NumberInput
              md={2}
              label="Perc. Desconto"
              value={percDesc}
              onChange={handleSetPercDesc}
              disabled={semProduto || tipo !== "VDA"}
              isPercentage
            />
            <NumberInput
              md={2}
              label="Vlr. Desconto"
              value={vlrTotDesc}
              disabled
            />
            <NumberInput
              md={2}
              label="Vlr. Acréscimo"
              value={vlrTotAcre}
              disabled
            />
          </Row>
          <Row>
            <NumberInput
              md={2}
              label="Vlr. ICMS ST Unit."
              value={vlrIcmsStUnit}
              disabled
            />
            <NumberInput
              md={2}
              label="Vlr. Tot. ICMS ST"
              value={vlrIcmsStUnit}
              disabled
            />
            <NumberInput
              md={2}
              label="Perc. IPI"
              value={percIpi}
              isPercentage
              disabled
            />
            <NumberInput md={2} label="Vlr. IPI" value={vlrTotIpi} disabled />
            <NumberInput
              md={2}
              label="Vlr. Tot. Prod."
              value={vlrTotProd}
              disabled
              decimalPlaces={4}
            />
            <NumberInput md={2} label="Vlr. Total" value={vlrTotal} disabled />
          </Row>
          <Row>
            <NumberInput
              md={2}
              label="Vlr. Tot. Troca"
              value={vlrTotTroca}
              disabled
              decimalPlaces={4}
            />
            <NumberInput
              md={2}
              label="Vlr. Tot Bonificação"
              value={vlrTotBonificacao}
              disabled
            />
            {obrigInfoOrdemCompraNfe && (
              <TextInput
                md={2}
                label="Nro Ordem Compra"
                value={nroOrdemCompra}
                onChange={(_, v) => setNroOrdemCompra(v ?? null)}
                disabled={semProduto || tipo !== "VDA"}
              />
            )}
          </Row>
        </ModalBase>
      </>
    );
  }
);
