import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import moment from "moment";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Card, Row, UncontrolledTooltip } from "reactstrap";
import {
  AsyncComboBox,
  ComboBox,
  DatePicker,
  Divider,
  FormButton,
  FormCheckbox,
  IntegerFormInput,
  LabelButton,
  Loader,
  NumberInput,
  PageContainer,
  RadioGroup,
  TextInput,
} from "../../../../../components";
import { CamposPerson } from "../../../../../components/CamposPerson";
import { FixedField } from "../../../../../components/FixedField";
import MaskedInput from "../../../../../components/MaskedInput";
import {
  TabBody,
  TabController,
} from "../../../../../components/TabController";
import {
  corDescricaoClassifCli,
  formatarCep,
  formatarCnpj,
  formatarFone,
  getTextColorBg,
  limparNumero,
  MODAL_ACTIONS,
  validarCNPJ,
} from "../../../../../coreUtils";
import {
  setBairro,
  setCamposPersonalizados,
  setCep,
  setCidade,
  setClassifCli,
  setCobGerarDespFinanc,
  setComplemento,
  setContatoPrinc,
  setCpfCnpj,
  setEmail,
  setEmiteNfeMobile,
  setEndereco,
  setFax,
  setFone1,
  setFone2,
  setFone3,
  setGeraBoletoAutomaticamente,
  setIdentidade,
  setIdReprExterno,
  setIdTipoCli,
  setInscEst,
  setInscProdutorRural,
  setNome,
  setNomeFant,
  setNumero,
  setObs,
  setObsFixaNfe,
  setPessoa,
  setReferencia,
  setRazaoSocNfe,
  setRevenda,
  setStatus,
  setBloqueado,
  setup,
  setWhatsapp,
  setDataDesde,
  setObrigOrdCompraNf,
  setIdRamoAtividade,
  setIdRede,
  setPercDescFinanc,
  setLimCheque,
  setLimCrediario,
  setTipoVenda,
  setDataAniver,
  setObservFinancEspecial,
  setTemLimiteContasReceber,
  setObsAppVenda,
  init,
  setCobContaBanc,
  setGeraNfseNaGeracaoBoleto,
} from "./store/cadastroClientesSlice";
import { PreencAutoModal } from "../components/PreencAutoModal";
import { RadioItem } from "../../../../../components/RadioGroup";
import { apiGet } from "../../../../../api";
import { useEffect } from "react";
import CadastroClienteService from "../../../../../services/cadastro/CadastroClienteService";
import store from "./store";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Redirect } from "react-router-dom";
import BotaoPesq from "../../../../../components/form/BotaoPesq";
import CadastroCidadeService from "../../../../../services/cadastro/CadastroCidadeService";
import { cadastroClienteRoute } from "../../../../../routes/modules/cadastro";
import { RiInformationLine } from "react-icons/ri";
import { showError, showWarning } from "../../../../../components/AlertaModal";
import { ContatosClienteModal } from "./components/ContatosClienteModal";
import { docPrintReport } from "../../../../../pdf";
import { CompradorClienteModal } from "./components/CompradorClienteModal";
import { showConfirmation } from "../../../../../components/ConfirmationModal";
import { TransformarClienteCuringaModal } from "./components/TransformarClienteCuringaModal";

const pessoa = [
  {
    label: "Física",
    value: "F",
  },
  {
    label: "Jurídica",
    value: "J",
  },
];
const tipoPreco = [
  {
    label: "Não Especificado",
    value: "N",
  },
  {
    label: "Varejo",
    value: "V",
  },
  {
    label: "Atacado",
    value: "A",
  },
];

const INSC_EST_NAO_CONTRIB = "NAOCONTRIB";

const INSC_EST_ISENTO = "ISENTO";

const IncluirAlterarClienteContainer = forwardRef(({ location }, ref) => {
  const opener = window.opener;
  const windowParameters = window?.parameters
    ? JSON.parse(window?.parameters)
    : {};
  const action =
    location?.state?.action ?? windowParameters.action ?? MODAL_ACTIONS.ADD;
  const selected = location?.state?.selected ?? windowParameters.selected;
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingCep, setLoadingCep] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const history = useHistory();

  const dispatch = useDispatch();
  const state = useSelector((state) => state.cadastroClientes);
  const [rotaAtende, setRotaAtende] = useState("");
  const [params, setParams] = useState({});
  const [ieIsentoNaoCont, setIeIsentoNaoCont] = useState("NOR");
  const [loadingAtuDados, setLoadingAtuDados] = useState(false);

  const licenca = localStorage.getItem("licenca");

  const [transformarCuringaModalOpen, setTransformarCuringaModalOpen] =
    useState(false);

  const carregarParametros = async () => {
    const ret = await apiGet("/tela/cad_cliente/");

    if (Object.keys(ret).length > 0) {
      setParams(ret);
      dispatch(setRevenda(ret.cli_pad_revenda_marcado ?? false));
    }
  };

  const carregarDados = async () => {
    const [ok, ret] = await CadastroClienteService.buscar(selected);

    if (!ok) return false;

    let inscEst = (ret.insc_est ?? "").trim();

    if (ret.pessoa === "J") {
      if (![INSC_EST_NAO_CONTRIB, INSC_EST_ISENTO].includes(inscEst)) {
        inscEst = limparNumero(inscEst);
      }
    }

    const data = {
      nome: ret.nome,
      nome_fant: ret.nome_fant,
      endereco: ret.endereco,
      numero: ret.numero,
      complemento: ret.complemento,
      bairro: ret.bairro,
      referencia: ret.referencia,
      cidade: ret.cidade,
      cep: ret.cep,
      classif_cli: parseInt(ret.classif_cli),
      fone1: ret.fone1,
      fone2: ret.fone2,
      fone3: ret.fone3,
      fax: ret.fax,
      whatsapp: ret.whatsapp,
      id_tipo_cli: ret.id_tipo_cli !== 0 ? ret.id_tipo_cli : null,
      email: ret.email,
      cpf_cnpj: ret.cpf_cnpj ?? "",
      identidade: ret.identidade,
      insc_est: inscEst,
      insc_produtor_rural: ret.insc_produtor_rural,
      razao_soc_nfe: ret.razao_soc_nfe,
      obs: ret.obs,
      obs_fixa_nfe: ret.obs_fixa_nfe,
      status: ret.status,
      bloqueado: ret.bloqueado,
      emite_nfe_mobile: ret.emite_nfe_mobile,
      revenda: ret.revenda,
      campos_personalizados: ret.campos_personalizados?.map((e) => ({
        id_campo: e.id_cab,
        value: e.value,
      })),
      id_repr_externo: ret.id_repr_externo,
      contato_princ: ret.contato_princ,
      id_rede: ret.id_rede,
      data_desde: moment(ret.data_desde),
      cob_conta_banc: ret.cob_conta_banc,
      cob_gerar_desp_financ: ret.cob_gerar_desp_financ,
      perc_desc_financ: parseFloat(ret.perc_desc_financ ?? "0"),
      gera_boleto_automaticamente: ret.gera_boleto_automaticamente,
      obrig_ord_compra_nf: ret.obrig_info_ordem_compra_nfe,
      id_ramo_atividade: ret.id_ramo_ativ !== 0 ? ret.id_ramo_ativ : null,
      lim_cheque: ret.lim_cheque ? parseFloat(ret.lim_cheque) : null,
      tem_limite_contas_receber: ret.tem_limite_contas_receber,
      lim_crediario: ret.lim_crediario ? parseFloat(ret.lim_crediario) : null,
      tipo_venda: (ret.tipo_venda ?? "N").trim() || "N",
      observ_financ_especial: ret.observ_financ_especial,
      data_aniver: moment(ret.data_aniver),
      obs_app_venda: ret.obs_app_venda,
      // gera_nfse_na_geracao_boleto: ret.gera_nfse_na_geracao_boleto,
    };
    if (ret.rota_atende && ret.dias_atende)
      setRotaAtende(
        `${ret.rota_atende} - ${ret.dias_atende
          ?.map((e) => e.dia_semana)
          .join(",")}`
      );

    dispatch(setPessoa(ret.pessoa));
    dispatch(setup(data));
    setIeIsentoNaoCont(
      inscEst === INSC_EST_ISENTO
        ? "ISE"
        : inscEst === INSC_EST_NAO_CONTRIB
        ? "NAO"
        : "NOR"
    );
  };

  const iniciarTela = async () => {
    await carregarParametros();

    if (action === MODAL_ACTIONS.EDIT) {
      await carregarDados();
    }

    setLoading(false);
  };

  const carregarDadosPreencAuto = async (ret) => {
    try {
      const estabelecimento = ret.estabelecimento;

      const razaoSocial = ret.razao_social
        ?.substring(0, 60)
        .trim()
        .toUpperCase();

      const nomeFant =
        estabelecimento.nome_fantasia?.trim().length > 0
          ? estabelecimento.nome_fantasia
          : razaoSocial;

      const endereco = (
        (estabelecimento.tipo_logradouro ?? "") +
        " " +
        estabelecimento.logradouro
      )
        .trim()
        .toUpperCase();

      const obs = `SITUAÇÃO: ${estabelecimento.situacao_cadastral.toUpperCase()}`;

      const inscEstAtiva = estabelecimento.inscricoes_estaduais.find(
        (item) => item.ativo
      );
      let inscEstEstab = "";
      if (inscEstAtiva) {
        inscEstEstab = inscEstAtiva?.inscricao_estadual ?? "";
      }

      inscEstEstab = inscEstEstab.trim();

      if (inscEstEstab === "") {
        handleSetIsentoNaoCont("NAO");
        inscEstEstab = INSC_EST_NAO_CONTRIB;
      } else if (inscEstEstab === INSC_EST_ISENTO) {
        handleSetIsentoNaoCont("ISE");
      }

      const fone1 =
        (estabelecimento.ddd1?.trim() ?? "") +
        (estabelecimento.telefone1?.trim() ?? "");
      const fone2 =
        (estabelecimento.ddd2?.trim() ?? "") +
        (estabelecimento.telefone2?.trim() ?? "");
      const fax =
        (estabelecimento.ddd_fax?.trim() ?? "") +
        (estabelecimento.fax?.trim() ?? "");

      const res = {
        nome: razaoSocial,
        nome_fant: nomeFant?.substring(0, 60).trim().toUpperCase(),
        endereco: endereco?.substring(0, 60).trim().toUpperCase(),
        numero: estabelecimento.numero?.substring(0, 10).trim().toUpperCase(),
        complemento: estabelecimento.complemento
          ?.substring(0, 40)
          .trim()
          .toUpperCase(),
        bairro: estabelecimento.bairro?.substring(0, 40).trim().toUpperCase(),
        cpf_cnpj: formatarCnpj(estabelecimento.cnpj?.substring(0, 18).trim()),
        insc_est: inscEstEstab?.substring(0, 20),
        cep: formatarCep(estabelecimento.cep?.substring(0, 10).trim()),
        fone1: formatarFone(fone1?.substring(0, 18).trim()),
        fone2: formatarFone(fone2?.substring(0, 18).trim()),
        fax: formatarFone(fax?.substring(0, 18).trim()),
        email: estabelecimento.email?.substring(0, 80).trim(),
        obs: obs?.trim(),
      };

      dispatch(setPessoa("J"));

      const [ok, cidade] = await CadastroCidadeService.verificarInserirCidade(
        estabelecimento.cidade.nome,
        estabelecimento.estado.sigla,
        estabelecimento.cidade.ibge_id
      );

      if (ok) {
        dispatch(setCidade(cidade));
      }

      dispatch(setup(res));
      return true;
    } catch (error) {
      showError(
        "Erro ao Importar dados para Preenchimento Automático",
        "ImpPreencAutoFront01",
        error.message
      );
      return false;
    }
  };

  const limparDados = () => {
    dispatch(init());
    setRotaAtende("");
    setIeIsentoNaoCont("NOR");
  };

  const handleSubmit = async () => {
    if ((state.nome ?? "").trim() === "") {
      showWarning("Por favor, informe o Nome do Cliente");
      return false;
    }

    if ([0, null, undefined].includes(state.cidade)) {
      showWarning("Por favor, informe a Cidade do Cliente");
      return false;
    }

    const cpfCnpjFilter = limparNumero(state.cpf_cnpj);
    if (state.pessoa === "F") {
      if (params.cli_obrig_cpf && cpfCnpjFilter.length !== 11) {
        showWarning("É necessário informar o CPF do cliente.");
        return false;
      }
    } else {
      if (params.cli_obrig_cnpj && cpfCnpjFilter.length !== 14) {
        showWarning("É necessário informar o CNPJ do cliente.");
        return false;
      }
    }

    const payloadData = {
      nome: state.nome ?? "",
      nome_fant: state.nome_fant ?? "",
      endereco: state.endereco ?? "",
      numero: state.numero ?? "",
      complemento: state.complemento ?? "",
      bairro: state.bairro ?? "",
      referencia: state.referencia ?? "",
      cidade: state.cidade ?? 0,
      cep: state.cep ?? "",
      classif_cli: state.classif_cli ?? 0,
      fone1: state.fone1 ?? "",
      fone2: state.fone2 ?? "",
      fone3: state.fone3 ?? "",
      fax: state.fax ?? "",
      whatsapp: state.whatsapp ?? "",
      id_tipo_cli: state.id_tipo_cli ?? null,
      email: state.email ?? "",
      pessoa: state.pessoa ?? "F",
      cpf_cnpj: state.cpf_cnpj ?? "",
      identidade: state.identidade ?? "",
      razao_soc_nfe: state.razao_soc_nfe ?? "",
      insc_est: state.insc_est ?? "",
      insc_produtor_rural: state.insc_produtor_rural ?? "",
      obs: state.obs ?? "",
      status: state.status,
      bloqueado: state.bloqueado,
      id_repr_externo: state.id_repr_externo ?? null,
      emite_nfe_mobile: state.emite_nfe_mobile,
      revenda: state.revenda,
      contato_princ: state.contato_princ ?? "",
      id_rede: state.id_rede ?? null,
      cob_conta_banc: state.cob_conta_banc ?? null,
      cob_gerar_desp_financ: state.cob_gerar_desp_financ,
      perc_desc_financ: state.perc_desc_financ ?? 0,
      gera_boleto_automaticamente: state.gera_boleto_automaticamente,
      obs_fixa_nfe: state.obs_fixa_nfe ?? "",
      data_desde: moment(state.data_desde).isValid()
        ? moment(state.data_desde).format("YYYY-MM-DD")
        : null,
      obrig_ord_compra_nf: state.obrig_ord_compra_nf,
      id_ramo_atividade: state.id_ramo_atividade,
      lim_cheque: state.lim_cheque,
      tem_limite_contas_receber: state.tem_limite_contas_receber,
      lim_crediario: state.lim_crediario,
      campos_personalizados: state.campos_personalizados ?? [],
      tipo_venda: state.tipo_venda ?? "N",
      observ_financ_especial: state.observ_financ_especial ?? null,
      data_aniver: moment(state.data_aniver).isValid()
        ? moment(state.data_aniver).format("YYYY-MM-DD")
        : null,
      obs_app_venda: state.obs_app_venda,
      // gera_nfse_na_geracao_boleto: state.gera_nfse_na_geracao_boleto,
    };

    setLoadingSubmit(true);

    const submitFunc =
      action === MODAL_ACTIONS.ADD
        ? CadastroClienteService.incluir
        : CadastroClienteService.alterar;

    const payload =
      action === MODAL_ACTIONS.ADD
        ? { ...payloadData, importacao: false, id_cad_imp: 0 }
        : { ...payloadData, id: selected };

    const [ok, ret] = await submitFunc(payload);

    // se não houve erro no POST/PUT
    if (ok) {
      // se a janela foi aberta pelo ConcatShortcut
      if (opener !== null && history.length === 1) {
        // se inclusão, passa o id incluído. Se alteração, passa o selecionado
        const idRet =
          action === MODAL_ACTIONS.ADD ? ret.id_cadastrado : selected;

        opener.postMessage(
          { id: JSON.parse(window.parameters).id, selected: idRet },
          window.location.origin
        );
        window.close();
      } else {
        // se não, volta para a tela anterior (Cadastro de Cliente)
        setRedirect(true);
      }
    }

    setLoadingSubmit(false);
  };

  const onKeyDown = (e) => {
    if (!e.shiftKey && !e.altKey && !e.ctrlKey && !e.metaKey) {
      if (e.key === "F8") {
        const btPreencAuto = document.getElementById("bt-preenc-auto");
        if (btPreencAuto) {
          btPreencAuto.click();
        }
      }
      if (e.key === "F9") handleSubmit();
      if (
        e.key === "Escape" &&
        opener !== null &&
        history.length === 1 &&
        !loading
      )
        window.close();
    }
  };

  const corClassifCli = corDescricaoClassifCli(state.classif_cli ?? 0).cor;

  const handleSetIsentoNaoCont = (v) => {
    setIeIsentoNaoCont(v);
    dispatch(
      setInscEst(
        v === "ISE" ? INSC_EST_ISENTO : v === "NAO" ? INSC_EST_NAO_CONTRIB : ""
      )
    );
  };

  const handleSetPessoa = (v) => {
    if (v === state.pessoa) return;
    dispatch(setPessoa(v));
    if (v === "F") {
      setIeIsentoNaoCont("NOR");
    }
  };

  const buscarCep = async () => {
    const cep = limparNumero(state.cep);

    if (cep.length < 8) return false;

    setLoadingCep(true);
    const ret = await CadastroClienteService.buscarCep(cep);

    if (Object.keys(ret).length > 0) {
      if (ret.logradouro) dispatch(setEndereco(ret.logradouro));

      if (ret.bairro) dispatch(setBairro(ret.bairro));

      const [ok, cidade] = await CadastroCidadeService.verificarInserirCidade(
        ret.localidade,
        ret.uf,
        ret.ibge
      );

      if (ok) {
        if (cidade) dispatch(setCidade(cidade));
      }
    }
    setLoadingCep(false);
  };

  const atualizarDadosCliente = async () => {
    setLoadingAtuDados(true);
    try {
      const ret = await CadastroClienteService.buscarDadosPreencAuto(
        state.cpf_cnpj
      );

      if (ret) {
        carregarDadosPreencAuto(ret);
      }
    } finally {
      setLoadingAtuDados(false);
    }
  };

  const toggleConfirmaAltDados = () =>
    showConfirmation(
      <>
        Atenção!
        <br />
        As informações do cliente {state.nome} serão alteradas com base na
        receita federal e estadual.
        <br />
        Deseja continuar?
      </>,
      atualizarDadosCliente
    );

  const onActivateExit = () => {
    iniciarTela();

    return () => limparDados();
  };

  useEffect(onActivateExit, []);

  const toggleTransformarCuringaModalOpen = () =>
    setTransformarCuringaModalOpen(!transformarCuringaModalOpen);

  useImperativeHandle(ref, () => ({
    onKeyDown: onKeyDown,
  }));

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: cadastroClienteRoute.path,
          state: { refresh: true },
        }}
      />
    );
  }

  const imprimirFicha = async () => {
    const [ok, ret] = await CadastroClienteService.buscar(selected);
    if (ok) {
      await docPrintReport(
        "/cadastro/ficha_cadastral_cliente/",
        { dados: ret },
        "0040"
      );
    }
  };

  let mdObservacao = 12;
  if (rotaAtende !== "") {
    mdObservacao -= 2;
  }
  if (params.cad_cli_modif_observ_especial) {
    mdObservacao -= 4;
  }

  const semCnpj =
    !state.cpf_cnpj || state.pessoa === "F" || !validarCNPJ(state.cpf_cnpj);

  return loading ? (
    <Loader />
  ) : (
    <>
      <Card body>
        <TabController
          tabClassName="px-2 pt-2"
          contentStyle={{
            height: "670px",
            overflowX: "clip",
            overflowY: "auto",
            marginBottom: "8px",
          }}
        >
          <TabBody title="Cadastro">
            <Row>
              <TextInput
                label="Nome"
                md={8}
                onChange={(e, v) => dispatch(setNome(v))}
                value={state.nome}
                maxLength={60}
                autoFocus
                onKeyDown={(e) => {
                  if (e.ctrlKey && e.which === 89) {
                    // CTRL + Y
                    toggleTransformarCuringaModalOpen();
                  }
                }}
              />
              {action === MODAL_ACTIONS.EDIT && (
                <IntegerFormInput
                  md={2}
                  label="Código"
                  defaultValue={selected}
                  divStyle={{ marginBottom: "11px" }}
                  disabled
                />
              )}
              <FormCheckbox
                label="Ativo"
                checked={state.status}
                onChange={() => dispatch(setStatus(!state.status))}
                name="ativo"
              />
            </Row>
            <Row>
              <TextInput
                label="Nome Fantasia"
                md={8}
                onChange={(e, v) => dispatch(setNomeFant(v))}
                value={state.nome_fant}
                maxLength={60}
              />
            </Row>
            <Row>
              <TextInput
                label="Endereço"
                md={6}
                onChange={(e, v) => dispatch(setEndereco(v))}
                value={state.endereco}
                maxLength={60}
              />
              <TextInput
                label="Número"
                md={2}
                onChange={(e, v) => dispatch(setNumero(v))}
                value={state.numero}
                maxLength={10}
              />
              <TextInput
                label="Complemento"
                md={4}
                onChange={(e, v) => dispatch(setComplemento(v))}
                value={state.complemento}
                maxLength={40}
              />
            </Row>
            <Row>
              <TextInput
                label="Bairro"
                md={4}
                onChange={(e, v) => dispatch(setBairro(v))}
                value={state.bairro}
                maxLength={40}
              />
              <TextInput
                label="Referência"
                md={4}
                onChange={(e, v) => dispatch(setReferencia(v))}
                value={state.referencia}
                maxLength={30}
              />
            </Row>
            <Row>
              <AsyncComboBox
                md={5}
                isConcatField
                concatModelName="cidade"
                isClearable
                label="Cidade"
                isSearchable
                name="cidade"
                onChange={(s) => dispatch(setCidade(s?.value ?? 0))}
                defaultValue={state.cidade}
              />
              <MaskedInput
                mask="99999-999"
                name="cep"
                label="CEP"
                md={2}
                onChange={(e, v) => dispatch(setCep(v))}
                value={state.cep}
                additionalButton={
                  <BotaoPesq
                    onClick={buscarCep}
                    loading={loadingCep}
                    disabled={limparNumero(state.cep ?? "").length < 8}
                    secondary
                  />
                }
              />
              <IntegerFormInput
                md={1}
                label="Classificação"
                defaultValue={state.classif_cli}
                onChange={(v) => dispatch(setClassifCli(v))}
                style={{
                  backgroundColor: corClassifCli,
                  color: getTextColorBg(corClassifCli),
                }}
              />
            </Row>
            <Row>
              <TextInput
                label="Fone 1"
                md={3}
                onChange={(e, v) => dispatch(setFone1(v))}
                value={state.fone1}
                maxLength={18}
              />
              <TextInput
                label="Fone 2"
                md={3}
                onChange={(e, v) => dispatch(setFone2(v))}
                value={state.fone2}
                maxLength={18}
              />
              <TextInput
                label="Fone 3"
                md={3}
                onChange={(e, v) => dispatch(setFone3(v))}
                value={state.fone3}
                maxLength={18}
              />
              <TextInput
                label="Fax"
                md={3}
                onChange={(e, v) => dispatch(setFax(v))}
                value={state.fax}
                maxLength={18}
              />
            </Row>
            <Row>
              <TextInput
                label="WhatsApp"
                md={3}
                onChange={(e, v) => dispatch(setWhatsapp(v))}
                value={state.whatsapp}
                maxLength={20}
              />
              <AsyncComboBox
                md={3}
                isConcatField
                concatModelName="tipo_cliente"
                defaultOptions
                label="Tipo de Cliente"
                isSearchable
                onChange={(s) => dispatch(setIdTipoCli(s?.value))}
                defaultValue={state.id_tipo_cli}
              />
              <AsyncComboBox
                md={3}
                isConcatField
                concatModelName="colaborador"
                defaultOptions
                label="Representante Externo"
                isSearchable
                onChange={(s) => dispatch(setIdReprExterno(s?.value))}
                defaultValue={state.id_repr_externo}
              />
              <AsyncComboBox
                md={3}
                isConcatField
                concatModelName="rede_cliente"
                defaultOptions
                label="Rede"
                isSearchable
                onChange={(s) => dispatch(setIdRede(s?.value))}
                defaultValue={state.id_rede}
              />
            </Row>
            <Row>
              <TextInput
                label="E-Mail"
                md={5}
                onChange={(e, v) => dispatch(setEmail(v))}
                value={state.email}
                maxLength={80}
                forceUppercase={false}
              />
              <TextInput
                label="Contato Principal"
                md={5}
                onChange={(e, v) => dispatch(setContatoPrinc(v))}
                value={state.contato_princ}
                maxLength={60}
              />
            </Row>
            <Row>
              <AsyncComboBox
                md={2}
                defaultOptions={pessoa}
                isClearable={false}
                isConcatField={false}
                label="Pessoa"
                name="pessoa"
                defaultValue={state.pessoa}
                onChange={(s) => handleSetPessoa(s?.value)}
              />
              <MaskedInput
                mask={
                  state.pessoa === "F" ? "999.999.999-99" : "99.999.999/9999-99"
                }
                name="cpf_cnpj"
                label={state.pessoa === "F" ? "CPF" : "CNPJ"}
                md={3}
                value={state.cpf_cnpj}
                onChange={(e, v) => dispatch(setCpfCnpj(v))}
              />
              {state.pessoa === "F" && (
                <TextInput
                  label="Identidade"
                  md={3}
                  onChange={(e, v) => dispatch(setIdentidade(v))}
                  value={state.identidade}
                  maxLength={15}
                />
              )}
            </Row>
            {state.pessoa !== "F" && (
              <Row>
                <RadioGroup
                  label="Inscrição Estadual"
                  value={ieIsentoNaoCont}
                  onChange={handleSetIsentoNaoCont}
                  divClassName="mb-2"
                >
                  <RadioItem label="Contribuinte" value="NOR" />
                  <RadioItem label="Não Contribuinte" value="NAO" />
                  <RadioItem label="Isento" value="ISE" />
                </RadioGroup>
                <MaskedInput
                  maskChar={null}
                  mask="99999999999999"
                  md={3}
                  onChange={(e, v) => dispatch(setInscEst(v))}
                  value={ieIsentoNaoCont === "NOR" ? state.insc_est : ""}
                  disabled={ieIsentoNaoCont !== "NOR"}
                />
              </Row>
            )}
            <Row>
              {params.cad_cli_modif_observ_especial && (
                <TextInput
                  type="textarea"
                  rows={3}
                  style={{ resize: 0 }}
                  label="Observação Financeira Especial"
                  md={4}
                  onChange={(e, v) => dispatch(setObservFinancEspecial(v))}
                  value={state.observ_financ_especial}
                />
              )}
              <TextInput
                type="textarea"
                name="obs"
                label="Observação"
                md={mdObservacao}
                rows={3}
                style={{ resize: 0 }}
                value={state.obs}
                onChange={(e, v) => dispatch(setObs(v))}
              />
              {rotaAtende !== "" && (
                <FixedField
                  md={2}
                  label="Roteirização"
                  value={rotaAtende}
                  divClassName="pl-0"
                />
              )}
            </Row>
            <CamposPerson
              tableName="cliente"
              values={state.campos_personalizados}
              onChange={(v) => dispatch(setCamposPersonalizados(v))}
            />
          </TabBody>
          <TabBody title="Informações Adicionais">
            <Row>
              {state.pessoa === "J" && (
                <AsyncComboBox
                  md={4}
                  isConcatField
                  concatModelName="ramo_atividade"
                  defaultOptions
                  label="Ramo de Atividade"
                  isSearchable
                  isClearable
                  onChange={(s) =>
                    dispatch(setIdRamoAtividade(s?.value ?? null))
                  }
                  defaultValue={state.id_ramo_atividade}
                />
              )}
            </Row>
            <Row>
              <FormCheckbox
                label="Revenda (utilizado para definição de tributação de diferimento de ICMS)"
                md="auto"
                name="revenda_check"
                onChange={(e) => dispatch(setRevenda(e.target.checked))}
                checked={state.revenda}
                padded={false}
              />
            </Row>
            <Row>
              <FormCheckbox
                label="Bloqueado (permite visualização do cliente, porém não permite realização de venda)"
                md="auto"
                name="bloqueado_check"
                onChange={(e) => dispatch(setBloqueado(e.target.checked))}
                checked={state.bloqueado}
                padded={false}
              />
            </Row>
            <Row>
              <FormCheckbox
                label="Cobrar Despesa Financeira / Taxa de Boleto"
                md="auto"
                name="cob_gerar_desp_financ_check"
                onChange={(e) =>
                  dispatch(setCobGerarDespFinanc(e.target.checked))
                }
                checked={state.cob_gerar_desp_financ}
                padded={false}
              />
              <FormCheckbox
                label="Sempre gerar Impressão dos Boletos automaticamente no ato da Venda (Pronta Entrega) ou Faturamento"
                name="gera_boleto_automaticamente_check"
                onChange={(e) =>
                  dispatch(setGeraBoletoAutomaticamente(e.target.checked))
                }
                checked={state.gera_boleto_automaticamente}
                padded={false}
              />
              <RiInformationLine
                size={20}
                id="hint-gera-boleto-auto"
                style={{
                  display: "block",
                  marginTop: "5px",
                  marginLeft: "-10px",
                }}
              />
              <UncontrolledTooltip target="hint-gera-boleto-auto">
                Opção Desmarcada, no aplicativo de pronta entrega, não permitirá
                o recebimento de venda em boleto; No processo de faturamento do
                sistema administrativo, não permitirá gerar o boleto, caso seja
                necessário, deverá ser utilizada a impressão de boletos manual.
              </UncontrolledTooltip>
            </Row>
            <Row className="mb-2">
              <FormCheckbox
                label="Obriga identificação de Ordem de Compra em todas as Notas Fiscais deste cliente"
                checked={state.obrig_ord_compra_nf}
                onChange={() =>
                  dispatch(setObrigOrdCompraNf(!state.obrig_ord_compra_nf))
                }
                padded={false}
              />
            </Row>
            <Row>
              <AsyncComboBox
                md={4}
                isConcatField
                concatModelName="conta_banc"
                defaultOptions
                label="Conta Bancária para Faturamento"
                isSearchable
                isClearable
                onChange={(s) => dispatch(setCobContaBanc(s?.value ?? null))}
                defaultValue={state.cob_conta_banc}
              />
            </Row>
            <Row>
              <NumberInput
                label="% Desconto Financeiro"
                md="auto"
                value={state.perc_desc_financ}
                onChange={(v) => dispatch(setPercDescFinanc(v))}
                isPercentage
                hint={
                  "Ao definir um % de desconto financeiro, o sistema irá " +
                  "gerar automaticamente um lançamento de dedução do percentual " +
                  "informado sobre o valor total do título a receber."
                }
              />
            </Row>
            <Row>
              <DatePicker
                label="Data Desde"
                md={2}
                value={state.data_desde}
                onChange={(v) => dispatch(setDataDesde(v))}
              />
              <DatePicker
                label="Data de Aniversário"
                md={2}
                onChange={(v) => dispatch(setDataAniver(v))}
                required={false}
                value={state.data_aniver}
              />

              {params.usa_preco_atacado && (
                <ComboBox
                  md={2}
                  options={tipoPreco}
                  isClearable={false}
                  isSearchable={false}
                  label="Tipo de Preço"
                  defaultValue={state.tipo_venda}
                  onChange={(selected) =>
                    dispatch(setTipoVenda(selected?.value ?? "N"))
                  }
                />
              )}
            </Row>
            <Row>
              <TextInput
                name="razao_soc_nfe"
                label="Razão Social (NF-e)"
                md={5}
                onChange={(e, v) => dispatch(setRazaoSocNfe(v))}
                value={state.razao_soc_nfe}
                maxLength={60}
              />
              <TextInput
                name="insc_produtor_rural"
                label="Inscrição Produtor Rural"
                md={3}
                onChange={(e, v) => dispatch(setInscProdutorRural(v))}
                value={state.insc_produtor_rural}
                maxLength={15}
              />
            </Row>
            <Row>
              <TextInput
                type="textarea"
                name="obs"
                label="Observação Fixa - NF-e"
                md={12}
                rows={2}
                style={{ resize: 0 }}
                value={state.obs_fixa_nfe}
                onChange={(_, v) => dispatch(setObsFixaNfe(v))}
              />
            </Row>
            <Divider>Limites Financeiros do Cliente</Divider>
            <Row>
              <NumberInput
                label="Limite de Cheque"
                md={2}
                value={state.lim_cheque}
                onChange={(v) => dispatch(setLimCheque(v))}
                disabled={!params.permite_alter_limite_credito_cli}
                hint={
                  !params.permite_alter_limite_credito_cli &&
                  "Somente usuários Master cujo perfil permite alterar limites de clientes podem ajustar este valor"
                }
              />
              <FormCheckbox
                label="Tem Limite Contas a Receber"
                disabled={!params.permite_alter_limite_credito_cli}
                hint={
                  !params.permite_alter_limite_credito_cli &&
                  "Somente usuários Master cujo perfil permite alterar limites de clientes podem ajustar este valor"
                }
                checked={state.tem_limite_contas_receber}
                onChange={() => {
                  dispatch(
                    setTemLimiteContasReceber(!state.tem_limite_contas_receber)
                  );

                  if (state.tem_limite_contas_receber) {
                    dispatch(setLimCrediario(0));
                  }
                }}
              />
              <NumberInput
                label="Limite de Contas a Receber"
                md={2}
                value={state.lim_crediario}
                onChange={(v) => dispatch(setLimCrediario(v))}
                disabled={
                  !params.permite_alter_limite_credito_cli ||
                  !state.tem_limite_contas_receber
                }
                hint={
                  "Esse limite não é considerado no aplicativo de Pronta Entrega" +
                  (!params.permite_alter_limite_credito_cli
                    ? "Somente usuários Master cujo perfil permite alterar limites de clientes podem ajustar este valor"
                    : "")
                }
              />
            </Row>
            <Divider className="mb-0">APP Pronta Entrega</Divider>
            <Row className="mb-2">
              <FormCheckbox
                label="Sempre emitir NF-e no ato da venda"
                name="emite_nfe_mobile_check"
                onChange={(e) => dispatch(setEmiteNfeMobile(e.target.checked))}
                checked={state.emite_nfe_mobile}
                padded={false}
              />
            </Row>
            <Divider>Aplicativos de Força de Venda</Divider>
            <Row>
              <TextInput
                type="textarea"
                name="obs"
                label="Observação de Venda"
                md={6}
                rows={2}
                style={{ resize: 0 }}
                value={state.obs_app_venda}
                forceUppercase={false}
                onChange={(e, v) => dispatch(setObsAppVenda(v))}
              />
            </Row>
            {/* {licenca === "0" && (
              <>
                <Divider>Automações Skillsoft</Divider>
                <Row>
                  <FormCheckbox
                    label="Gerar NFS-e na Geração do Boleto"
                    name="gera_nfse_na_geracao_boleto"
                    onChange={(e) =>
                      dispatch(setGeraNfseNaGeracaoBoleto(e.target.checked))
                    }
                    checked={state.gera_nfse_na_geracao_boleto}
                    padded={false}
                  />
                </Row>
              </>
            )} */}
          </TabBody>
        </TabController>
        <Row className="px-2 mt-auto">
          {action === MODAL_ACTIONS.ADD && (
            <PreencAutoModal
              carregarDadosPreencAuto={carregarDadosPreencAuto}
            />
          )}
          {action === MODAL_ACTIONS.EDIT && (
            <FormButton
              md="auto"
              padded={false}
              color="secondary"
              onClick={toggleConfirmaAltDados}
              disabled={semCnpj}
              loading={loadingAtuDados}
              disabledHint={
                "O cliente deve ser Pessoa Jurídica " +
                "e o CNPJ deve ser válido"
              }
              id="bt-preenc-auto"
            >
              F8 - Atualizar Dados
            </FormButton>
          )}
          {action === MODAL_ACTIONS.EDIT && (
            <ContatosClienteModal
              idCliente={selected}
              nomeCliente={state.nome}
            />
          )}
          {action === MODAL_ACTIONS.EDIT && (
            <CompradorClienteModal
              idCliente={selected}
              nomeCliente={state.nome}
            />
          )}
          {action === MODAL_ACTIONS.EDIT && (
            <LabelButton onClick={imprimirFicha}>
              Imprimir Ficha Cadastral
            </LabelButton>
          )}
          {action === MODAL_ACTIONS.EDIT && (
            <TransformarClienteCuringaModal
              isOpen={transformarCuringaModalOpen}
              toggle={toggleTransformarCuringaModalOpen}
              idCliente={selected}
              nomeCliente={state.nome}
            />
          )}
          <FormButton
            divClassName="ml-auto"
            md="auto"
            color="success"
            onClick={handleSubmit}
            loading={loadingSubmit}
            padded={false}
          >
            F9 - Confirmar
          </FormButton>
          <FormButton
            md="auto"
            color="danger"
            onClick={() => history.goBack()}
            padded={false}
          >
            Esc - Sair
          </FormButton>
        </Row>
      </Card>
    </>
  );
});

export const IncluirAlterarCliente = ({ location }) => {
  const windowParameters = window?.parameters
    ? JSON.parse(window?.parameters)
    : {};
  const action =
    location?.state?.action ?? windowParameters.action ?? MODAL_ACTIONS.ADD;

  const refContainer = useRef();

  return (
    <PageContainer
      title={
        (action === MODAL_ACTIONS.ADD ? "Inclusão" : "Alteração") +
        " de Cliente"
      }
      number="0040_1"
      onKeyDown={(e) => {
        if (refContainer.current) {
          refContainer.current.onKeyDown(e);
        }
      }}
      canGoBack
    >
      <Provider store={store}>
        <IncluirAlterarClienteContainer
          location={location}
          ref={refContainer}
        />
      </Provider>
    </PageContainer>
  );
};
