import moment from "moment";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Card, Col, Row, UncontrolledTooltip } from "reactstrap";
import {
  AsyncComboBox,
  ComboBox,
  FixedField,
  FormButton,
  FormCheckbox,
  IconButton,
  IntegerFormInput,
  Loader,
  NumberInput,
  PageContainer,
  TextInput,
} from "../../../../../components";
import { DatePicker } from "../../../../../components/DatePicker";
import { Divider } from "../../../../../components/Divider";
import MaskedInput from "../../../../../components/MaskedInput";
import {
  TabBody,
  TabController,
} from "../../../../../components/TabController";
import { UnlockToEdit } from "../../../../../components/UnlockToEdit";
import {
  formatDateISO,
  formatNumber,
  MODAL_ACTIONS,
  roundFloat,
} from "../../../../../coreUtils";
import CadastroProdutosService from "../../../../../services/cadastro/CadastroProdutosService";
import { ImBarcode } from "react-icons/im";
import {
  initialState,
  setAtivo,
  setCest,
  setClasFiscal,
  setCodBalanca,
  setCodBar,
  setControlEst,
  setCor,
  setCustoUe,
  setDiasValidade,
  setEntraCalcMargLucroVenda,
  setEnviarMobileForcaVenda,
  setEstMin,
  setFotos,
  setGeraComissao,
  setIdAnp,
  setIdCfop,
  setIdClasse,
  setIdFabricante,
  setIdGrupo,
  setIdGrupoTrib,
  setIdSimilar,
  setIdSubGrupo,
  setIdTipoProd,
  setLocal,
  setNome,
  setObserv,
  setOrigemMerc,
  setPercDescFixo,
  setPercDescMaxVend,
  setPercLucro,
  setPercLucroAtacado,
  setPermiteAltPreco,
  setPesoUnitBruto,
  setPesoUnitLiquido,
  setPrecoAtacado,
  setPrecoMed,
  setPrecoMinPermitido,
  setPrecoVenda,
  setProntaEntrSolicRecQtdCaixaFec,
  setProntaEntrSolicRecUsaCaixaFec,
  setQtdCaixaFec,
  setQuantidade,
  setReferAux1,
  setReferAux2,
  setReferAux3,
  setReferAux4,
  setReferencia,
  setTamanho,
  setUltimaEntrada,
  setUnidade,
  setUsaCaixaFec,
  setup,
} from "./store/cadastroProdutoSlice";
import { CarrosselFotosProd } from "./components/CarrosselFotosProd";
import { AiOutlineLock, AiOutlineUnlock } from "react-icons/ai";
import { toastr } from "react-redux-toastr";
import { PesqModal } from "../../../../../components/PesqModal";
import { AlterarCodigoModal } from "./components/AlterarCodigoModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Redirect } from "react-router-dom";
import store from "./store";
import { cadastroProdutoRoute } from "../../../../../routes/modules/cadastro";
import { CadastroUnidadeModal } from "../../unidade/components/CadastroUnidadeModal";
import { apiGetV2, apiPostV2 } from "../../../../../apiV2";
import { showWarning } from "../../../../../components/AlertaModal";
import { routesBaseLigacaoProdFornec } from "../../../../compras/gerenciamento/entrada_nf_xml/produtos/components/IdentificarProdutoModal";
import { LogProdutoModal } from "../components/LogProdutoModal";
import { TransformarProdutoCuringaModal } from "./components/TransformarProdutoCuringaModal";
import { SenhaModal } from "../../../../../components/SenhaModal";

const origens = [
  { label: "0 - Nacional", value: "0" },
  { label: "1 - Estrangeira - Importação direta", value: "1" },
  { label: "2 - Estrangeira - Adquirida no mercado interno", value: "2" },
  {
    label:
      "3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40% e inferior ou igual a 70%",
    value: "3",
  },
  {
    label:
      "4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legislações citadas nos Ajustes",
    value: "4",
  },
  {
    label:
      "5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%",
    value: "5",
  },
  {
    label:
      "6 - Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX e gás natural",
    value: "6",
  },
  {
    label:
      "7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante lista CAMEX e gás natural",
    value: "7",
  },
  {
    label:
      "8 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%",
    value: "8",
  },
];

const SEM_GTIN = "SEM GTIN";

const IncluirAlterarProdutoContainer = forwardRef(({ location }, ref) => {
  const opener = window.opener;
  const windowParams = window.parameters ? JSON.parse(window.parameters) : {};
  const action =
    location?.state?.action ?? windowParams?.action ?? MODAL_ACTIONS.ADD;
  const [selected, setSelected] = useState(
    location?.state?.selected ?? windowParams?.selected
  );
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((state) => state.cadastroProduto);
  const [params, setParams] = useState({});
  const [recalcMargens, setRecalcMargens] = useState(false);
  const [semGtin, setSemGtin] = useState(false);
  const [gerarCodBarras, setGerarCodBarras] = useState(false);
  const [clonar, setClonar] = useState(false);
  const [proximoId, setProximoId] = useState("");
  const [logsOpen, setLogsOpen] = useState(false);
  const [senhaQuantidadeOpen, setSenhaQuantidadeOpen] = useState(false);
  const [quantidadeLocked, setQuantidadeLocked] = useState(true);

  const [qtdCasasDecimaisQtd, setQtdCasasDecimaisQtd] = useState(2);
  const [qtdCasasDecimaisVlrs, setQtdCasasDecimaisVlrs] = useState(2);

  const codBarRef = useRef();
  const nomeRef = useRef();
  const quantidadeRef = useRef();

  const [permiteAlterarPrecos, setPermiteAlterarPrecos] = useState(true);
  const [permiteAlterarQtd, setPermiteAlterarQtd] = useState(true);

  const [transformarCuringaModalOpen, setTransformarCuringaModalOpen] =
    useState(false);

  const limparDados = () => {
    dispatch(setup(initialState));
    setRecalcMargens(false);
    setSemGtin(false);
    setClonar(false);
  };

  const carregarParametros = async () => {
    const [ok, ret] = await apiGetV2("/tela/cad_produto/");

    if (ok) {
      setParams(ret);
      if (action === MODAL_ACTIONS.ADD) {
        dispatch(
          setup({
            id_grupo: ret.id_grupo_pad ?? initialState.id_grupo,
            id_sub_grupo: ret.id_sub_grupo_pad ?? initialState.id_sub_grupo,
            id_fabricante: ret.id_fabricante_pad ?? initialState.id_fabricante,
            id_tipo_prod: ret.id_tipo_prod_pad ?? initialState.id_tipo_prod,
            unidade: (ret.unid_med_pad ?? initialState.unidade).trim(),
            id_cfop: ret.usa_regra_tributaria
              ? initialState.id_cfop
              : (ret.cfop_pad ?? initialState.id_cfop).trim(),
          })
        );
        if ((ret.unid_med_pad ?? initialState.unidade).trim()) {
          setTimeout(() => {
            buscarCasasDecimais(
              (ret.unid_med_pad ?? initialState.unidade).trim()
            );
          }, 1);
        }
      }
      if (action === MODAL_ACTIONS.ADD) {
        const [ok2, ret2] = await apiGetV2(
          "/cadastro/produto/buscar_proximo_id/",
          {},
          { errorMessage: false }
        );
        if (ok2) {
          setProximoId(ret2.proximo_id ?? "");
        }
      }
    }
    return ok;
  };

  const verificaSemGtin = (v) => {
    if ([null, undefined].includes(v)) {
      v = "";
    }
    v = v.toString().trim().toUpperCase();

    if (v.length === 0 || v === SEM_GTIN) {
      setSemGtin(true);
      return SEM_GTIN;
    } else {
      return v.replace(/\s/g, "");
    }
  };

  const carregarDados = async () => {
    const [ok, ret] = await CadastroProdutosService.buscar(selected);

    if (ok) {
      setQtdCasasDecimaisQtd(ret.qtd_casas_decimais_qtd);
      setQtdCasasDecimaisVlrs(ret.qtd_casas_decimais_vlrs);
      dispatch(
        setup({
          nome: ret.nome,
          referencia: ret.referencia,
          refer_aux_1: ret.refer_aux_1 ?? "",
          refer_aux_2: ret.refer_aux_2 ?? "",
          refer_aux_3: ret.refer_aux_3 ?? "",
          refer_aux_4: ret.refer_aux_4 ?? "",
          cod_bar: verificaSemGtin(ret.cod_bar),
          id_grupo: ret.id_grupo,
          id_sub_grupo: ret.id_sub_grupo,
          id_fabricante: ret.id_fabricante,
          id_anp: ret.id_anp || null,
          id_classe: ret.id_classe || null,
          unidade: ret.unidade,
          fotos: ret.fotos,
          clas_fiscal: ret.clas_fiscal,
          id_cfop: ret.id_cfop,
          id_tipo_prod: [0, undefined].includes(ret.id_tipo_prod)
            ? null
            : ret.id_tipo_prod,
          preco_med: parseFloat(ret.preco_med),
          preco_venda: parseFloat(ret.preco_venda),
          preco_atacado: parseFloat(ret.preco_atacado),
          custo_ue: parseFloat(ret.custo_ue),
          custo_med: parseFloat(ret.custo_med),
          custo_max: parseFloat(ret.custo_max),
          perc_desc_fixo: parseFloat(ret.perc_desc_fixo),
          perc_desc_max_vend: parseFloat(ret.perc_desc_max_vend),
          perc_lucro: parseFloat(ret.perc_lucro),
          perc_lucro_atacado: parseFloat(ret.perc_lucro_atacado),
          quantidade: parseFloat(ret.quantidade),
          control_est: ret.control_est,
          ativo: ret.ativo,
          origem_merc: ret.origem_merc,
          local: ret.local ?? "",
          observ: ret.observ ?? "",
          ultima_entrada:
            ret.ultima_entrada &&
            moment(ret.ultima_entrada, "DD/MM/YYYY").toDate(),
          enviar_mobile_forca_venda: ret.enviar_mobile_forca_venda,
          est_min: parseFloat(ret.est_min),
          preco_min_permitido: parseFloat(ret.preco_min_permitido),
          cest: ret.cest ?? "",
          peso_unit_bruto: parseFloat(ret.peso_unit_bruto ?? 0),
          peso_unit_liquido: parseFloat(ret.peso_unit_liquido ?? 0),
          gera_comissao: ret.gera_comissao,
          entra_calc_marg_lucro_venda: ret.entra_calc_marg_lucro_venda ?? false,
          id_grupo_trib: ret.id_grupo_regra_tributaria,
          id_similar: ret.id_similar ?? 0,
          cod_balanca: ret.cod_balanca ?? "",
          permite_alt_preco: ret.permite_alt_preco ?? false,
          usa_caixa_fec: ret.usa_caixa_fec,
          qtd_caixa_fec: parseFloat(ret.qtd_caixa_fec),
          tamanho: ret.tamanho,
          cor: ret.cor,
          pronta_entr_solic_rec_usa_caixa_fec:
            ret.pronta_entr_solic_rec_usa_caixa_fec,
          pronta_entr_solic_rec_qtd_caixa_fec: parseFloat(
            ret.pronta_entr_solic_rec_qtd_caixa_fec
          ),
          dias_validade: ret.dias_validade,
        })
      );
    }

    return ok;
  };

  const preCarrregarValoresInclusao = () => {
    if (windowParams.dadosPreCarregarInclusao) {
      if (windowParams.dadosPreCarregarInclusao.nome) {
        dispatch(setNome(windowParams.dadosPreCarregarInclusao.nome));
      }

      if (windowParams.dadosPreCarregarInclusao.codBar) {
        dispatch(
          setCodBar(
            verificaSemGtin(windowParams.dadosPreCarregarInclusao.codBar)
          )
        );
      }

      if (windowParams.dadosPreCarregarInclusao.ncm) {
        dispatch(setClasFiscal(windowParams.dadosPreCarregarInclusao.ncm));
      }

      if (windowParams.dadosPreCarregarInclusao.cest) {
        dispatch(setCest(windowParams.dadosPreCarregarInclusao.cest));
      }

      setPermiteAlterarQtd(windowParams.permiteAlterarQtd);
      setPermiteAlterarPrecos(windowParams.permiteAlterarPrecos);
    }
  };

  const iniciarTela = async () => {
    if (!(await carregarParametros())) {
      history.goBack();
      return false;
    }

    if (action === MODAL_ACTIONS.EDIT) {
      if (!(await carregarDados())) {
        history.goBack();
        return false;
      }
    } else {
      preCarrregarValoresInclusao();
    }
    setLoading(false);
  };

  const handleSubmit = async () => {
    if (store.nome.trim().length === 0) {
      showWarning("Por favor, informe o Nome do Produto");
      return false;
    }

    if ([0, null, undefined].includes(store.id_grupo)) {
      showWarning("Por favor, informe o Grupo do Produto e tente novamente.");
      return false;
    }

    if ([0, null, undefined].includes(store.id_sub_grupo)) {
      showWarning("Por favor, informe Sub-Grupo do Produto e tente novamente.");
      return false;
    }

    if ([0, null, undefined].includes(store.id_fabricante)) {
      showWarning(
        "Por favor, informe Fabricante do Produto e tente novamente."
      );
      return false;
    }

    if (store.unidade.trim().length === 0) {
      showWarning("Por favor, informe a Unidade do Produto e tente novamente.");
      return false;
    }

    if (windowParams.cadastrarLigacaoProduto) {
      if ([0, null, undefined].includes(windowParams.idFornecedorLigacao)) {
        showWarning(
          "Falha ao identificar dados do produto para geração da ligação: " +
            "O Fornecedor não foi identificado"
        );
        return false;
      }
    }

    const payloadData = {
      nome: store.nome,
      referencia: store.referencia,
      refer_aux_1: store.refer_aux_1,
      refer_aux_2: store.refer_aux_2,
      refer_aux_3: store.refer_aux_3,
      refer_aux_4: store.refer_aux_4,
      cod_bar: verificaSemGtin(store.cod_bar),
      id_grupo: store.id_grupo,
      id_sub_grupo: store.id_sub_grupo,
      id_fabricante: store.id_fabricante,
      id_tipo_prod: store.id_tipo_prod,
      id_classe: store.id_classe,
      id_anp: store.id_anp ?? null,
      local: store.local,
      unidade: (store.unidade ?? "").trim(),
      usa_caixa_fec: store.usa_caixa_fec,
      qtd_caixa_fec: store.qtd_caixa_fec,
      clas_fiscal: store.clas_fiscal,
      cest: store.cest,
      id_cfop:
        !params.usa_regra_tributaria && store.id_cfop ? store.id_cfop : "",
      origem_merc: parseInt(store.origem_merc),
      preco_med: store.preco_med,
      preco_venda: store.preco_venda,
      preco_atacado: params.usa_preco_atacado ? store.preco_atacado : 0,
      preco_min_permitido: store.preco_min_permitido,
      data_ue:
        store.ultima_entrada && store.ultima_entrada !== ""
          ? formatDateISO(store.ultima_entrada)
          : null,
      custo_ue: store.custo_ue,
      custo_med: store.custo_med,
      custo_max:
        store.custo_ue > store.custo_max ? store.custo_ue : store.custo_max,
      perc_lucro: store.perc_lucro,
      perc_lucro_atacado: params.usa_preco_atacado
        ? store.perc_lucro_atacado
        : 0,
      perc_desc_fixo: store.perc_desc_fixo,
      perc_desc_max_vend: store.perc_desc_max_vend,
      quantidade: store.quantidade,
      control_est: store.control_est,
      est_min: store.est_min,
      est_max: 0, // FIXO
      peso_unit_bruto: store.peso_unit_bruto,
      peso_unit_liquido: store.peso_unit_liquido,
      observ: store.observ,
      cod_balanca: store.cod_balanca,
      permite_alt_preco: store.permite_alt_preco,
      enviar_mobile_forca_venda: store.enviar_mobile_forca_venda,
      ativo: store.ativo,
      gera_comissao: store.gera_comissao,
      entra_calc_marg_lucro_venda: store.entra_calc_marg_lucro_venda,
      id_grupo_trib: params.usa_regra_tributaria ? store.id_grupo_trib : null,
      tamanho: store.tamanho,
      cor: store.cor,
      pronta_entr_solic_rec_usa_caixa_fec:
        store.pronta_entr_solic_rec_usa_caixa_fec,
      pronta_entr_solic_rec_qtd_caixa_fec:
        store.pronta_entr_solic_rec_qtd_caixa_fec,
      dias_validade: store.dias_validade,
    };

    const payload =
      action === MODAL_ACTIONS.ADD || clonar
        ? {
            ...payloadData,
            importacao: false,
            id_cad_imp: 0,
            fotos: store.fotos.map((e) => ({ base64: e.base64_foto })),
            id_similar: store.id_similar,
            gerar_cod_barras: gerarCodBarras,
          }
        : { ...payloadData, id: selected };

    setLoadingSubmit(true);

    const submitFunc =
      action === MODAL_ACTIONS.ADD || clonar
        ? CadastroProdutosService.incluir
        : CadastroProdutosService.alterar;

    const [ok, ret] = await submitFunc(payload);

    if (ok) {
      if (windowParams.cadastrarLigacaoProduto) {
        await cadastrarLigacaoProduto(ret.id_cadastrado);
      }
      setRedirect(true);
    }

    setLoadingSubmit(false);
  };

  const cadastrarLigacaoProduto = async (idProduto) => {
    const payload = {
      id_produto: idProduto,
      id_fornecedor: windowParams.idFornecedorLigacao,
      cod_prod_fornec: windowParams.codProdFornecLigacao ?? "",
      ean_prod_fornec: windowParams.eanProdFornecLigacao ?? "",
      considerar_ean: false,
      fator_conv: 0,
      arredonda_result: false,
      conversao_em_m3: false,
    };

    const [ok] = await apiPostV2(
      `${routesBaseLigacaoProdFornec}/incluir/`,
      payload
    );

    return ok;
  };

  const refreshFotos = async () => {
    const [ok, ret] = await CadastroProdutosService.buscar(selected);

    if (ok) {
      dispatch(setFotos(ret.fotos));
    }
  };

  const addFotoEdit = async (base64) => {
    const payload = {
      id_produto: selected,
      base64_foto: base64,
      foto_principal: store.fotos.length === 0,
    };
    const [ok] = await CadastroProdutosService.fotos.incluir(payload);
    return ok;
  };

  const deleteFotoEdit = async (idFoto) => {
    const [ok] = await CadastroProdutosService.fotos.excluir(idFoto);
    return ok;
  };

  const definirFotoPrincipalEdit = async (idFoto) => {
    const payload = {
      id_produto: selected,
      id_foto: idFoto,
    };
    const [ok] = await CadastroProdutosService.fotos.definirFotoPrincipal(
      payload
    );
    return ok;
  };

  const addFoto = async (data) => {
    if (action === MODAL_ACTIONS.EDIT) {
      if (await addFotoEdit(data)) {
        refreshFotos();
      }
    } else {
      dispatch(
        setFotos(
          [
            ...store.fotos,
            { base64_foto: data, foto_principal: store.fotos.length === 0 },
          ]
            .slice()
            .sort((e) => (e.foto_principal ? -1 : 1))
        )
      );
    }
  };

  const deleteFoto = async (i) => {
    if (action === MODAL_ACTIONS.EDIT) {
      if (await deleteFotoEdit(store.fotos[i].id)) {
        refreshFotos();
      }
    } else {
      dispatch(setFotos(store.fotos.filter((e, index) => index !== i)));
    }
  };

  const definirFotoPrincipal = async (i) => {
    if (action === MODAL_ACTIONS.EDIT) {
      if (await definirFotoPrincipalEdit(store.fotos[i].id)) {
        refreshFotos();
      }
    } else {
      dispatch(
        setFotos(
          store.fotos
            .map((e, ind) => ({ ...e, foto_principal: ind === i }))
            .slice()
            .sort((e) => (e.foto_principal ? -1 : 1))
        )
      );
    }
  };

  const onChangeCusto = (v) => {
    if (v > 0) {
      if (recalcMargens) {
        dispatch(
          setPercLucro(roundFloat((store.preco_venda * 100) / v - 100, 4))
        );
        dispatch(
          setPercLucroAtacado(
            roundFloat((store.preco_atacado * 100) / v - 100, 4)
          )
        );
      } else {
        dispatch(
          setPrecoVenda(
            roundFloat(v + v * (store.perc_lucro / 100), qtdCasasDecimaisVlrs)
          )
        );
        dispatch(
          setPrecoAtacado(
            roundFloat(
              v + v * (store.perc_lucro_atacado / 100),
              qtdCasasDecimaisVlrs
            )
          )
        );
      }
    }
    dispatch(setCustoUe(v));
  };

  const onChangeMargem = (v) => {
    if (store.custo_ue > 0 && v > 0)
      dispatch(
        setPrecoVenda(
          roundFloat(
            store.custo_ue + store.custo_ue * (v / 100),
            qtdCasasDecimaisVlrs
          )
        )
      );

    dispatch(setPercLucro(v));
  };

  const onChangeMargemAtacado = (v) => {
    if (store.custo_ue > 0 && v > 0)
      dispatch(
        setPrecoAtacado(
          roundFloat(
            store.custo_ue + store.custo_ue * (v / 100),
            qtdCasasDecimaisVlrs
          )
        )
      );

    dispatch(setPercLucroAtacado(v));
  };

  const onChangePrecoVenda = (v) => {
    if (store.custo_ue > 0)
      dispatch(setPercLucro(roundFloat((v * 100) / store.custo_ue - 100, 4)));

    dispatch(setPrecoVenda(v));
  };

  const onChangePrecoAtacado = (v) => {
    if (store.custo_ue > 0)
      dispatch(
        setPercLucroAtacado(roundFloat((v * 100) / store.custo_ue - 100, 4))
      );

    dispatch(setPrecoAtacado(v));
  };

  const setCodBarSemGtin = () => {
    if (semGtin) {
      dispatch(setCodBar(SEM_GTIN));
    } else {
      dispatch(setCodBar(""));
      if (codBarRef.current) {
        codBarRef.current.focus();
      }
    }
  };

  const setModoClonar = () => {
    setClonar(true);
    if (store.id_similar === 0) {
      dispatch(setIdSimilar(selected));
    }
    nomeRef.current.focus();
    toastr.info(
      "Clonagem configurada",
      "Ajuste os dados do produto, se necessário; Então, clique em Confirmar."
    );
  };

  const gerarCodBarrasProd = async () => {
    if (semGtin) {
      setSemGtin(!semGtin);
    }
    if (action === MODAL_ACTIONS.ADD) {
      setGerarCodBarras(!gerarCodBarras);
      dispatch(setCodBar(""));
    } else {
      if (!gerarCodBarras) {
        const [ok, ret] = await CadastroProdutosService.gerarCodBarras(
          selected
        );
        dispatch(setCodBar(ok ? ret.cod_bar ?? "" : ""));
      }
    }
  };

  const handleSetUsaCaixaFec = (v) => {
    if (!v) {
      dispatch(setQtdCaixaFec(0));
    }
    dispatch(setUsaCaixaFec(v));
  };

  const handleSetProntaEntrSolicRecargaUsaCaixaFec = (v) => {
    if (!v) {
      dispatch(setProntaEntrSolicRecQtdCaixaFec(0));
    }
    dispatch(setProntaEntrSolicRecUsaCaixaFec(v));
  };

  const toggleLogs = () => setLogsOpen(!logsOpen);

  const onKeyDown = (e) => {
    if (!e.shiftKey && !e.altKey && !e.ctrlKey && !e.metaKey) {
      if (e.key === "F9") handleSubmit();
      if (
        e.key === "Escape" &&
        opener !== null &&
        history.length === 1 &&
        !loading
      )
        window.close();
    }
  };

  const buscarCasasDecimais = async (unidade) => {
    if (!unidade) {
      unidade = store.unidade;
    }
    const [ok, ret] = await apiGetV2(
      `/cadastro/unidade_med/buscar_casas_decimais/${unidade}/`
    );
    if (ok) {
      setQtdCasasDecimaisQtd(ret.qtd_casas_decimais_qtd);
      setQtdCasasDecimaisVlrs(ret.qtd_casas_decimais_vlrs);
    }
  };

  const verifSenhaQuantidade = async (senha) => {
    const payload = { senha: senha };
    const [ok] = await CadastroProdutosService.verifSenhaAltEstoque(payload);
    if (ok) {
      setQuantidadeLocked(false);
      if (quantidadeRef.current) {
        quantidadeRef.current.focus();
      }
    }
    return ok;
  };

  useEffect(() => {
    setCodBarSemGtin();
  }, [semGtin]);

  useEffect(() => {
    iniciarTela();

    return () => limparDados();
  }, []);

  useImperativeHandle(ref, () => ({
    onKeyDown: onKeyDown,
  }));

  const toggleTransformarCuringaModalOpen = () =>
    setTransformarCuringaModalOpen(!transformarCuringaModalOpen);

  const toggleSenhaQuantidade = () =>
    setSenhaQuantidadeOpen(!senhaQuantidadeOpen);

  if (redirect) {
    const opener = window.opener;

    if (opener !== null && history.length === 1) {
      opener.postMessage(
        { id: windowParams.id, selected: selected },
        window.location.origin
      );
      window.close();
    } else {
      return (
        <Redirect
          to={{
            pathname: cadastroProdutoRoute.path,
            state: { refresh: true },
          }}
        />
      );
    }
  }

  const quantidadeDisabled =
    !permiteAlterarQtd || // Bloqueado por windowParams
    (action === MODAL_ACTIONS.EDIT &&
      (!params.permite_ajuste_estoque || // Bloqueado por Perfil
        (params.exige_senha_alt_estoq_pelo_cad_prod && quantidadeLocked))); // Bloqueado por senha

  return loading ? (
    <Loader />
  ) : (
    <Card body>
      <TabController
        tabClassName="px-2 pt-2"
        contentStyle={{
          height: "737px",
          overflowX: "clip",
          overflowY: "auto",
          marginBottom: "8px",
        }}
      >
        <TabBody title="Cadastro">
          <div style={{ display: "flex" }}>
            <div style={{ flexGrow: "1" }}>
              <Row>
                <TextInput
                  label="Código"
                  md={2}
                  disabled
                  value={String(selected ?? proximoId)}
                  inputStyle={{ textAlign: "center" }}
                />
                <FormCheckbox
                  md={2}
                  label="Ativo"
                  checked={store.ativo}
                  onChange={() => dispatch(setAtivo(!store.ativo))}
                  tabIndex={100}
                />
              </Row>
              <Row>
                <TextInput
                  label="Nome"
                  md={12}
                  onChange={(e, v) => dispatch(setNome(v))}
                  value={store.nome}
                  maxLength={80}
                  ref={nomeRef}
                  required
                  onKeyDown={(e) => {
                    if (e.ctrlKey && e.which === 89) {
                      // CTRL + Y
                      toggleTransformarCuringaModalOpen();
                    }
                  }}
                />
              </Row>
              <Row>
                <TextInput
                  label="Referência"
                  md={4}
                  onChange={(e, v) => dispatch(setReferencia(v))}
                  value={store.referencia}
                  maxLength={30}
                />
                <MaskedInput
                  label="Código de Barras"
                  md={3}
                  onChange={(e, v) => dispatch(setCodBar(v))}
                  value={store.cod_bar}
                  disabled={semGtin || gerarCodBarras}
                  mask={semGtin ? "SEM GTIN" : "********************"}
                  maskChar={null}
                  ref={codBarRef}
                  actions={
                    action === MODAL_ACTIONS.EDIT
                      ? [
                          <div
                            className="react-select-shortcut"
                            onClick={gerarCodBarrasProd}
                          >
                            <ImBarcode size={20} color="white" />
                          </div>,
                        ]
                      : []
                  }
                />
                <FormCheckbox
                  md={null}
                  label="Sem Cód. Barras"
                  checked={semGtin}
                  onChange={() => {
                    setSemGtin(!semGtin);
                    if (gerarCodBarras) {
                      setGerarCodBarras(!gerarCodBarras);
                    }
                  }}
                />
                {action === MODAL_ACTIONS.ADD && (
                  <>
                    <Col md={null} id="gerar-cod-bar" className="pl-0">
                      <FormCheckbox
                        divClassName="p-0"
                        md={null}
                        label="Gerar Cód. Barras"
                        checked={gerarCodBarras}
                        onChange={gerarCodBarrasProd}
                      />
                    </Col>

                    <UncontrolledTooltip target="gerar-cod-bar">
                      O código de barras será gerado automaticamente no momento
                      da inclusão do produto.
                    </UncontrolledTooltip>
                  </>
                )}
              </Row>
              <Row>
                <AsyncComboBox
                  isConcatField
                  concatModelName="grupo"
                  isSearchable
                  isClearable
                  md={6}
                  label="Grupo"
                  defaultOptions
                  onChange={(s) => dispatch(setIdGrupo(s?.value ?? 0))}
                  defaultValue={store.id_grupo}
                  required
                />
                <AsyncComboBox
                  isConcatField
                  concatModelName="sub_grupo"
                  isSearchable
                  md={6}
                  label="Sub-Grupo"
                  defaultOptions
                  onChange={(s) => dispatch(setIdSubGrupo(s?.value))}
                  defaultValue={store.id_sub_grupo}
                  required
                />
              </Row>
              <Row>
                <AsyncComboBox
                  isConcatField
                  concatModelName="fabricante"
                  isSearchable
                  isClearable
                  md={6}
                  label="Fabricante"
                  defaultOptions
                  onChange={(s) => dispatch(setIdFabricante(s?.value ?? 0))}
                  defaultValue={store.id_fabricante}
                  required
                />
                <AsyncComboBox
                  isClearable
                  isConcatField
                  concatModelName="tipo_prod"
                  isSearchable
                  md={6}
                  label="Tipo Produto"
                  defaultOptions
                  onChange={(s) => dispatch(setIdTipoProd(s?.value ?? null))}
                  defaultValue={store.id_tipo_prod}
                  required
                />
              </Row>
            </div>
            <CarrosselFotosProd
              fotos={store.fotos}
              onAdd={addFoto}
              onDelete={deleteFoto}
              definirPrincipal={definirFotoPrincipal}
              md={3}
            />
          </div>
          {params.tipo_sist === "MECANICA" && (
            <Row>
              <TextInput
                label="Ref. Aux. 1"
                md={3}
                onChange={(e, v) => dispatch(setReferAux1(v))}
                value={store.refer_aux_1}
                maxLength={30}
              />
              <TextInput
                label="Ref. Aux. 2"
                md={3}
                onChange={(e, v) => dispatch(setReferAux2(v))}
                value={store.refer_aux_2}
                maxLength={30}
              />
              <TextInput
                label="Ref. Aux. 3"
                md={3}
                onChange={(e, v) => dispatch(setReferAux3(v))}
                value={store.refer_aux_3}
                maxLength={30}
              />
              <TextInput
                label="Ref. Aux. 4"
                md={3}
                onChange={(e, v) => dispatch(setReferAux4(v))}
                value={store.refer_aux_4}
                maxLength={30}
              />
            </Row>
          )}
          <Row>
            {params.usa_regra_tributaria ? (
              <UnlockToEdit enabled={action === MODAL_ACTIONS.EDIT}>
                <AsyncComboBox
                  isClearable
                  isConcatField
                  concatModelName="grupo_trib"
                  isSearchable
                  label="Grupo de Regra Tributária"
                  defaultOptions
                  onChange={(s) => dispatch(setIdGrupoTrib(s?.value ?? null))}
                  defaultValue={store.id_grupo_trib}
                  divClassName={action === MODAL_ACTIONS.EDIT && "pr-0"}
                  required
                />
              </UnlockToEdit>
            ) : (
              <AsyncComboBox
                isClearable
                isConcatField
                concatModelName="cfop"
                isSearchable
                label="CFOP (Padrão)"
                defaultOptions
                onChange={(s) => dispatch(setIdCfop(s?.value))}
                defaultValue={store.id_cfop}
                required
              />
            )}
            <MaskedInput
              divClassName="ml-auto pr-0"
              md={2}
              label="NCM"
              mask="99999999"
              maskChar={null}
              value={store.clas_fiscal}
              onChange={(e, v) => dispatch(setClasFiscal(v))}
              required
            />
            <PesqModal
              route="/cadastro/ncm/pesquisa/"
              keyField="ncm"
              labelField="descricao"
              title="NCM"
              value={store.clas_fiscal}
              onChange={(v) => dispatch(setClasFiscal(v))}
              modalSize="lg"
            />
            <MaskedInput
              label="CEST"
              md={2}
              mask="9999999"
              maskChar={null}
              value={store.cest}
              onChange={(e, v) => dispatch(setCest(v))}
            />
          </Row>
          <div className="mt-1" style={{ display: "flex" }}>
            <div className="pr-2" style={{ width: "50%" }}>
              <Divider className="mt-0">Estoque</Divider>
              <Row>
                <NumberInput
                  md={4}
                  label="Quantidade"
                  onChange={(v) => dispatch(setQuantidade(v))}
                  value={store.quantidade}
                  decimalPlaces={qtdCasasDecimaisQtd}
                  divClassName={action === MODAL_ACTIONS.EDIT && "pr-0"}
                  ref={quantidadeRef}
                  disabled={quantidadeDisabled}
                  hint={
                    "O comportamento deste campo é personalizado. " +
                    "O sistema pode ser configurado para não permitir alteração, " +
                    "permitir alteração por perfil do colaborador " +
                    "e permitir a alteração com senha."
                  }
                  additionalButton={
                    action === MODAL_ACTIONS.EDIT &&
                    (!params.permite_ajuste_estoque ||
                      params.exige_senha_alt_estoq_pelo_cad_prod) &&
                    quantidadeLocked && (
                      <IconButton
                        icon={AiOutlineLock}
                        size={18}
                        onClick={toggleSenhaQuantidade}
                        disabled={!params.permite_ajuste_estoque}
                        style={{ marginBlock: "auto", marginLeft: "0.4rem" }}
                        hint={
                          "Clique para Desbloquear a Alteração de Quantidade"
                        }
                        disabledHint={
                          "Seu perfil não está habilitado " +
                          "a efetuar Ajustes de Estoque"
                        }
                        tooltipPlacement="right"
                      />
                    )
                  }
                />
                <SenhaModal
                  isOpen={senhaQuantidadeOpen}
                  toggle={toggleSenhaQuantidade}
                  title="Alteração de Estoque pelo Cadastro de Produtos"
                  onConfirm={verifSenhaQuantidade}
                />
                <TextInput
                  label="Unidade"
                  md={3}
                  onChange={(e, v) => dispatch(setUnidade(v))}
                  onBlur={buscarCasasDecimais}
                  value={store.unidade}
                  maxLength={5}
                  additionalButton={
                    <CadastroUnidadeModal
                      selected={store.unidade}
                      notifyEvent={(v) => {
                        dispatch(setUnidade(v));
                        setTimeout(() => {
                          buscarCasasDecimais();
                        }, 1);
                      }}
                      concatShortcut
                    />
                  }
                  required
                />
                <FormCheckbox
                  name="control_est"
                  label="Controlar Estoque"
                  checked={store.control_est}
                  onChange={() => dispatch(setControlEst(!store.control_est))}
                  padded={false}
                  className="mb-3 mt-3 pt-0"
                />
              </Row>
              <Row className="mb-2">
                <TextInput
                  label="Localização"
                  md={7}
                  onChange={(e, v) => dispatch(setLocal(v))}
                  value={store.local}
                  maxLength={20}
                />
                <NumberInput
                  md={4}
                  label="Estoque Mínimo"
                  onChange={(v) => dispatch(setEstMin(v))}
                  value={store.est_min}
                  decimalPlaces={qtdCasasDecimaisQtd}
                />
              </Row>
              <Divider className="mt-1">Descontos</Divider>
              <Row>
                <NumberInput
                  md={4}
                  label="Desc. Fixo"
                  onChange={(v) => dispatch(setPercDescFixo(v))}
                  value={store.perc_desc_fixo}
                  isPercentage
                />
                <NumberInput
                  md={4}
                  label="Desc. Máx. Vendedor"
                  onChange={(v) => dispatch(setPercDescMaxVend(v))}
                  value={store.perc_desc_max_vend}
                  isPercentage
                />
              </Row>
            </div>
            <div className="pl-2" style={{ width: "50%" }}>
              <Divider className="mt-1">Compra / Custo</Divider>
              <Row className="mb-2" style={{ justifyContent: "space-between" }}>
                <FixedField
                  md={4}
                  label="Custo Médio"
                  value={formatNumber(store.custo_med, true, 2)}
                />
                <FixedField
                  md={4}
                  label="Custo Máximo"
                  value={formatNumber(
                    store.custo_ue > store.custo_max
                      ? store.custo_ue
                      : store.custo_max,
                    true,
                    2
                  )}
                />
              </Row>
              <Row style={{ justifyContent: "space-between" }}>
                <DatePicker
                  label="Última Entrada"
                  md={4}
                  value={store.ultima_entrada}
                  onChange={(v) =>
                    dispatch(
                      setUltimaEntrada(moment.isMoment(v) ? v.toDate() : v)
                    )
                  }
                  disabled={!permiteAlterarPrecos}
                />
                <NumberInput
                  md={4}
                  label="Preço de Custo"
                  onChange={onChangeCusto}
                  value={store.custo_ue}
                  decimalPlaces={qtdCasasDecimaisVlrs}
                  disabled={!permiteAlterarPrecos}
                />
              </Row>
              <Row style={{ justifyContent: "space-between" }}>
                <NumberInput
                  md={4}
                  label="Margem de Lucro"
                  onChange={onChangeMargem}
                  value={store.perc_lucro}
                  isPercentage
                  disabled={!permiteAlterarPrecos}
                />
                <div id="recalc-margem" className="mt-auto mb-2 ml-auto">
                  <IconButton
                    size={20}
                    icon={recalcMargens ? AiOutlineLock : AiOutlineUnlock}
                    onClick={() => setRecalcMargens(!recalcMargens)}
                  />
                </div>
                <UncontrolledTooltip target="recalc-margem">
                  <>
                    Cadeado aberto: ao alterar o Custo, o Preço de Venda será
                    alterado;
                    <br />
                    Cadeado fechado: ao alterar o Custo, a Margem de Lucro será
                    alterada.
                    <br />
                    Ao alterar a Margem de Lucro, o preço de venda sempre será
                    alterado.
                  </>
                </UncontrolledTooltip>
                <NumberInput
                  md={4}
                  label="Preço de Venda"
                  onChange={onChangePrecoVenda}
                  value={store.preco_venda}
                  decimalPlaces={qtdCasasDecimaisVlrs}
                  disabled={recalcMargens || !permiteAlterarPrecos}
                />
              </Row>
              {params.usa_preco_atacado && (
                <>
                  <Divider>Venda - Preço Atacado</Divider>
                  <Row style={{ justifyContent: "space-between" }}>
                    <NumberInput
                      md={5}
                      label="Margem de Lucro"
                      onChange={onChangeMargemAtacado}
                      value={store.perc_lucro_atacado}
                      isPercentage
                      disabled={!permiteAlterarPrecos}
                    />
                    <NumberInput
                      md={5}
                      label="Preço de Atacado"
                      onChange={onChangePrecoAtacado}
                      value={store.preco_atacado}
                      decimalPlaces={qtdCasasDecimaisVlrs}
                      disabled={!permiteAlterarPrecos}
                    />
                  </Row>
                </>
              )}
            </div>
          </div>
          <Row className="mt-1">
            <TextInput
              type="textarea"
              label="Observação"
              md={12}
              onChange={(e, v) => dispatch(setObserv(v))}
              value={store.observ}
              rows={3}
              inputStyle={{ resize: "none" }}
            />
          </Row>
        </TabBody>
        <TabBody title="Informações Adicionais">
          <Row>
            <NumberInput
              md={2}
              label="Peso Unit. Bruto (kg)"
              onChange={(v) => dispatch(setPesoUnitBruto(v))}
              value={store.peso_unit_bruto}
              decimalPlaces={7}
            />
            <NumberInput
              md={2}
              label="Peso Unit. Líquido (kg)"
              onChange={(v) => dispatch(setPesoUnitLiquido(v))}
              value={store.peso_unit_liquido}
              decimalPlaces={7}
            />
          </Row>
          <Row>
            <NumberInput
              md={2}
              label="Preço Mínimo"
              onChange={(v) => dispatch(setPrecoMinPermitido(v))}
              value={store.preco_min_permitido}
              hint={
                <>
                  Esse preço é considerado nos apps de Pedido de Venda e Pronta
                  Entrega.
                  <br />
                  Este é o preço que será utilizado para emissão das notas
                  fiscais de remessa do sistema de Pronta Entrega
                </>
              }
              disabled={!permiteAlterarPrecos}
              decimalPlaces={qtdCasasDecimaisVlrs}
            />
            <NumberInput
              md={2}
              label="Preço Médio"
              onChange={(v) => dispatch(setPrecoMed(v))}
              value={store.preco_med}
              decimalPlaces={qtdCasasDecimaisVlrs}
              disabled={!permiteAlterarPrecos}
            />
            <FormCheckbox
              name="gera_comissao"
              label="Gera Comissão"
              checked={store.gera_comissao}
              onChange={() => dispatch(setGeraComissao(!store.gera_comissao))}
            />
            <FormCheckbox
              name="entra_calc_marg_lucro_venda"
              label="Produto comissionado por % de Lucro"
              checked={store.entra_calc_marg_lucro_venda}
              onChange={() =>
                dispatch(
                  setEntraCalcMargLucroVenda(!store.entra_calc_marg_lucro_venda)
                )
              }
            />
          </Row>
          <Row>
            <ComboBox
              label="Origem Fiscal"
              md={12}
              options={origens}
              onChange={(s) => dispatch(setOrigemMerc(s?.value))}
              defaultValue={store.origem_merc}
            />
          </Row>
          <Row>
            <IntegerFormInput
              label="Código ANP"
              md={3}
              onChange={(v) => dispatch(setIdAnp(v))}
              defaultValue={store.id_anp}
              additionalButton={
                <PesqModal
                  route="/cadastro/anp/listar/"
                  keyField="codigo"
                  labelField="descricao"
                  title="ANP"
                  value={store.id_anp}
                  onChange={(v) => dispatch(setIdAnp(v))}
                />
              }
            />
            <TextInput
              label="Tamanho"
              md={2}
              value={store.tamanho}
              onChange={(e, v) => dispatch(setTamanho(v))}
              maxLength={15}
            />
            <TextInput
              label="Cor"
              md={2}
              value={store.cor}
              onChange={(e, v) => dispatch(setCor(v))}
              maxLength={15}
            />
            <AsyncComboBox
              md={3}
              isClearable
              isConcatField
              concatModelName="classe_prod"
              isSearchable
              label="Classe"
              defaultOptions
              onChange={(s) => dispatch(setIdClasse(s?.value))}
              defaultValue={store.id_classe}
            />
          </Row>
          <Divider>APP Mobile</Divider>
          <Row>
            <FormCheckbox
              name="enviar_mobile_forca_venda"
              label="Enviar Produto para Aplicativos de Venda (Pedido e Pronta Entrega)"
              checked={store.enviar_mobile_forca_venda}
              onChange={() =>
                dispatch(
                  setEnviarMobileForcaVenda(!store.enviar_mobile_forca_venda)
                )
              }
              padded={false}
              className="mb-2"
            />
          </Row>
          <Divider>Pronta Entrega</Divider>
          <Row>
            <FormCheckbox
              label="Usa Caixa Fechada na Solicitação de Recarga"
              checked={store.pronta_entr_solic_rec_usa_caixa_fec}
              onChange={() =>
                handleSetProntaEntrSolicRecargaUsaCaixaFec(
                  !store.pronta_entr_solic_rec_usa_caixa_fec
                )
              }
            />
            {store.pronta_entr_solic_rec_usa_caixa_fec && (
              <NumberInput
                md={2}
                label="Qtd. da Caixa"
                onChange={(v) => dispatch(setProntaEntrSolicRecQtdCaixaFec(v))}
                value={store.pronta_entr_solic_rec_qtd_caixa_fec}
                decimalPlaces={qtdCasasDecimaisQtd}
              />
            )}
          </Row>
          <Divider>PDV</Divider>
          <Row className="mb-3">
            <TextInput
              label="Código Balança"
              md={2}
              value={store.cod_balanca}
              onChange={(e, v) => dispatch(setCodBalanca(v))}
              maxLength={20}
            />
            <IntegerFormInput
              label="Dias de Validade"
              md={2}
              defaultValue={store.dias_validade}
              onChange={(v) => dispatch(setDiasValidade(v))}
            />
            <FormCheckbox
              label="Permite Alteração de Preço na Venda"
              checked={store.permite_alt_preco}
              onChange={() =>
                dispatch(setPermiteAltPreco(!store.permite_alt_preco))
              }
            />
          </Row>
          <Divider>Frente de Venda Interna</Divider>
          <Row>
            <FormCheckbox
              label="Caixa Fechada"
              checked={store.usa_caixa_fec}
              onChange={() => handleSetUsaCaixaFec(!store.usa_caixa_fec)}
            />
            {store.usa_caixa_fec && (
              <NumberInput
                md={2}
                label="Qtd. da Caixa"
                onChange={(v) => dispatch(setQtdCaixaFec(v))}
                value={store.qtd_caixa_fec}
                decimalPlaces={2}
              />
            )}
          </Row>
        </TabBody>
      </TabController>
      <Row className="px-2">
        {(action === MODAL_ACTIONS.EDIT || clonar) && (
          <FormButton
            md="auto"
            padded={false}
            color="primary"
            onClick={setModoClonar}
            disabled={clonar}
            disabledHint="Ajuste os dados, se necessário; após, clique em Confirmar"
          >
            Clonar Produto
          </FormButton>
        )}
        {action === MODAL_ACTIONS.EDIT &&
          !clonar &&
          params.utiliza_alteracao_id_produto && (
            <AlterarCodigoModal selected={selected} setSelected={setSelected} />
          )}
        {action === MODAL_ACTIONS.EDIT && (
          <>
            <LogProdutoModal
              isOpen={logsOpen}
              toggle={toggleLogs}
              selected={selected}
            />
            <FormButton padded={false} onClick={toggleLogs}>
              Logs
            </FormButton>
            <TransformarProdutoCuringaModal
              isOpen={transformarCuringaModalOpen}
              toggle={toggleTransformarCuringaModalOpen}
              idProduto={selected}
              nomeProduto={store.nome}
            />
          </>
        )}
        <FormButton
          divClassName="ml-auto"
          md="auto"
          color="success"
          onClick={handleSubmit}
          loading={loadingSubmit}
          padded={false}
        >
          F9 - Confirmar
        </FormButton>
        <FormButton
          md="auto"
          color="danger"
          onClick={() => history.goBack()}
          padded={false}
        >
          Esc - Sair
        </FormButton>
      </Row>
    </Card>
  );
});

export const IncluirAlterarProduto = ({ location }) => {
  const windowParameters = window?.parameters
    ? JSON.parse(window?.parameters)
    : {};
  const action =
    location?.state?.action ?? windowParameters.action ?? MODAL_ACTIONS.ADD;

  const refContainer = useRef();

  return (
    <PageContainer
      title={
        (action === MODAL_ACTIONS.ADD ? "Inclusão" : "Alteração") +
        " de Produto"
      }
      onKeyDown={(e) => {
        if (refContainer.current) {
          refContainer.current.onKeyDown(e);
        }
      }}
      number="0029_1"
      canGoBack
    >
      <Provider store={store}>
        <IncluirAlterarProdutoContainer
          location={location}
          ref={refContainer}
        />
      </Provider>
    </PageContainer>
  );
};
