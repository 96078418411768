import React from "react";
import {
  AsyncComboBox,
  ComboBox,
  ConcatShortcut,
  DatePicker,
  Divider,
  FixedField,
  FormButton,
  FormCheckbox,
  HintLabel,
  IntegerFormInput,
  MaskedInput,
  NumberInput,
  PageContainer,
  RadioGroup,
  Table,
  TextInput,
  UnlockToEdit,
} from "../../../../../components";
import { Card, Col, Label, Row, UncontrolledTooltip } from "reactstrap";
import { useState } from "react";
import { InfoNfFaturadaGrid } from "./components/InfoNfFaturadaGrid";
import { RadioItem } from "../../../../../components/RadioGroup";
import { RiInformationLine } from "react-icons/ri";
import {
  MODAL_ACTIONS,
  formatDateISO,
  formatDateLocal,
  formatNumber,
  formatValueFromAPI,
  roundFloat,
  sumDataField,
} from "../../../../../coreUtils";
import { IncluirAlterarItemNfFatModal } from "./components/IncluirAlterarItemNfFatModal";
import { useEffect } from "react";
import GerarNfVendaOsService from "../../../../../services/faturamento/GerarNfVendaOsService";
import { AlterarInfoCabModal } from "./components/AlterarInfoCabModal";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { gerarNfVendaOsRoute } from "../../../../../routes/modules/faturamento";
import { toastr } from "react-redux-toastr";
import UteisService from "../../../../../services/uteis/UteisService";
import { showError } from "../../../../../components/AlertaModal";
import { v4 as uuidv4 } from "uuid";
import moment, { isMoment } from "moment";
import NotaFiscalService from "../../../../../services/docs_eletron/NotaFiscalService";
import NotaFiscalConsumidorService from "../../../../../services/docs_eletron/NotaFiscalConsumidorService";
import { apiGetV2 } from "../../../../../apiV2";
import { incluirAlterarProdutoRoute } from "../../../../../routes/modules/cadastro";
import { BoletoService } from "../../../../../services/bancario/BoletoService";
import { useSelector } from "react-redux";
import { AlterarCfopModal } from "./components/AlterarCfopModal";

const fretePorContaOptions = [
  { label: "0 - Emitente", value: "0" },
  { label: "1 - Destinatário", value: "1" },
  { label: "2 - Terceiros", value: "2" },
  { label: "9 - Sem Frete", value: "9" },
];

let formasPagGeraMovFinancOptions = [
  { label: "Contas a Receber COM Boleto Bancário", value: "BB" },
  { label: "Cartão de Crédito", value: "CC" },
  { label: "Cartão de Débito", value: "CD" },
  { label: "Cheque", value: "CQ" },
  { label: "Contas a Receber SEM Boleto - Crediário", value: "CI" },
  { label: "Depósito Bancário", value: "DB" },
  { label: "Dinheiro", value: "DI" },
  { label: "PIX", value: "PX" },
  { label: "Transf. Bancária - TED/DOC", value: "TB" },
  { label: "Sem Pagamento", value: "SE" },
];

let formasPagNuncGeraMovFinancOptions = [
  { label: "Boleto Bancário", value: "BB" },
  { label: "Cartão de Crédito", value: "CC" },
  { label: "Cartão de Débito", value: "CD" },
  { label: "Cheque", value: "CQ" },
  { label: "Crediário", value: "CI" },
  { label: "Depósito Bancário", value: "DB" },
  { label: "Dinheiro", value: "DI" },
  { label: "PIX", value: "PX" },
  { label: "Transf. Bancária - TED/DOC", value: "TB" },
  { label: "Sem Pagamento", value: "SE" },
];

const totaisLabelStyle = { color: "black" };
const totaisClassName = "mb-0";

const alterarImpostosItem = (item, itemNovo) => ({
  ...item,
  base_calc_icms: parseFloat(itemNovo.base_calc_icms),
  vlr_icms: parseFloat(itemNovo.vlr_icms),
  base_calc_icms_st: parseFloat(itemNovo.base_calc_icms_st),
  vlr_icms_st: parseFloat(itemNovo.vlr_icms_st),
  base_calc_ipi: parseFloat(itemNovo.base_calc_ipi),
  vlr_ipi: parseFloat(itemNovo.vlr_ipi),
  base_calc_pis: parseFloat(itemNovo.base_calc_pis),
  vlr_pis: parseFloat(itemNovo.vlr_pis),
  base_calc_cofins: parseFloat(itemNovo.base_calc_cofins),
  vlr_cofins: parseFloat(itemNovo.vlr_cofins),
  vlr_total: parseFloat(itemNovo.vlr_total_com_impostos),
  cfop: (itemNovo.cfop ?? item.cfop).trim(),
});

export const InfoNfFaturada = ({ location }) => {
  const alertasOpen = useSelector((store) => store.alerts.isOpen);
  const selected = location?.state?.selected;
  const history = useHistory();
  const [itemAction, setItemAction] = useState(MODAL_ACTIONS.ADD);
  const [itemOpen, setItemOpen] = useState(false);
  const [cfopOpen, setCfopOpen] = useState(false);
  const [uuidItemEdit, setUuidItemEdit] = useState(null);
  const [loadingFetch, setLoadingFetch] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingParcelas, setLoadingParcelas] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [fecharAlertaSair, setFecharAlertaSair] = useState(false);
  // Parâmetros
  const [idContaBancGeraBoleto, setIdContaBancGeraBoleto] = useState(null);
  const [utilizaRegraTributacao, setUtilizaRegraTributacao] = useState(false);
  const [permiteManipularVendaOs, setPermiteManipularVendaOs] = useState(false);
  const [unificarTitulosProdServ, setUnificarTitulosProdServ] = useState(false);
  const [nuncaGeraMovFinanceira, setNuncaGeraMovFinanceira] = useState(true);

  const [clienteTemLimiteContasRec, setClienteTemLimiteContasRec] =
    useState(false);
  const [limiteClienteContasRec, setLimiteClienteContasRec] = useState(0);
  const [limiteDispClienteContasRec, setLimiteDispClienteContasRec] =
    useState(0);

  // Dados da NF
  const [idTipoDoc, setIdTipoDoc] = useState(null);
  const [descTipoDoc, setDescTipoDoc] = useState("");
  const [serieTipoDoc, setSerieTipoDoc] = useState("");
  const [emissao, setEmissao] = useState(new Date());
  const [idCliente, setIdCliente] = useState(null);
  const [nomeCliente, setNomeCliente] = useState("");
  const [infoAdic, setInfoAdic] = useState("");
  const [modeloNfProd, setModeloNfProd] = useState(null);
  const [itens, setItens] = useState([]);
  const [condPags, setCondPags] = useState([]);
  const [vendaBonificada, setVendaBonificada] = useState(false);
  const [podeGerarContasRec, setPodeGerarContasRec] = useState(false);
  const [cliGeraBoletoAuto, setCliGeraBoletoAuto] = useState(true);
  const [dataSaida, setDataSaida] = useState(null);
  const [horaSaida, setHoraSaida] = useState(null);
  // Valores e Impostos
  const [baseCalcIcms, setBaseCalcIcms] = useState(0);
  const [vlrIcms, setVlrIcms] = useState(0);
  const [baseCalcIcmsSt, setBaseCalcIcmsSt] = useState(0);
  const [vlrIcmsSt, setVlrIcmsSt] = useState(0);
  const [vlrTotProd, setVlrTotProd] = useState(0);
  const [vlrFrete, setVlrFrete] = useState(0);
  const [vlrDesconto, setVlrDesconto] = useState(0);
  const [vlrOutros, setVlrOutros] = useState(0);
  const [vlrSeguro, setVlrSeguro] = useState(0);
  const [vlrTotIpi, setVlrTotIpi] = useState(0);
  const [vlrTotNfProd, setVlrTotNfProd] = useState(0);
  const [mostrarDescontoNfe, setMostrarDescontoNfe] = useState(false);
  // Faturas
  const [moedaPag, setMoedaPag] = useState(
    formasPagNuncGeraMovFinancOptions[0].value
  );
  const [tipoPrazo, setTipoPrazo] = useState("P");
  const [idCondPag, setIdCondPag] = useState(null);
  const [faturas, setFaturas] = useState([]);
  const [gerarBoleto, setGerarBoleto] = useState(true);
  // Frete
  const [fretePorConta, setFretePorConta] = useState("");
  const [destacarVlrFreteNf, setDestacarVlrFreteNf] = useState(false);
  const [idTransportadora, setIdTransportadora] = useState(null);
  const [placa, setPlaca] = useState("");
  const [ufVeiculo, setUfVeiculo] = useState("");
  const [qtdVolume, setQtdVolume] = useState(0);
  const [especieVolume, setEspecieVolume] = useState("");
  const [marcaVolume, setMarcaVolume] = useState("");
  const [numeroVolume, setNumeroVolume] = useState("");
  const [pesoBruto, setPesoBruto] = useState(0);
  const [pesoLiquido, setPesoLiquido] = useState(0);

  const vlrTotFinalNf = destacarVlrFreteNf
    ? vlrTotNfProd + vlrFrete
    : vlrTotNfProd;

  const handleAlterarDataVctoFatura = (_, novaData, faturaAntiga) => {
    if (isMoment(novaData)) {
      setFaturas(
        faturas.map((e) => {
          if (e.parcela === faturaAntiga.parcela) {
            return {
              ...e,
              data_vcto: formatDateLocal(novaData),
            };
          }
          return e;
        })
      );
    }
  };

  const columnsFaturas = [
    {
      dataField: "parcela",
      text: "Parcela",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "data_vcto",
      text: "Vencimento",
      align: "center",
      headerAlign: "center",
      editable: true,
      editorType: "date",
      alwaysShowEditor: true,
      onChange: handleAlterarDataVctoFatura,
      borderless: true,
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
  ];

  const toggleItem = () => setItemOpen(!itemOpen);

  const toggleCfop = () => setCfopOpen(!cfopOpen);

  const handleAddItem = () => {
    setItemAction(MODAL_ACTIONS.ADD);
    toggleItem();
  };

  const handleEditItem = (_uuid) => {
    setUuidItemEdit(_uuid);
    setItemAction(MODAL_ACTIONS.EDIT);
    toggleItem();
  };

  const handleEditCfopProduto = (_uuid) => {
    setUuidItemEdit(_uuid);
    toggleCfop();
  };

  const handleDeleteItem = (uuid) =>
    setItens(itens.filter((e) => e.uuid !== uuid));

  const carregarParametros = async () => {
    const [ok, ret] = await apiGetV2(`/tela/faturamento/`);
    if (ok) {
      setIdContaBancGeraBoleto(ret.id_conta_banc_geracao_boleto);
      setUtilizaRegraTributacao(ret.utiliza_regra_tributacao);
      setPermiteManipularVendaOs(ret.permite_manipular_venda_os);
      setUnificarTitulosProdServ(ret.unificar_titulos_prod_serv);
      setNuncaGeraMovFinanceira(ret.nunca_gera_mov_financeira);
      if (ret.unificar_titulos_prod_serv) {
        setMoedaPag("BB");
        setGerarBoleto(true);
      }
      setFecharAlertaSair(false);
      return true;
    } else {
      setFecharAlertaSair(true);
      return false;
    }
  };

  const carregarCondicoesPag = async (idCli) => {
    setCondPags(await GerarNfVendaOsService.listarCondicoesPag(idCli));
  };

  const carregarDados = async () => {
    const [ok, ret] = await GerarNfVendaOsService.gerarDadosFaturarVendaOs({
      origens: selected,
    });

    if (ok) {
      if (ret.itens.length === 0) {
        toastr.warning(
          "Atenção",
          "As Vendas e/ou Ordens de Serviço selecionadas não possuem itens."
        );
        setRedirect(true);
        return false;
      }
      setIdCliente(ret.id_cliente);
      setNomeCliente(ret.nome_cliente);
      setVlrFrete(parseFloat(ret.vlr_frete));
      setIdTipoDoc(ret.id_tipo_doc);
      setDescTipoDoc(ret.desc_tipo_doc);
      setSerieTipoDoc(ret.serie_tipo_doc);
      setVendaBonificada(ret.venda_bonificada);
      setPodeGerarContasRec(ret.pode_gerar_contas_rec);
      if (!ret.pode_gerar_contas_rec) {
        setGerarBoleto(false);
      }
      setCliGeraBoletoAuto(ret.cli_gera_boleto_automaticamente);

      setPesoBruto(parseFloat(ret.peso_bruto));
      setPesoLiquido(parseFloat(ret.peso_liquido));
      setInfoAdic(ret.info_adic_nf ?? "");

      setItens(
        ret.itens.map((e) => {
          const uuid = String(uuidv4());
          return {
            ...e,
            cfop: (e.cfop ?? "").trim(),
            quantidade: parseFloat(e.quantidade),
            vlr_unit: parseFloat(e.vlr_unit),
            vlr_item: parseFloat(e.vlr_item),
            perc_desc: parseFloat(e.perc_desc),
            vlr_desc: parseFloat(e.vlr_desc),
            vlr_acre: parseFloat(e.vlr_acre),
            vlr_tot_prod: parseFloat(e.vlr_tot_prod),
            vlr_total: parseFloat(e.vlr_total),
            base_calc_icms: parseFloat(e.base_calc_icms),
            vlr_icms: parseFloat(e.vlr_icms),
            base_calc_icms_st: parseFloat(e.base_calc_icms_st),
            vlr_icms_st: parseFloat(e.vlr_icms_st),
            base_calc_ipi: parseFloat(e.base_calc_ipi),
            vlr_ipi: parseFloat(e.vlr_ipi),
            base_calc_pis: parseFloat(e.base_calc_pis),
            vlr_pis: parseFloat(e.vlr_pis),
            base_calc_cofins: parseFloat(e.base_calc_cofins),
            vlr_cofins: parseFloat(e.vlr_cofins),
            nro_ordem_compra: e.nro_ordem_compra ?? "",
            uuid: uuid,
          };
        })
      );
      setFecharAlertaSair(false);
      return true;
    } else {
      setFecharAlertaSair(true);
      return false;
    }
  };

  const iniciarTela = async () => {
    if (!(await carregarParametros())) {
      setLoadingFetch(false);
      return false;
    }
    if (!(await carregarDados())) {
      setLoadingFetch(false);
      return false;
    }
    setLoadingFetch(false);
  };

  const handleAlterarInfoCab = (data) => {
    setIdCliente(data.id_cliente);
    setNomeCliente(data.nome_cliente);
    setIdTipoDoc(data.id_tipo_doc);
    setDescTipoDoc(data.desc_tipo_doc);
    setSerieTipoDoc(data.serie_tipo_doc);
    setEmissao(moment(data.emissao).toDate());
    setItens(
      itens.map((item) => {
        const itemNovo = data.itens.find((e) => e.uuid === item.uuid);

        // Muda apenas os valores de impostos e CFOP
        return alterarImpostosItem(item, itemNovo);
      })
    );
  };

  const addItem = (payload) => {
    setItens([...itens, payload]);
  };

  const editItem = (payload) => {
    setItens(itens.map((e) => (e.uuid === payload.uuid ? payload : e)));
  };

  const totalizarItens = () => {
    setBaseCalcIcms(roundFloat(sumDataField(itens, "base_calc_icms"), 2));
    setVlrIcms(roundFloat(sumDataField(itens, "vlr_icms"), 2));
    setBaseCalcIcmsSt(roundFloat(sumDataField(itens, "base_calc_icms_st"), 2));
    setVlrIcmsSt(roundFloat(sumDataField(itens, "vlr_icms_st"), 2));
    setVlrTotProd(roundFloat(sumDataField(itens, "vlr_tot_prod"), 2));
    setVlrDesconto(roundFloat(sumDataField(itens, "vlr_desc"), 2));
    setVlrTotIpi(roundFloat(sumDataField(itens, "vlr_ipi"), 2));
    setVlrTotNfProd(roundFloat(sumDataField(itens, "vlr_total"), 2));
  };

  const calcularParcelasFatura = async () => {
    setLoadingParcelas(true);
    try {
      if (tipoPrazo === "V") {
        setFaturas([
          {
            numero: "",
            data_vcto: formatDateLocal(new Date()),
            parcela: 1,
            valor: parseFloat(vlrTotFinalNf),
            entrada: false,
          },
        ]);
      } else {
        if (!idCondPag) {
          setFaturas([]);
          return;
        }

        const [ok, ret] = await UteisService.calcularParcelasCondPag(
          vlrTotFinalNf,
          idCondPag
        );
        if (ok) {
          setFaturas(
            ret.map((e, index) => ({
              ...e,
              data_vcto: formatDateLocal(e.data_vcto),
              parcela: index + 1,
              valor: parseFloat(e.valor),
            }))
          );
        }
      }
    } finally {
      setLoadingParcelas(false);
    }
  };

  const handleSubmit = async () => {
    if (!fretePorConta) {
      toastr.warning(
        "Atenção",
        "Por favor, informe por conta de quem é o frete."
      );
      return false;
    }

    if (["BB", "CI"].includes(moedaPag) && faturas.length === 0) {
      toastr.warning(
        "Atenção",
        "Por favor, informe as faturas para geração de boletos/contas a receber."
      );
      return false;
    }

    if (!modeloNfProd) {
      toastr.warning("Atenção", "Por favor, selecione o Modelo da NF.");
      return false;
    }

    const fretePayload = {
      frete_por_conta: fretePorConta,
      id_transportadora: idTransportadora ?? null,
      placa_veic: placa,
      uf_veic: ufVeiculo,
      qtd_volumes: qtdVolume,
      especie_frete: especieVolume,
      marca_frete: marcaVolume,
      numero_volume: numeroVolume,
      peso_bruto: pesoBruto ?? null,
      peso_liquido: pesoLiquido ?? null,
    };

    const faturasPayload = {
      forma_pag: moedaPag,
      a_vista: tipoPrazo === "V",
      id_conta_banc_boletos: moedaPag === "BB" ? idContaBancGeraBoleto : null,
      parcelas: faturas.map((e) => ({
        parcela: e.parcela,
        data_vcto: moment(e.data_vcto, "DD/MM/YYYY").format("YYYY-MM-DD"),
        valor: e.valor,
        entrada: e.entrada,
      })),
    };

    const itensPayload = itens.map((e) => ({
      id_produto: e.id_produto,
      nome_produto: e.nome_produto,
      referencia: e.referencia,
      clas_fiscal: e.clas_fiscal,
      unidade: e.unidade,
      quantidade: e.quantidade,
      cfop: e.cfop,
      vlr_unit: e.vlr_unit,
      vlr_item: e.vlr_item,
      perc_desc: e.perc_desc,
      vlr_desc: e.vlr_desc,
      vlr_acre: e.vlr_acre,
      vlr_tot_prod: e.vlr_tot_prod,
      vlr_total: e.vlr_total,
      base_calc_icms: e.base_calc_icms,
      vlr_icms: e.vlr_icms,
      base_calc_icms_st: e.base_calc_icms_st,
      vlr_icms_st: e.vlr_icms_st,
      base_calc_ipi: e.base_calc_ipi,
      vlr_ipi: e.vlr_ipi,
      nro_ordem_compra: e.nro_ordem_compra ?? "",
      prod_convertido: e.prod_convertido ?? false,
      id_prod_orig: e.id_prod_orig ?? null,
      nome_prod_orig: e.nome_prod_orig ?? null,
      un_prod_orig: e.un_prod_orig ?? null,
      fator_conversao: e.fator_conversao ? parseFloat(e.fator_conversao) : null,
    }));

    const payload = {
      id_cliente: idCliente,
      nome_cliente: nomeCliente,
      id_tipo_doc: idTipoDoc,
      origens: selected,
      frete: fretePayload,
      faturas: faturasPayload,
      itens: itensPayload,
      mostrar_desconto_nfe: mostrarDescontoNfe,
      destacar_frete_nfe: destacarVlrFreteNf,
      venda_bonificada: vendaBonificada,
      modelo_nf_prod: modeloNfProd,
      gerar_boleto: gerarBoleto,
      vlr_tot_prod: vlrTotProd,
      vlr_tot_icms_st: vlrIcmsSt,
      vlr_tot_ipi: vlrTotIpi,
      vlr_tot_desc: vlrDesconto,
      vlr_tot_frete: vlrFrete,
      vlr_total: roundFloat(vlrTotNfProd, 2),
      info_adic: infoAdic,
      id_cond_pag_unifica: unificarTitulosProdServ ? idCondPag : null,
      data_saida: formatDateISO(dataSaida),
      hora_saida: horaSaida,
    };

    setFecharAlertaSair(false);
    setLoadingSubmit(true);
    const [ok, ret] = await GerarNfVendaOsService.faturarVendaOs(payload);
    if (ok) {
      const res = ret.res;
      if (res.emite_nf_auto && res.nf_emitida) {
        toastr.success("Sucesso", ret.msg);
        const idNf = res.id_nf;
        if (res.tipo_nf === "NFE") {
          await NotaFiscalService.imprimir(idNf);
        } else {
          await NotaFiscalConsumidorService.imprimir(idNf);
        }

        if (res.imprimir_boletos) {
          const duplicRecs = res.duplic_recs;
          for (let i = 0; i < duplicRecs.length; i++) {
            await BoletoService.imprimirBoleto(duplicRecs[i]);
          }
        }
        setRedirect(true);
      } else if (!res.emite_nf_auto) {
        toastr.success(
          "Sucesso",
          "A NF-e foi gerada e está pendente para emissão no Gerenciamento de Notas Fiscais"
        );
        setRedirect(true);
      } else {
        setFecharAlertaSair(true);
        showError(
          `A Nota Fiscal foi gerada, porém, houve um problema ao emití-la: ${res.msg_erro_nfe}`,
          ""
        );
        setLoadingSubmit(false);
      }
    }
    setLoadingSubmit(false);
  };

  const alterarCadProduto = () => {
    const id = Math.floor(Math.random() * Date.now());
    const cadastro = window.open(incluirAlterarProdutoRoute.path);

    const idProduto = itens.find((e) => e.uuid === uuidItemEdit).id_produto;

    cadastro.window.parameters = JSON.stringify({
      id: id,
      action: MODAL_ACTIONS.EDIT,
      selected: idProduto,
    });

    const func = (event) => {
      if (event.origin !== window.location.origin && !event.data?.selected) {
        return;
      }

      if (event.data.id !== id) {
        return;
      }

      carregarDados();
      setUuidItemEdit("");

      window.removeEventListener("message", func);
    };

    window.addEventListener("message", func);
  };

  const editCfopProduto = async (payload) => {
    setItens(
      itens.map((e) =>
        e.uuid === uuidItemEdit ? alterarImpostosItem(e, payload) : e
      )
    );
  };

  const buscarLimiteContasReceberCliente = async () => {
    const [ok, ret] = await apiGetV2(
      `/cadastro/cliente/buscar_limites/${idCliente}/`
    );
    if (ok) {
      setClienteTemLimiteContasRec(ret.tem_limite_contas_receber);
      setLimiteClienteContasRec(parseFloat(ret.limite_contas_receber));
      setLimiteDispClienteContasRec(parseFloat(ret.limite_disponivel));
    } else {
      setClienteTemLimiteContasRec(false);
      setLimiteClienteContasRec(0);
      setLimiteDispClienteContasRec(0);
    }
  };

  useEffect(() => {
    iniciarTela();
  }, []);

  useEffect(() => {
    if (idCliente) {
      buscarLimiteContasReceberCliente(idCliente);
    } else {
      setClienteTemLimiteContasRec(false);
      setLimiteClienteContasRec(0);
      setLimiteDispClienteContasRec(0);
    }
  }, [idCliente]);

  useEffect(() => {
    if (![0, null, undefined].includes(idCliente)) {
      carregarCondicoesPag(idCliente);
    }
  }, [idCliente]);

  useEffect(() => {
    if (moedaPag !== "BB") {
      setGerarBoleto(false);
      setIdCondPag(null);
      setIdContaBancGeraBoleto(null);
      setFaturas([]);
      if (tipoPrazo === "V" && moedaPag !== "SE") {
        calcularParcelasFatura();
      }
    } else {
      // Quando for BB => Contas a Receber COM Boleto Bancário, define o
      // campo para true automaticamente
      setGerarBoleto(true);
      setTipoPrazo("P");
    }
  }, [moedaPag]);

  useEffect(() => {
    totalizarItens();
  }, [itens]);

  useEffect(() => {
    if (tipoPrazo === "V") {
      setIdCondPag(null);
    }
    if (tipoPrazo) {
      calcularParcelasFatura();
    }
  }, [tipoPrazo]);

  useEffect(() => {
    if (!alertasOpen && fecharAlertaSair) {
      setRedirect(true);
    }
  }, [fecharAlertaSair, alertasOpen]);

  if (redirect) {
    return <Redirect to={gerarNfVendaOsRoute.path} />;
  }

  const vendas = selected.filter((e) => e.tipo === "VDA").map((e) => e.id_tipo);

  const oss = selected.filter((e) => e.tipo === "OSV").map((e) => e.id_tipo);

  return (
    <PageContainer
      title="Informações da Nota Fiscal Faturada"
      number="0036_1"
      loading={loadingFetch}
      canGoBack
    >
      <Card body>
        <Row>
          <FixedField
            label="Cliente"
            value={formatValueFromAPI(nomeCliente, idCliente)}
            horizontal
          />
          <FixedField
            label="Emissão"
            value={formatDateLocal(emissao)}
            horizontal
          />
          <FixedField
            label="Tipo de Documento"
            value={descTipoDoc}
            horizontal
          />
          <FixedField label="Série" value={serieTipoDoc} horizontal />
          <AlterarInfoCabModal
            idCliente={idCliente}
            idTipoDoc={idTipoDoc}
            emissao={emissao}
            itens={itens}
            modeloNfe={modeloNfProd}
            handleAlterarInfoCab={handleAlterarInfoCab}
          />
        </Row>
      </Card>
      <Card body>
        <Row className="mb-2">
          {vendaBonificada ? (
            <Label md="auto" style={{ fontStyle: "italic" }}>
              As vendas selecionadas são de bonificação. Não é permitida
              alteração/inclusão de produtos.
            </Label>
          ) : (
            permiteManipularVendaOs && (
              <FormButton
                md="auto"
                color="success"
                padded={false}
                onClick={handleAddItem}
                disabled={[0, null, undefined].includes(idCliente)}
                disabledHint="Selecione o cliente"
              >
                Adicionar Item
              </FormButton>
            )
          )}
          <FormButton
            md="auto"
            padded={false}
            color="warning"
            onClick={alterarCadProduto}
            disabled={["", null, undefined].includes(uuidItemEdit)}
          >
            Alterar Produto
          </FormButton>
          <IncluirAlterarItemNfFatModal
            isOpen={itemOpen}
            toggle={toggleItem}
            action={itemAction}
            itens={itens}
            uuidItemEdit={uuidItemEdit}
            idCliente={idCliente}
            handleAdd={addItem}
            handleEdit={editItem}
            utilizaRegraTributacao={utilizaRegraTributacao}
            vlrFrete={vlrFrete}
          />
          <AlterarCfopModal
            isOpen={cfopOpen}
            toggle={toggleCfop}
            itens={itens}
            idCliente={idCliente}
            uuidItemEdit={uuidItemEdit}
            editCfopProduto={editCfopProduto}
          />
        </Row>
        <InfoNfFaturadaGrid
          itens={itens}
          handleEdit={handleEditItem}
          handleDelete={handleDeleteItem}
          podeAlterar={permiteManipularVendaOs && !vendaBonificada}
          setSelected={setUuidItemEdit}
          handleEditCfopProduto={handleEditCfopProduto}
        />
      </Card>
      <Card body>
        <Divider className="mt-0">Informações de Valores</Divider>
        <Row className="mb-2">
          <FixedField
            md={2}
            label="Base Cálc. ICMS"
            value={formatNumber(baseCalcIcms, true, 2)}
            labelStyle={totaisLabelStyle}
            labelClassName={totaisClassName}
          />
          <FixedField
            md={2}
            label="Vlr. ICMS"
            value={formatNumber(vlrIcms, true, 2)}
            labelStyle={totaisLabelStyle}
            labelClassName={totaisClassName}
          />
          <FixedField
            md={2}
            label="Base Cálc. ICMS ST"
            value={formatNumber(baseCalcIcmsSt, true, 2)}
            labelStyle={totaisLabelStyle}
            labelClassName={totaisClassName}
          />
          <FixedField
            md={2}
            label="Vlr. ICMS ST"
            value={formatNumber(vlrIcmsSt, true, 2)}
            labelStyle={totaisLabelStyle}
            labelClassName={totaisClassName}
          />
          <FixedField
            md={2}
            label="Total Produtos"
            value={formatNumber(vlrTotProd, true, 2)}
            labelStyle={totaisLabelStyle}
            labelClassName={totaisClassName}
            divClassName="ml-auto"
          />
        </Row>
        <Row>
          <FixedField
            md={2}
            label="Total IPI"
            value={formatNumber(vlrTotIpi, true, 2)}
            labelStyle={totaisLabelStyle}
            labelClassName={totaisClassName}
          />
          <FixedField
            md={2}
            label="Seguro"
            value={formatNumber(vlrSeguro, true, 2)}
            labelStyle={totaisLabelStyle}
            labelClassName={totaisClassName}
          />
          <FixedField
            md={2}
            label="Outras Despesas"
            value={formatNumber(vlrOutros, true, 2)}
            labelStyle={totaisLabelStyle}
            labelClassName={totaisClassName}
          />
          <FixedField
            md={2}
            label={
              <>
                Desconto
                <FormCheckbox
                  padded={false}
                  label="Mostrar NF"
                  checked={mostrarDescontoNfe}
                  onChange={() => setMostrarDescontoNfe(!mostrarDescontoNfe)}
                  className="pt-0"
                  divClassName="pr-0"
                />
              </>
            }
            value={formatNumber(vlrDesconto, true, 2)}
            labelStyle={{ ...totaisLabelStyle, display: "flex" }}
            labelClassName={totaisClassName}
            divClassName="pr-0"
          />
          <FixedField
            md={2}
            label="TOTAL NOTA FISCAL"
            value={formatNumber(vlrTotFinalNf, true, 2)}
            labelStyle={totaisLabelStyle}
            labelClassName={totaisClassName}
            divClassName="ml-auto"
          />
        </Row>
      </Card>
      <Card body>
        <Row>
          <Col md={6}>
            <Divider className="mt-0">
              Informações de Fatura e Forma de Pagamento
            </Divider>
            <Row>
              <ComboBox
                md={5}
                label="Moeda de Pagamento"
                options={
                  nuncaGeraMovFinanceira
                    ? formasPagNuncGeraMovFinancOptions
                    : formasPagGeraMovFinancOptions
                }
                defaultValue={moedaPag}
                onChange={(s) => setMoedaPag(s?.value)}
                isDisabled={unificarTitulosProdServ}
                isSearchable={false}
              />
            </Row>
            {moedaPag !== "SE" && (
              <>
                {!nuncaGeraMovFinanceira && (
                  <>
                    {!podeGerarContasRec && ["BB", "CI"].includes(moedaPag) && (
                      <Row>
                        <Label md="auto" style={{ color: "#002a57" }}>
                          Atenção!{" "}
                          {selected.length > 1 ? (
                            <>
                              Uma ou mais vendas/O.S. do faturamento já foram
                              recebidas no caixa loja.
                              <br />
                              Não serão geradas as duplicatas no Contas a
                              Receber através do faturamento.
                            </>
                          ) : (
                            <>
                              A venda/O.S. que está sendo faturada já foi
                              recebida no Caixa Loja.
                              <br />
                              Não serão geradas duplicatas no Contas a Receber
                              no processo de faturamento.
                            </>
                          )}
                          <br />
                          As faturas informadas abaixo serão destacadas na
                          NF-e/NFC-e.
                        </Label>
                      </Row>
                    )}
                    {["BB", "CI"].includes(moedaPag) && (
                      <>
                        {moedaPag === "BB" && podeGerarContasRec && (
                          <Row>
                            {cliGeraBoletoAuto ? (
                              <AsyncComboBox
                                md={10}
                                isConcatField
                                concatModelName="conta_banc"
                                label="Conta Bancária - Boletos"
                                isSearchable
                                onChange={(s) =>
                                  setIdContaBancGeraBoleto(s?.value)
                                }
                                defaultValue={idContaBancGeraBoleto}
                                defaultOptions
                              />
                            ) : (
                              <Label md="auto" style={{ color: "#002a57" }}>
                                Atenção! O Cliente está definido para NÃO gerar
                                boletos automaticamente. Serão geradas SOMENTE
                                as duplicatas no Contas a Receber.
                                <br />
                                Caso deseje imprimir os Boletos junto da NF-e,
                                acesse o Cadastro do Cliente na aba Informações
                                Adicionais e ajuste o campo{" "}
                                <b>Gerar Boletos Automaticamente</b>.
                                <br />
                                As faturas informadas abaixo serão destacadas na
                                NF-e/NFC-e.
                              </Label>
                            )}
                          </Row>
                        )}
                        {unificarTitulosProdServ && (
                          <Row>
                            <Label md="auto">
                              Seu sistema está configurado para agrupar as
                              cobranças no faturamento, e a cobrança referente a
                              esta nota será gerada em Contas a Receber. Caso
                              haja alguma nota fiscal de serviço gerada
                              anteriormente, as cobranças serão agrupadas.
                            </Label>
                          </Row>
                        )}
                      </>
                    )}
                  </>
                )}
                <Row>
                  <RadioGroup
                    md={8}
                    label="Tipo de Pagamento"
                    value={tipoPrazo}
                    onChange={setTipoPrazo}
                    disabled={moedaPag === "BB"}
                    disabledHint={
                      "Não é possível emitir boletos com a data de vencimento igual a hoje"
                    }
                  >
                    <RadioItem label="A Prazo" value="P" />
                    <RadioItem label="A Vista" value="V" />
                  </RadioGroup>
                </Row>
                {tipoPrazo === "P" && (
                  <Row>
                    <ComboBox
                      label={
                        <HintLabel
                          label="Condição de Pagamento"
                          hintText={
                            "As Condições de Pagamento listadas abaixo são " +
                            "atribuídas em Pronta Entrega > Cadastro > " +
                            "Formas de Recebimento."
                          }
                        />
                      }
                      md={8}
                      options={condPags}
                      isSearchable
                      onChange={(s) => setIdCondPag(s?.value)}
                      defaultValue={idCondPag}
                      additionalButton={
                        <ConcatShortcut
                          concatModelName="cond_pag"
                          selected={idCondPag}
                          setSelected={setIdCondPag}
                        />
                      }
                    />
                  </Row>
                )}
                <Row className="">
                  {["BB", "CI"].includes(moedaPag) &&
                    (nuncaGeraMovFinanceira ? (
                      <Label md="8">
                        O número das faturas da nota fiscal será igual ao número
                        da NF-e emitida
                        <br />
                        <strong>
                          O sistema está configurado para NÃO gerar movimentação
                          financeira no faturamento, portanto, a informação de
                          Forma de Pagamento será considerada APENAS a nivel de
                          informação na Nota Fiscal.
                        </strong>
                      </Label>
                    ) : (
                      <Label md="8">
                        O número dos boletos, as faturas da nota fiscal e os
                        títulos a receber serão iguais ao número da nota fiscal
                        emitida
                      </Label>
                    ))}
                </Row>
                <Row className="mb-3">
                  {nuncaGeraMovFinanceira === false && (
                    <>
                      {(moedaPag === "BB" || moedaPag === "CI") && (
                        <>
                          {clienteTemLimiteContasRec === true && (
                            <>
                              <FixedField
                                horizontal
                                label="Limite Cliente"
                                padded
                                value={formatNumber(
                                  limiteClienteContasRec,
                                  false,
                                  2
                                )}
                              />
                              <FixedField
                                horizontal
                                padded
                                label="Limite Disponível"
                                value={formatNumber(
                                  limiteDispClienteContasRec,
                                  false,
                                  2
                                )}
                                textStyle={
                                  limiteDispClienteContasRec < 0 ||
                                  limiteDispClienteContasRec < vlrTotNfProd
                                    ? { color: "red" }
                                    : {}
                                }
                              />
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {tipoPrazo === "P" && (
                    <FormButton
                      md="auto"
                      color="primary"
                      divClassName="ml-auto"
                      padded={false}
                      onClick={calcularParcelasFatura}
                      loading={loadingParcelas}
                      disabled={[0, null, undefined].includes(idCondPag)}
                      disabledHint="Informe a Condição de Pagamento"
                    >
                      F4 - Calcular
                    </FormButton>
                  )}
                </Row>
                <Row>
                  <Col md={12}>
                    <Table
                      data={faturas}
                      columns={columnsFaturas}
                      paginated={false}
                      showRegisterCount={false}
                      pageSize={4}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Col>
          <Col md={6}>
            <Divider className="mt-0">Informações de Frete</Divider>
            <Row>
              <ComboBox
                md={4}
                label="Por Conta"
                options={fretePorContaOptions}
                defaultValue={fretePorConta}
                onChange={(s) => setFretePorConta(s?.value)}
              />
              <FormCheckbox
                md={4}
                label="Destacar Valor de Frete na NF"
                checked={destacarVlrFreteNf}
                onChange={() => setDestacarVlrFreteNf(!destacarVlrFreteNf)}
              />
              <Col md={1}>
                <Label>&#8205;</Label>
                <RiInformationLine
                  size={25}
                  id="hint-frete"
                  style={{ display: "block" }}
                />
              </Col>
              <UncontrolledTooltip target="hint-frete">
                Se a opção <b>"Destacar Valor de Frete na NF"</b> estiver{" "}
                <b>marcada</b>, o sistema irá ratear o valor do frete entre os
                produtos da NF-e e tributar o frete.
                <br />
                Se a opção estiver <b>desmarcada</b>, o frete será considerado
                na cobrança e não será destacado na Nota Fiscal.
              </UncontrolledTooltip>
              <UnlockToEdit>
                <NumberInput
                  md={3}
                  label="Frete"
                  value={vlrFrete}
                  onChange={setVlrFrete}
                />
              </UnlockToEdit>
            </Row>
            {vlrFrete > 0 && destacarVlrFreteNf && (
              <Label>
                O Valor do Frete não está considerado nos valores dos produtos
                da grade acima e será fracionado proporcionalmente entre todos
                os produtos da NF.
              </Label>
            )}
            <Row>
              <AsyncComboBox
                md={12}
                isConcatField
                concatModelName="transportadora"
                label="Transportadora"
                isSearchable
                onChange={(s) => setIdTransportadora(s?.value)}
                defaultValue={idTransportadora}
              />
            </Row>
            <Row>
              <MaskedInput
                md={4}
                mask="aaa-9*99"
                label="Placa"
                value={placa}
                onChange={(e, v) => setPlaca(v)}
                maskChar={null}
              />
              <MaskedInput
                md={2}
                mask="aa"
                label="UF do Veículo"
                value={ufVeiculo}
                onChange={(e, v) => setUfVeiculo(v)}
                maskChar={null}
              />
            </Row>
            <Row>
              <TextInput
                md={4}
                hint="Exemplo: Caixa, Palete, Fardo"
                label="Espécie"
                value={especieVolume}
                onChange={(e, v) => setEspecieVolume(v)}
              />
              <TextInput
                md={4}
                label="Marca"
                value={marcaVolume}
                onChange={(e, v) => setMarcaVolume(v)}
              />
            </Row>
            <Row>
              <TextInput
                md={4}
                label="Numeração do Volume"
                value={numeroVolume}
                onChange={(e, v) => setNumeroVolume(v)}
              />
              <IntegerFormInput
                md={4}
                label="Quantidade de Volume(s)"
                defaultValue={qtdVolume}
                onChange={setQtdVolume}
              />
            </Row>
            <Row>
              <NumberInput
                md={4}
                label="Peso Bruto"
                value={pesoBruto}
                onChange={setPesoBruto}
              />
              <NumberInput
                md={4}
                label="Peso Líquido"
                value={pesoLiquido}
                onChange={setPesoLiquido}
              />
            </Row>
          </Col>
        </Row>
      </Card>
      <Card body>
        <Row>
          <TextInput
            md={7}
            label="Informações Adicionais"
            value={infoAdic}
            onChange={(e, v) => setInfoAdic(v)}
            type="textarea"
          />
          <DatePicker
            label="Data de Saída"
            md={2}
            value={dataSaida}
            onChange={(v) => setDataSaida(moment.isMoment(v) ? v.toDate() : v)}
          />
          <TextInput
            type="time"
            label="Hora de Saída"
            md={2}
            value={horaSaida}
            onChange={(_, v) => setHoraSaida(v)}
          />
        </Row>
        <Row className="my-2">
          <Label md="auto">
            Faturamento da(s){" "}
            {[
              vendas.length > 0 ? `Venda(s) ${vendas.join(", ")}` : null,
              oss.length > 0 ? `Ordem(ns) de Serviço ${oss.join(", ")}` : null,
            ]
              .filter((e) => e)
              .join(" e ")}
          </Label>
        </Row>
        <Row>
          <RadioGroup
            label="Modelo da Nota Fiscal"
            value={modeloNfProd}
            onChange={setModeloNfProd}
            labelStyle={{ fontSize: "0.9rem" }}
          >
            <RadioItem label="NFe - Modelo 55" value="NFE" />
            <RadioItem
              label="NFCe - Nota Fiscal Consumidor Mod. 65"
              value="NFCE"
            />
          </RadioGroup>
          <FormButton
            md="auto"
            color="success"
            divClassName="ml-auto"
            onClick={handleSubmit}
            loading={loadingSubmit}
          >
            F9 - Confirmar
          </FormButton>
          <FormButton md="auto" color="danger" onClick={() => history.goBack()}>
            Esc - Sair
          </FormButton>
        </Row>
      </Card>
    </PageContainer>
  );
};
