import React, {
  useEffect,
  useImperativeHandle,
  useRef,
  forwardRef,
} from "react";
import { Card, Row, Label, Col } from "reactstrap";
import {
  FixedField,
  FormButton,
  IconButton,
  LabelButton,
  NumberInput,
  PageContainer,
} from "../../../../components";
import { useState } from "react";
import { BsCurrencyDollar, BsMagic, BsPersonGear } from "react-icons/bs";
import {
  formatNumber,
  formatValueFromAPI,
  roundFloat,
} from "../../../../coreUtils";
import { RecCartaoModal } from "./components/RecCartaoModal";
import { RecCrediarioModal } from "./components/RecCrediarioModal";
import { RecChequeModal } from "./components/RecChequeModal";
import { Provider, useDispatch, useSelector } from "react-redux";
import store from "./store";
import {
  addRecebCartao,
  addRecebCreditoCliente,
  addRecebDinheiro,
  iniciarNovoRecebimento,
  setValorTrocoCredito,
  setValorTrocoDinheiro,
} from "./store/moedas_rec_slice";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { urlParams } from "../../../../api";
import { RecTransfBancModal } from "./components/RecTransfBancModal";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { caixaLojaRoute } from "../../../../routes/modules/financeiro";
import { RecCreditoRotativoModal } from "./components/RecCreditoRotativoModal";
import { UsarCredClienteModal } from "./components/UsarCredClienteModal";
import CaixaLojaService from "../../../../services/financeiro/CaixaLojaService";
import { ConfirmarRecModal } from "./components/ConfirmarRecModal";
import { FaturarNfModal } from "./components/FaturarNfModal";
import { RecCartaoMultiplosCartoesModal } from "./components/RecCartaoMultiplosCartoesModal";
import { apiGetV2 } from "../../../../apiV2";
import { AplicarDescontoModal } from "./components/AplicarDescontoModal";

const LinhaMoeda = ({
  label,
  description,
  value,
  onChange,
  color,
  sideAction,
  sideIcon = BsMagic,
  noSideAction,
  autoFocus,
  disabled,
}) => {
  const inputRef = useRef();

  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 35);
    }
  }, []);

  return (
    <Row className="mb-3">
      <Label
        md={5}
        style={{
          fontSize: "1.2rem",
          textAlign: "right",
          paddingBottom: "1rem",
          fontWeight: 600,
          color: color,
          paddingLeft: 0,
          paddingRight: "0.2rem",
        }}
      >
        {label}
      </Label>
      <Col md={6} className="px-0">
        <NumberInput
          md={12}
          value={value}
          onChange={onChange}
          style={{
            fontSize: "1.5rem",
            fontWeight: 600,
            color: color,
            height: "calc(1.8125rem + 6px)",
          }}
          divClassName="pl-2"
          formGroupClassName="mb-0"
          disabled={disabled}
          onKeyDown={(e) => e.key === "F4" && sideAction()}
          ref={inputRef}
        />
        <Label className="ml-2 mb-0" style={{ color: color }}>
          {description}
        </Label>
      </Col>
      {!noSideAction && (
        <IconButton icon={sideIcon} onClick={sideAction} className="mt-2" />
      )}
    </Row>
  );
};

const CardValor = ({ label, value, color, textColor = "white" }) => (
  <div
    style={{
      width: "100%",
      paddingBlock: "0.5rem",
      backgroundColor: color,
      display: "flex",
      flexDirection: "column",
      marginBottom: "0.7rem",
      borderRadius: "0.5rem",
    }}
  >
    <h4
      style={{
        fontWeight: 600,
        color: textColor,
        textAlign: "center",
        marginBottom: "0.1rem",
      }}
    >
      {label}
    </h4>
    <h3
      style={{
        fontWeight: 600,
        color: textColor,
        textAlign: "center",
        marginBottom: 0,
      }}
    >
      {formatNumber(value, true, 2)}
    </h3>
  </div>
);

export const CaixaLojaReceberContainer = forwardRef(({ location }, ref) => {
  const history = useHistory();
  const [redirectBack, setRedirectBack] = useState(false);
  const [idMovCaixaLoja, setIdMovCaixaLoja] = useState(0);
  const [idCliente, setIdCliente] = useState(0);
  const [nomeCliente, setNomeCliente] = useState("");
  const [vlrSaldoCredCliente, setVlrSaldoCredCliente] = useState(0);
  const [origem, setOrigem] = useState("");
  const [idOrigem, setIdOrigem] = useState(null);
  // Controle Recebimento
  const [vlrAPagar, setVlrAPagar] = useState(0);
  const [vlrLancar, setVlrLancar] = useState(0);
  const [vlrTroco, setVlrTroco] = useState(0);
  const [vlrCredTroco, setVlrCredTroco] = useState(0);
  const [vlrTotalPago, setVlrTotalPago] = useState(0);
  const [recebendo, setRecebendo] = useState(false);

  // Adiantamento
  const [adiantamento, setAdiantamento] = useState(false);
  const [formaPagAdiantNegoc, setFormaPagAdiantNegoc] = useState("");
  const [nomeCondPagAdiantNegoc, setNomeCondPagAdiantNegoc] = useState("");
  const [valorAdiantNegoc, setValorAdiantNegoc] = useState("");

  // Moedas
  const [vlrDinheiro, setVlrDinheiro] = useState(0);
  const [vlrCartao, setVlrCartao] = useState(0);
  const [vlrCheque, setVlrCheque] = useState(0);
  const [vlrCrediario, setVlrCrediario] = useState(0);
  const [vlrBancario, setVlrBancario] = useState(0);
  const [vlrRotativo, setVlrRotativo] = useState(0);
  const [vlrSaldoDevRotativo, setVlrSaldoDevRotativo] = useState(0);
  const [vlrCredCliente, setVlrCredCliente] = useState(0);
  const [obsCredCliente, setObsCredCliente] = useState("");

  // Redux
  const recebimento = useSelector((state) => state.moedasRec);
  const dispatch = useDispatch();

  // Controle de modais
  const [cartaoOpen, setCartaoOpen] = useState(false);
  const [cartaoMultiploOpen, setCartaoMultiploOpen] = useState(false);
  const [chequeOpen, setChequeOpen] = useState(false);
  const [crediarioOpen, setCrediarioOpen] = useState(false);
  const [bancarioOpen, setBancarioOpen] = useState(false);
  const [rotativoOpen, setRotativoOpen] = useState(false);
  const [usarCredClienteOpen, setUsarCredClienteOpen] = useState(false);
  const [confirmarRecOpen, setConfirmarRecOpen] = useState(false);

  // TEF
  const [temTef, setTemTef] = useState(false);
  const [pixTefHabilitado, setPixTefHabilitado] = useState(false);
  const [idContaBancPix, setIdContaBancPix] = useState(null);

  // Cartão
  const [detalharDadosCartao, setDetalharDadosCartao] = useState(true);
  const [idBandeiraPadraoCartao, setIdBandeiraPadraoCartao] = useState(null);

  const faturarNfModalRef = useRef();

  //Limite contas a receber
  const [clienteTemLimiteContasRec, setClienteTemLimiteContasRec] =
    useState(false);
  const [limiteClienteContasRec, setLimiteClienteContasRec] = useState(0);
  const [limiteDispClienteContasRec, setLimiteDispClienteContasRec] =
    useState(0);

  //Busca se o cliente possui o parametro que permite inserir um limite de contas a receber habilitado e quais são os valores de limite
  //Se a comunicação com a rota funcionar corretamente e houverem valores, seta nas variaveis locais o retorno da rota (variaveis do banco)
  //Se não, atribui false e 0 às variaveis.
  const buscarLimiteContasReceberCliente = async () => {
    const [ok, ret] = await apiGetV2(
      `/cadastro/cliente/buscar_limites/${idCliente}/`
    );
    if (ok) {
      setClienteTemLimiteContasRec(ret.tem_limite_contas_receber);
      setLimiteClienteContasRec(parseFloat(ret.limite_contas_receber));
      setLimiteDispClienteContasRec(parseFloat(ret.limite_disponivel));
    } else {
      setClienteTemLimiteContasRec(false);
      setLimiteClienteContasRec(0);
      setLimiteDispClienteContasRec(0);
    }
  };

  const limparRecebimento = () => {
    setVlrDinheiro(0);
    setVlrCartao(0);
    setVlrCheque(0);
    setVlrCrediario(0);
    setVlrBancario(0);
    setVlrRotativo(0);
    setVlrCredCliente(0);
    setObsCredCliente("");
  };

  const preencAuto = (funcao) => {
    limparRecebimento();
    funcao(parseFloat(vlrAPagar));
  };

  useEffect(() => {
    const totalPago =
      parseFloat(vlrDinheiro) +
      parseFloat(vlrCartao) +
      parseFloat(vlrCheque) +
      parseFloat(vlrCrediario) +
      parseFloat(vlrBancario) +
      parseFloat(vlrRotativo) +
      parseFloat(vlrCredCliente);

    const troco = totalPago > vlrAPagar ? totalPago - vlrAPagar : 0;
    const vlrLanc = totalPago < vlrAPagar ? vlrAPagar - totalPago : 0;

    setVlrTotalPago(roundFloat(totalPago, 2));
    setVlrLancar(roundFloat(vlrLanc, 2));
    setVlrTroco(roundFloat(troco, 2));
  }, [
    vlrDinheiro,
    vlrCartao,
    vlrCheque,
    vlrCrediario,
    vlrBancario,
    vlrRotativo,
    vlrCredCliente,
    vlrAPagar,
  ]);

  const verificarSaldoRotativo = async () => {
    // Se vai abrir a tela de rotativo, deve primeiro verificar se o
    // cliente tem conta no rotativo e se tem saldo suficiente.
    const params = urlParams({ valor: vlrRotativo });
    const [ok, ret] = await apiGetV2(
      `/cred_rotativo/cli_tem_conta_saldo/${idCliente}/${params}`,
      {}
    );
    if (ok) {
      setVlrSaldoDevRotativo(parseFloat(ret.saldo_devedor));
    } else {
      return false;
    }

    return true;
  };

  const setVlrObservCredCliente = (valor, obs) => {
    setVlrCredCliente(valor);
    setObsCredCliente(obs);
  };

  const checkMoeda = (moeda) =>
    recebimento.moedas.some((e) => e.moeda === moeda);

  const toggleCartao = () => {
    setCartaoOpen(!cartaoOpen);
  };

  const toggleCartaoMultiplo = () => {
    setCartaoMultiploOpen(!cartaoMultiploOpen);
  };

  const toggleCheque = () => {
    setChequeOpen(!chequeOpen);
  };

  const toggleCrediario = () => {
    setCrediarioOpen(!crediarioOpen);
  };

  const toggleBancario = () => {
    setBancarioOpen(!bancarioOpen);
  };

  const toggleRotativo = async (verificarSaldo) => {
    if (verificarSaldo) {
      if (!(await verificarSaldoRotativo())) {
        return;
      }
    }
    setRotativoOpen(!rotativoOpen);
  };

  const toggleUsarCredCliente = () => {
    setUsarCredClienteOpen(!usarCredClienteOpen);
  };

  const toggleConfirmarRec = () => {
    setConfirmarRecOpen(!confirmarRecOpen);
  };

  const toggleNext = () => {
    if (vlrDinheiro > 0 && !checkMoeda("DI")) {
      dispatch(addRecebDinheiro(vlrDinheiro));
    } else if (vlrCartao > 0 && !checkMoeda("CC")) {
      if (detalharDadosCartao || temTef) {
        toggleCartao();
      } else {
        dispatch(
          addRecebCartao({
            valor: vlrCartao,
            dados_rec: [
              {
                id_meio_pagto: 0, // O meio de pagamento não é informado
                id_adm: idBandeiraPadraoCartao,
                tipo: "C",
                qtd_parcelas: 1,
                valor: vlrCartao,
              },
            ],
          })
        );
      }
    } else if (vlrCheque > 0 && !checkMoeda("CQ")) {
      toggleCheque();
    } else if (vlrCrediario > 0 && !checkMoeda("CI")) {
      toggleCrediario();
    } else if (vlrBancario > 0 && !checkMoeda("TB")) {
      toggleBancario();
    } else if (vlrRotativo > 0 && !checkMoeda("CR")) {
      toggleRotativo(true);
    } else if (vlrCredCliente > 0 && !checkMoeda("CL")) {
      dispatch(
        addRecebCreditoCliente({
          valor: vlrCredCliente,
          dados_rec: { id_cliente: idCliente, observ: obsCredCliente },
        })
      );
    } else {
      toggleConfirmarRec();
    }
  };

  useEffect(() => {
    if (recebendo) {
      toggleNext();
    }
  }, [recebendo, recebimento.moedas]);

  const handleConfirmar = async () => {
    if (
      vlrDinheiro === 0 &&
      vlrCartao === 0 &&
      vlrCheque === 0 &&
      vlrCrediario === 0 &&
      vlrBancario === 0 &&
      vlrRotativo === 0 &&
      vlrCredCliente === 0
    ) {
      toastr.warning("Atenção", "Por favor, informe um valor em alguma moeda.");
      return false;
    }

    if (vlrTotalPago < vlrAPagar) {
      toastr.warning(
        "Atenção",
        "O Valor Pago deve ser igual ao Total a Pagar."
      );
      return false;
    }

    if (vlrRotativo > 0) {
      if (!(await verificarSaldoRotativo())) {
        return false;
      }
    }

    dispatch(iniciarNovoRecebimento(idMovCaixaLoja));
    if (vlrTroco > 0) {
      dispatch(setValorTrocoDinheiro(vlrTroco));
    } else if (vlrCredTroco > 0) {
      dispatch(setValorTrocoCredito(vlrCredTroco));
    }
    setRecebendo(true);
  };

  const onKeyDown = (e) => {
    if (e.key === "F9") {
      handleConfirmar();
    }
  };

  const carregarDados = async () => {
    if (!location.state.id) {
      toastr.error(
        "Erro!",
        "Não foi possível identificar o movimento a ser recebido"
      );
      history.goBack();
    }

    setIdMovCaixaLoja(location.state.id);
    const [ok, ret] = await CaixaLojaService.buscarDadosMovReceber(
      location.state.id
    );

    if (ok) {
      setVlrAPagar(parseFloat(ret.valor_mov));
      setIdCliente(ret.id_cli);
      setNomeCliente(ret.nome_cli);
      setVlrSaldoCredCliente(parseFloat(ret.saldo_cred_cli));
      setOrigem(ret.origem);
      setIdOrigem(ret.id_origem);
      setAdiantamento(ret.adiantamento);
      setFormaPagAdiantNegoc(ret.forma_pag_adiant_negoc);
      setNomeCondPagAdiantNegoc(ret.nome_cond_pag_adiant_negoc);
      setValorAdiantNegoc(ret.valor_adiant_negoc);
      setTemTef(ret.tem_tef);
      setPixTefHabilitado(ret.pix_tef_habilitado);
      setIdContaBancPix(ret.id_conta_banc_pix);
      setDetalharDadosCartao(ret.detalhar_cartao);
      setIdBandeiraPadraoCartao(ret.id_bandeira_cartao_pad);
    } else {
      history.goBack();
    }
  };

  //Ao carregar a tela, se houver um idCliente com valor atribuído nos dados, executa a função, se não atribui 0 e false
  useEffect(() => {
    if (idCliente) {
      buscarLimiteContasReceberCliente(idCliente);
    } else {
      setClienteTemLimiteContasRec(false);
      setLimiteClienteContasRec(0);
      setLimiteDispClienteContasRec(0);
    }
  }, [idCliente]);

  useEffect(() => {
    carregarDados();
  }, []);

  useImperativeHandle(ref, () => ({
    onKeyDown: onKeyDown,
  }));

  if (redirectBack) {
    return <Redirect to={caixaLojaRoute.path} />;
  }

  return (
    <>
      <Card body>
        <Row>
          <FixedField label="Número" value={idMovCaixaLoja} horizontal />
          {idCliente > 0 && (
            <FixedField
              label="Cliente"
              value={`${nomeCliente} (${idCliente})`}
              horizontal
            />
          )}
        </Row>
      </Card>
      <Card body>
        <Row>
          <Col md={1}></Col>
          <Col md={6} className="pl-0">
            <LinhaMoeda
              label="Dinheiro"
              description="Venda à Vista"
              value={vlrDinheiro}
              onChange={(v) => setVlrDinheiro(parseFloat(v))}
              color="green"
              autoFocus
              sideAction={() => preencAuto(setVlrDinheiro)}
            />
            <LinhaMoeda
              label="Cartão"
              description="Débito ou Crédito"
              value={vlrCartao}
              onChange={setVlrCartao}
              color="blue"
              sideAction={() => preencAuto(setVlrCartao)}
            />
            <LinhaMoeda
              label="Cheque"
              description="Pré Datado ou À Vista"
              value={vlrCheque}
              onChange={setVlrCheque}
              color="maroon"
              sideAction={() => preencAuto(setVlrCheque)}
            />
            <LinhaMoeda
              label="Crediário"
              description={
                <>
                  Venda a Prazo, Boleto
                  <br />
                  {clienteTemLimiteContasRec === true && (
                    <Row>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <FixedField
                          horizontal
                          label="Limite Cliente"
                          style={{}}
                          value={formatNumber(limiteClienteContasRec, false, 2)}
                        />
                        <FixedField
                          horizontal
                          label="Limite Disponível"
                          value={formatNumber(
                            limiteDispClienteContasRec,
                            false,
                            2
                          )}
                          textStyle={
                            limiteDispClienteContasRec < 0 ||
                            limiteDispClienteContasRec < vlrAPagar
                              ? { color: "red" }
                              : {}
                          }
                        />
                      </div>
                    </Row>
                  )}
                </>
              }
              value={vlrCrediario}
              onChange={setVlrCrediario}
              color="purple"
              sideAction={() => preencAuto(setVlrCrediario)}
            />

            <LinhaMoeda
              label="Bancário"
              description="PIX, TED, DOC, Depósito Bancário"
              value={vlrBancario}
              onChange={setVlrBancario}
              color="orange"
              sideAction={() => preencAuto(setVlrBancario)}
            />
            <LinhaMoeda
              label="Crédito Rotativo"
              description="Venda a Crédito"
              value={vlrRotativo}
              onChange={setVlrRotativo}
              color="black"
              sideAction={() => preencAuto(setVlrRotativo)}
              disabled={origem === "FRO"}
              noSideAction={origem === "FRO"}
            />
            <LinhaMoeda
              label="Crédito Cliente"
              description="Pressione F10 para utilizar Crédito"
              value={vlrCredCliente}
              color="teal"
              sideAction={toggleUsarCredCliente}
              sideIcon={BsPersonGear}
              disabled
            />
            {vlrSaldoCredCliente > 0 && (
              <Row>
                <Col md={5}></Col>
                <Col md={6}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      backgroundColor: "teal",
                      borderRadius: "0.5rem",
                    }}
                  >
                    <label
                      className="mb-0"
                      style={{
                        width: "max-content",
                        marginInline: "auto",
                        color: "white",
                      }}
                    >
                      Cliente Possui Crédito Disponível
                    </label>
                    <label
                      style={{
                        width: "max-content",
                        marginInline: "auto",
                        color: "white",
                        fontSize: "0.9rem",
                        fontWeight: "600",
                      }}
                    >
                      {formatNumber(vlrSaldoCredCliente, true, 2)}
                    </label>
                  </div>
                </Col>
              </Row>
            )}
          </Col>
          <Col md={3}>
            <CardValor
              label="Total a Pagar"
              value={vlrAPagar}
              color="#0080FF"
            />
            <CardValor
              label="Valor a Lançar"
              value={vlrLancar}
              color="#00468b"
            />
            <CardValor
              label="Troco"
              value={vlrTroco}
              color="#8ac5ff"
              textColor="black"
            />
            <CardValor
              label="Crédito do Troco"
              value={vlrCredTroco}
              color="#e9e9e9"
              textColor="black"
            />
            <CardValor
              label="Total Pago"
              value={vlrTotalPago}
              color="#0080FF"
            />
            <Row className="mt-5">
              <FormButton
                divClassName="ml-auto pr-0"
                md="auto"
                padded={false}
                color="success"
                style={{
                  paddingBlock: "0.5rem",
                  fontWeight: 600,
                  fontSize: "1rem",
                }}
                onClick={handleConfirmar}
              >
                <BsCurrencyDollar
                  size={20}
                  color="white"
                  style={{ marginTop: "-5px", marginRight: "5px" }}
                />
                F9 - Confirmar
              </FormButton>
            </Row>
            <Col md={2}></Col>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Row>
              <AplicarDescontoModal
                idMovCaixaLoja={idMovCaixaLoja}
                origem={origem}
                idOrigem={idOrigem}
                notifyEvent={carregarDados}
              />
              <LabelButton onClick={limparRecebimento}>Limpar</LabelButton>
            </Row>
          </Col>
          {adiantamento && formaPagAdiantNegoc && (
            <Col md={6}>
              Forma de pagamento negociada - {formaPagAdiantNegoc}
              {formaPagAdiantNegoc !== "Dinheiro"
                ? ` - ${nomeCondPagAdiantNegoc}`
                : ""}
            </Col>
          )}
        </Row>
      </Card>
      <RecCartaoModal
        vlrCartao={vlrCartao}
        isOpen={cartaoOpen}
        toggle={toggleCartao}
        toggleMultiplos={toggleCartaoMultiplo}
      />
      <RecCartaoMultiplosCartoesModal
        vlrCartao={vlrCartao}
        isOpen={cartaoMultiploOpen}
        toggle={toggleCartaoMultiplo}
      />
      <RecCrediarioModal
        vlrCrediario={vlrCrediario}
        isOpen={crediarioOpen}
        toggle={toggleCrediario}
        idMovCaixaLoja={idMovCaixaLoja}
      />
      <RecChequeModal
        idCliente={idCliente}
        vlrCheque={vlrCheque}
        isOpen={chequeOpen}
        toggle={toggleCheque}
      />
      <RecTransfBancModal
        vlrTransfBanc={vlrBancario}
        isOpen={bancarioOpen}
        toggle={toggleBancario}
        pixTefHabilitado={pixTefHabilitado && temTef}
        idContaBancPix={idContaBancPix}
      />
      <RecCreditoRotativoModal
        idMovCaixaLoja={idMovCaixaLoja}
        vlrCreditoRotativo={vlrRotativo}
        vlrSaldoDevRotativo={vlrSaldoDevRotativo}
        isOpen={rotativoOpen}
        toggle={toggleRotativo}
      />
      <UsarCredClienteModal
        nomeCliente={formatValueFromAPI(nomeCliente, idCliente)}
        vlrSaldoCredCliente={vlrSaldoCredCliente}
        setVlrObservCredCliente={setVlrObservCredCliente}
        isOpen={usarCredClienteOpen}
        toggle={toggleUsarCredCliente}
      />
      <ConfirmarRecModal
        isOpen={confirmarRecOpen}
        toggle={toggleConfirmarRec}
        notifyEvent={(emiteNFeAgora, emiteAuto, modeloNFe, fretePorConta) => {
          if (emiteNFeAgora) {
            faturarNfModalRef.current.toggle(
              emiteAuto,
              modeloNFe,
              fretePorConta
            );
          } else {
            setRedirectBack(true);
          }
        }}
      />
      <FaturarNfModal
        notifyEvent={() => setRedirectBack(true)}
        idMovCxaLoja={idMovCaixaLoja}
        ref={faturarNfModalRef}
      />
    </>
  );
});

export const CaixaLojaReceber = ({ location }) => {
  const refContainer = useRef();
  return (
    <PageContainer
      title="Recebimento do Caixa Loja"
      number="0063_1"
      canGoBack
      onKeyDown={(e) => {
        if (refContainer.current) {
          refContainer.current.onKeyDown(e);
        }
      }}
    >
      <Provider store={store}>
        <CaixaLojaReceberContainer location={location} ref={refContainer} />
      </Provider>
    </PageContainer>
  );
};
