import React from "react";
import {
  ModalDetalhesIcone,
  Table,
  TableCheck,
  TableDelete,
} from "../../../../../components";
import { formatValueFromAPI } from "../../../../../coreUtils";

export const SolicitacaoRecargaGrid = ({
  dados,
  onSelect,
  mostrarDetalhes,
  cancelar,
}) => {
  const columns = [
    { dataField: "id", text: "#", align: "center" },
    {
      dataField: "nome_rota_mob",
      text: "Rota",
      align: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_rota_mob),
    },
    {
      dataField: "data_hora_solicitacao",
      text: "Solicitação",
      align: "center",
    },
    {
      dataField: "expedido_jorn",
      text: "Expedido",
      align: "center",
      formatter: (c) => <TableCheck value={c} />,
    },
    { dataField: "data_hora_expedido", text: "Expedição", align: "center" },
    {
      dataField: "det",
      text: "",
      align: "center",
      dummy: true,
      selectOnClick: false,
      formatter: (c, row) => (
        <ModalDetalhesIcone toggle={() => mostrarDetalhes(row.id)} />
      ),
    },
    {
      dataField: "del",
      text: "",
      align: "center",
      dummy: true,
      selectOnClick: false,
      formatter: (c, row) => (
        <TableDelete
          onClick={() => cancelar(row.id)}
          disabled={row.expedido_jorn}
          disabledHint="A Solicitação já foi expedida"
        />
      ),
    },
  ];

  return <Table data={dados} columns={columns} onSelect={onSelect} />;
};
