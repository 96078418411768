import React from "react";
import {
  formatNumber,
  formatValueFromAPI,
  naturalSort,
  naturalSortDate,
} from "../../../../../coreUtils";
import { MoreDropdown } from "../../../../../components/MoreDropdown";
import { DropdownItem } from "reactstrap";
import { ColorField } from "../../../../../components/ColorField";
import { IconButton, Table } from "../../../../../components";
import { BsPrinter } from "react-icons/bs";
import { iconeAlterar } from "../../../../../components/cadastro";

export const coresStatusNFe = [
  { value: "A", color: "#0080FF", hint: "Aguardando Emissão" },
  { value: "E", color: "#00AA00", hint: "Enviada SEFAZ" },
  { value: "C", color: "#FF0D0D", hint: "Cancelada" },
  { value: "U", color: "#FF9664", hint: "Inutilizada" },
  { value: "D", color: "#000000", hint: "Denegada" },
  { value: "I", color: "#FF00FF", hint: "Digitação Incompleta" },
];

export const NotaFiscalGrid = ({
  data,
  selected,
  setSelected,
  onSelectAll,
  downloadXML,
  downloadXmlContingencia,
  imprimir,
  imprimirContingencia,
  ajustarDuplicidade,
  alterarNFe,
  vincularNFProdRural,
  cancelarNFe,
  cartaCorrecaoNFe,
  verDetalhesNFe,
  inutilizarNFe,
  duplicarNFe,
  alertasNFe,
  permiteContingencia,
}) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      headerAlign: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "numero",
      text: "Número",
      align: "center",
      headerAlign: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "serie",
      text: "Série",
      align: "center",
      headerAlign: "center",
      sortable: true,
    },
    {
      dataField: "nome_tipo_doc",
      text: "Tipo de Documento",
      align: "left",
      headerAlign: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_tipo_doc),
      sortable: true,
    },
    {
      dataField: "nome_cliente",
      text: "Cliente",
      align: "left",
      headerAlign: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_cliente),
      sortable: true,
    },
    {
      dataField: "email_cliente",
      text: "E-Mail",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "emissao",
      text: "Emissão",
      align: "center",
      headerAlign: "center",
      sortable: true,
      sortFunc: naturalSortDate,
    },
    {
      dataField: "vlr_tot_nf",
      text: "Valor",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "status",
      text: "Sit",
      align: "center",
      headerAlign: "center",
      formatter: (c) => <ColorField scheme={coresStatusNFe} value={c} />,
      style: { verticalAlign: "middle", padding: 0 },
      colWidth: "35px",
      fixedColWidth: true,
      sortable: true,
      hideSortIcon: true,
    },
    iconeAlterar(alterarNFe, {
      disabled: (c, row) => !["A", "I"].includes(row.status),
      disabledHint:
        "Somente é possível alterar NF's Em Digitação ou Aguardando Emissão",
    }),
    {
      dataField: "print",
      text: "",
      align: "center",
      headerAlign: "center",
      selectOnClick: false,
      fixedColWidth: true,
      colWidth: "40px",
      formatter: (c, row) => (
        <IconButton
          icon={BsPrinter}
          onClick={() => imprimir(row.id)}
          hint="Imprimir NFe"
          tooltipPlacement="left"
        />
      ),
    },
    {
      dataField: "act",
      text: "",
      align: "center",
      headerAlign: "center",
      selectOnClick: false,
      fixedColWidth: true,
      colWidth: "40px",
      formatter: (c, row) => (
        <MoreDropdown horizontal>
          <DropdownItem onClick={() => verDetalhesNFe(row.id)}>
            Detalhes
          </DropdownItem>
          <DropdownItem onClick={() => alertasNFe(row.id)}>
            Alertas
          </DropdownItem>
          {row.status === "E" && (
            <>
              <DropdownItem onClick={() => cartaCorrecaoNFe(row.id)}>
                Carta de Correção
              </DropdownItem>
              <DropdownItem onClick={() => cancelarNFe(row.id)}>
                Cancelar NF-e
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={() => downloadXML(row.id)}>
                Baixar XML
              </DropdownItem>
            </>
          )}
          {row.status === "A" && (
            <>
              <DropdownItem onClick={() => ajustarDuplicidade(row.id)}>
                Ajustar Duplicidade
              </DropdownItem>
              <DropdownItem onClick={() => inutilizarNFe(row.id)}>
                Inutilizar
              </DropdownItem>
            </>
          )}
          <DropdownItem onClick={() => duplicarNFe(row.id)}>
            Duplicar
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={() => vincularNFProdRural(row.id)}>
            Produtor Rural
          </DropdownItem>
          {permiteContingencia === true && row.status === "A" && (
            <>
              <DropdownItem divider />
              <DropdownItem onClick={() => downloadXmlContingencia(row.id)}>
                Baixar XML Contingência
              </DropdownItem>
              <DropdownItem onClick={() => imprimirContingencia(row.id)}>
                Imprimir Contingência
              </DropdownItem>
            </>
          )}
        </MoreDropdown>
      ),
      footer: "",
    },
  ];

  return (
    <>
      <Table
        data={data}
        columns={columns}
        multiselect
        selected={selected}
        onSelect={setSelected}
        onSelectAll={onSelectAll}
        clearSelectionOnUpdate
        onRowDoubleClick={alterarNFe}
      />
    </>
  );
};
