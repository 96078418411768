import React, { useEffect, useState } from "react";
import { Row } from "reactstrap";
import { AsyncComboBox, ModalBase } from "../../../../../components";
import { showWarning } from "../../../../../components/AlertaModal";
import GerenciamentoVendasService from "../../../../../services/vendas/GerenciamentoVendasService";
import { SenhaModal } from "../../../../../components/SenhaModal";

const ExcluirVendaModal = ({ idVenda, notifyEvent, isOpen, toggle }) => {
  const [idMotivo, setIdMotivo] = useState(null);
  const [senhaOpen, setSenhaOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const emailProprietario = localStorage.getItem("email_proprietario");

  const handleSubmit = async (senha) => {
    setLoading(true);
    try {
      if ([null, undefined, 0].includes(idMotivo)) {
        showWarning(
          "Por favor, selecione o motivo para exclusão e tente novamente"
        );
        return;
      }

      const payload = {
        senha: senha,
        id_motivo: idMotivo,
        id_venda: idVenda,
        obs_exclusao: "",
      };
      const [ok] = await GerenciamentoVendasService.excluir(payload);
      if (ok) {
        notifyEvent();
        toggle();
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setSenhaOpen(false);
      setIdMotivo(null);
      setLoading(false);
    }
  }, [isOpen]);

  const toggleSenha = () => setSenhaOpen(!senhaOpen);

  return (
    <>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="sm"
        title="Exclusão de Venda"
        number="0067_1"
        onConfirm={toggleSenha}
        loadingConfirm={loading}
      >
        Ao excluir a venda, o sistema irá:
        <ol>
          <li>Retorna o estoque a venda.</li>
          <li>Exclui o movimento pendente de recebimento no caixa loja.</li>
          <li>Armazena os dados da venda excluída para consulta futura.</li>
          <li>Remove o histórico da venda.</li>
          <li>
            Será enviado um e-mail com as informações da exclusão para{" "}
            <b>{emailProprietario}</b>
          </li>
        </ol>
        <span style={{ paddingLeft: "40px", fontWeight: "bold" }}>
          Se origem for Pedido Externo:
        </span>
        <br />
        <span style={{ paddingLeft: "40px" }}>
          A entrega do pedido será excluída, os registros do romaneio serão
          removidos e o pedido será reaberto
        </span>
        <Row className="mt-2">
          <AsyncComboBox
            label="Motivo para Exclusão"
            md={12}
            isConcatField
            concatModelName="motivo"
            defaultOptions
            isSearchable
            onChange={(s) => setIdMotivo(s?.value)}
            defaultValue={idMotivo}
          />
        </Row>
      </ModalBase>
      <SenhaModal
        isOpen={senhaOpen}
        toggle={toggleSenha}
        title="Exclusão de Venda"
        onConfirm={handleSubmit}
      />
    </>
  );
};

export default ExcluirVendaModal;
