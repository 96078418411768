import React from "react";
import { DataTable } from "../../../../../../components/DataTable";
import { TableCheck } from "../../../../../../components/TableCheck";
import { FINALIDADES_REGRA_TRIB } from "../../../regra_tributacao/components/modal/CamposChaves";

export const RegrasAddGrid = ({ data, selected, onSelect, onSelectAll }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      headerAlign: "center",
      sort: true,
    },
    {
      dataField: "descricao",
      text: "Descrição",
      align: "left",
      headerAlign: "left",
      sort: true,
    },
    {
      dataField: "cfop",
      text: "CFOP",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "dentro_estabelecimento",
      text: "Dentro do Estabelecimento",
      align: "center",
      headerAlign: "center",
      formatter: (cell) => <TableCheck value={cell} />,
      sort: true,
    },
    {
      dataField: "finalidade_oper",
      text: "Finalidade",
      align: "center",
      headerAlign: "center",
      formatter: (cell) =>
        FINALIDADES_REGRA_TRIB.find((e) => e.value === cell)?.label ?? "",
    },
    {
      dataField: "ativo",
      text: "Ativo",
      align: "center",
      headerAlign: "center",
      formatter: (cell) => <TableCheck value={cell} />,
      sort: true,
    },
  ];

  return (
    <DataTable
      data={data}
      columns={columns}
      isMulti
      selected={selected}
      paginated
      sizePerPage={15}
      onSelect={onSelect}
      onSelectAll={onSelectAll}
    />
  );
};
