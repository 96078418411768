import React, { useState } from "react";
import {
  AsyncComboBox,
  ComboBox,
  ConcatShortcut,
  FixedField,
  FormButton,
  HintLabel,
  ModalBase,
  Table,
  TextInput,
} from "../../../../../components";
import { Row, Label } from "reactstrap";
import NotaFiscalServicoService from "../../../../../services/docs_eletron/NotaFiscalServicoService";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";
import GerarNfVendaOsService from "../../../../../services/faturamento/GerarNfVendaOsService";
import { apiGetV2 } from "../../../../../apiV2";
import { useEffect } from "react";
import { toastr } from "react-redux-toastr";

const columns = [
  {
    dataField: "tipo_orig",
    text: "Origem",
    align: "center",
    headerAlign: "center",
    formatter: (c) => (c === "VDA" ? "Venda" : c === "OSV" ? "O.S." : c),
  },
  {
    dataField: "nome_servico",
    text: "Serviço",
    align: "left",
    headerAlign: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_servico),
  },
  {
    dataField: "quantidade",
    text: "Quantidade",
    align: "center",
    headerAlign: "center",
    formatter: (c) => formatNumber(c, true, 2, true),
  },
  {
    dataField: "vlr_item",
    text: "Vlr. Unit.",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_total",
    text: "Total",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

const totaisLabelStyle = { color: "black" };

export const GerarNFServicoModal = ({ selected, notifyEvent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // Parâmetros
  const [unificarTitulosProdServ, setUnificarTitulosProdServ] = useState(false);
  const [natOps, setNatOps] = useState([]);
  const [condPags, setCondPags] = useState([]);
  const [localPrestaServPadrao, setLocalPrestaServPadrao] = useState("");
  // Controle
  const [qtdServs, setQtdServs] = useState(0);
  // Dados NFS
  const [idCliente, setIdCliente] = useState(null);
  const [idServicoFaturar, setIdServicoFaturar] = useState(null);
  const [codSped, setCodSped] = useState(null);
  const [idNaturOperacao, setIdNaturOperacao] = useState(null);
  const [descAutomatica, setDescAutomatica] = useState("");
  const [idCidadePrestaServ, setIdCidadePrestaServ] = useState(null);
  const [descricao, setDescricao] = useState("");
  const [servicos, setServicos] = useState([]);
  const [idCondPagUnifica, setIdCondPagUnifica] = useState(null);
  // Impostos
  const [baseCalcIssqn, setBaseCalcIssqn] = useState(0);
  const [aliqIssqn, setAliqIssqn] = useState(0);
  const [vlrIssqn, setVlrIssqn] = useState(0);
  const [baseCalcInss, setBaseCalcInss] = useState(0);
  const [aliqInss, setAliqInss] = useState(0);
  const [vlrInss, setVlrInss] = useState(0);
  // Valores
  const [vlrTotServ, setVlrTotServ] = useState(0);
  const [vlrTotal, setVlrTotal] = useState(0);

  const limparDados = () => {
    setUnificarTitulosProdServ(false);
    setNatOps([]);
    setCondPags([]);
    setLocalPrestaServPadrao("");
    setQtdServs(0);
    setIdCliente(null);
    setIdServicoFaturar(null);
    setIdNaturOperacao(null);
    setDescAutomatica("");
    setIdCidadePrestaServ(null);
    setDescricao("");
    setServicos([]);
    setIdCondPagUnifica(null);
    setBaseCalcIssqn(0);
    setAliqIssqn(0);
    setVlrIssqn(0);
    setBaseCalcInss(0);
    setAliqInss(0);
    setVlrInss(0);
    setVlrTotServ(0);
    setVlrTotal(0);
  };

  const carregarCondicoesPag = async (idCli) => {
    setCondPags(await GerarNfVendaOsService.listarCondicoesPag(idCli));
  };

  const carregarParametros = async () => {
    const [ok, ret] = await apiGetV2(`/tela/faturamento/`);
    if (ok) {
      setUnificarTitulosProdServ(ret.unificar_titulos_prod_serv);
      return true;
    } else {
      return false;
    }
  };

  const carregarNaturezasOperacao = async () => {
    const [ok, ret] = await NotaFiscalServicoService.buscarNaturezasOperacao();
    if (!ok) {
      return false;
    }

    setNatOps(ret ? ret.map((e) => ({ label: e.descricao, value: e.id })) : []);
    return true;
  };

  const carregarDados = async () => {
    const [ok, ret] = await GerarNfVendaOsService.gerarDadosFaturarNfServico({
      origens: selected,
    });
    if (ok) {
      setIdCliente(ret.id_cliente);
      setIdNaturOperacao(ret.natureza_oper_padrao);
      setLocalPrestaServPadrao(ret.local_presta_serv_padrao);
      setDescAutomatica(ret.descricao_nfse);
      setIdServicoFaturar(ret.id_servico_fat);
      setQtdServs(ret.qtd_servs);

      setBaseCalcIssqn(parseFloat(ret.base_calc_issqn));
      setAliqIssqn(parseFloat(ret.aliq_issqn));
      setVlrIssqn(parseFloat(ret.vlr_issqn));
      setBaseCalcInss(parseFloat(ret.base_calc_inss));
      setAliqInss(parseFloat(ret.aliq_inss));
      setVlrInss(parseFloat(ret.vlr_inss));
      setVlrTotServ(parseFloat(ret.vlr_tot_serv));
      setVlrTotal(parseFloat(ret.vlr_total));
      setServicos(
        ret.itens.map((e) => ({ ...e, vlr_total: parseFloat(e.vlr_total) }))
      );

      return true;
    } else {
      return false;
    }
  };

  const toggle = async () => {
    if (!isOpen) {
      if (!(await carregarParametros())) {
        return false;
      }

      if (!(await carregarNaturezasOperacao())) {
        return false;
      }

      if (!(await carregarDados())) {
        return false;
      }
    }

    setIsOpen(!isOpen);
  };

  const handleSubmit = async () => {
    if (
      localPrestaServPadrao === "SSL" &&
      [0, null, undefined].includes(idCidadePrestaServ)
    ) {
      toastr.warning(
        "Atenção",
        "Por favor, informe a Cidade de Prestação do Serviço."
      );
      return false;
    }
    if (
      unificarTitulosProdServ &&
      [0, null, undefined].includes(idCondPagUnifica)
    ) {
      toastr.warning(
        "Atenção",
        "Por favor, informe a condição de pagamento para unificação de títulos."
      );
      return false;
    }

    const payload = {
      origens: selected,
      id_cliente: idCliente,
      id_forma_tribut: idNaturOperacao,
      id_servico_fat: idServicoFaturar,
      desc_adic_serv: descricao,
      vlr_tot_serv: vlrTotServ,
      base_calc_issqn: baseCalcIssqn,
      aliq_issqn: aliqIssqn,
      vlr_issqn: vlrIssqn,
      base_calc_inss: baseCalcInss,
      aliq_inss: aliqInss,
      vlr_inss: vlrInss,
      vlr_total: vlrTotal,
      id_cond_pag_unifica: unificarTitulosProdServ ? idCondPagUnifica : null,
      id_cidade_local_presta_serv:
        localPrestaServPadrao === "SSL" ? idCidadePrestaServ : null,
      itens: servicos.map((e) => ({
        descricao: e.nome_servico,
        vlr_total: e.vlr_total,
      })),
    };
    setLoading(true);
    const [ok] = await GerarNfVendaOsService.faturarNFSe(payload);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  useEffect(() => {
    if (![0, null, undefined].includes(idCliente)) {
      carregarCondicoesPag(idCliente);
    }
  }, [idCliente]);

  return (
    <>
      <FormButton
        onClick={toggle}
        md="auto"
        color="success"
        disabled={selected?.length === 0}
      >
        Gerar NF Serviço
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title="Gerar Nota Fiscal de Serviço"
        number="0036_2"
        onConfirm={handleSubmit}
        loadingConfirm={loading}
        onClosed={limparDados}
      >
        <Row>
          <AsyncComboBox
            md={10}
            label="Cliente"
            isSearchable
            isClearable
            isConcatField
            concatModelName="cliente"
            onChange={(s) => setIdCliente(s?.value ?? null)}
            defaultValue={idCliente}
          />
        </Row>
        <Row>
          <ComboBox
            md={10}
            label="Natureza de Operação"
            options={natOps}
            defaultValue={idNaturOperacao}
            onChange={(s) => setIdNaturOperacao(s?.value)}
            defaultOptions
          />
        </Row>
        <Row>
          <AsyncComboBox
            md={10}
            label="Serviço para Faturamento"
            isSearchable
            isClearable
            isConcatField
            concatModelName="servico"
            onChange={(s) => {
              setIdServicoFaturar(s?.value ?? null);
              setCodSped(s?.cod_sped ?? null);
            }}
            defaultValue={idServicoFaturar}
            disabled={qtdServs === 1}
            defaultOptions
          />
          <FixedField label="SPED" value={codSped} />
        </Row>
        <Row>
          {localPrestaServPadrao === "SSL" ? (
            <AsyncComboBox
              md={6}
              label="Cidade de Prestação do Serviço"
              isConcatField
              concatModelName="cidade"
              isSearchable
              onChange={(s) => setIdCidadePrestaServ(s?.value ?? 0)}
              defaultValue={idCidadePrestaServ}
            />
          ) : (
            <FixedField
              label="Local de Prestação de Serviço"
              value={
                localPrestaServPadrao === "EMI"
                  ? "No Emitente"
                  : localPrestaServPadrao === "TOM"
                  ? "No Destinatário"
                  : localPrestaServPadrao
              }
              horizontal
              divClassName="my-2"
            />
          )}
        </Row>

        <Row>
          <TextInput
            type="textarea"
            md={12}
            label="Descrição Automática"
            value={descAutomatica}
            disabled
            rows={3}
          />
        </Row>
        <Row className="mb-2">
          <TextInput
            md={12}
            label="Descrição Adicional"
            value={descricao}
            onChange={(e, v) => setDescricao(v)}
          />
        </Row>
        {unificarTitulosProdServ && (
          <>
            <Row>
              <Label md="auto">
                Seu sistema está configurado para agrupar as cobranças no
                faturamento, e a cobrança referente a esta nota será gerada em
                Contas a Receber. Caso haja alguma nota fiscal de serviço gerada
                anteriormente, as cobranças serão agrupadas.
              </Label>
            </Row>
            <Row className="mb-2">
              <ComboBox
                label={
                  <HintLabel
                    label="Condição de Pagamento"
                    hintText={
                      "As Condições de Pagamento listadas abaixo são " +
                      "atribuídas em Pronta Entrega > Cadastro > " +
                      "Formas de Recebimento."
                    }
                  />
                }
                md={8}
                options={condPags}
                isSearchable
                onChange={(s) => setIdCondPagUnifica(s?.value)}
                defaultValue={idCondPagUnifica}
                additionalButton={
                  <ConcatShortcut
                    concatModelName="cond_pag"
                    selected={idCondPagUnifica}
                    setSelected={setIdCondPagUnifica}
                  />
                }
              />
            </Row>
          </>
        )}
        <Row className="mb-2">
          <FixedField
            md={3}
            label="Base Cálc. ISSQN"
            value={formatNumber(baseCalcIssqn, true, 2)}
            labelStyle={totaisLabelStyle}
          />
          <FixedField
            md={3}
            label="Aliq. ISSQN"
            value={formatNumber(aliqIssqn, true, 2, true) + "%"}
            labelStyle={totaisLabelStyle}
          />
          <FixedField
            md={3}
            label="Valor ISSQN"
            value={formatNumber(vlrIssqn, true, 2)}
            labelStyle={totaisLabelStyle}
          />
          <FixedField
            md={3}
            label="Total Serviços"
            value={formatNumber(vlrTotServ, true, 2)}
            labelStyle={totaisLabelStyle}
          />
        </Row>
        <Row className="mb-2">
          <FixedField
            md={3}
            label="Base Cálc. INSS"
            value={formatNumber(baseCalcInss, true, 2)}
            labelStyle={totaisLabelStyle}
          />
          <FixedField
            md={3}
            label="Aliq. INSS"
            value={formatNumber(aliqInss, true, 2, true) + "%"}
            labelStyle={totaisLabelStyle}
          />
          <FixedField
            md={3}
            label="Valor INSS"
            value={formatNumber(vlrInss, true, 2)}
            labelStyle={totaisLabelStyle}
          />
          <FixedField
            md={3}
            label="Total NF"
            value={formatNumber(vlrTotal, true, 2)}
            labelStyle={totaisLabelStyle}
          />
        </Row>
        <Table
          data={servicos}
          columns={columns}
          paginated={false}
          pageSize={5}
        />
      </ModalBase>
    </>
  );
};
