import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import AsyncSelect from "react-select/async";
import { Col, FormGroup } from "reactstrap";
import { MODAL_ACTIONS, handleFocus } from "../coreUtils";
import ComboBoxService from "../services/components/ComboBoxService";
import { ConcatShortcut } from "./ConcatShortcut";
import { CadastroCidadeModal } from "../pages/cadastro/clientes/cidade/components/CadastroCidadeModal";
import { CadastroGrupoModal } from "../pages/cadastro/produtos/grupo/components/CadastroGrupoModal";
import { CadastroFabricanteModal } from "../pages/cadastro/produtos/fabricante/components/CadastroFabricanteModal";
import { CadastroMontadoraModal } from "../pages/cadastro/servicos/montadora/components/CadastroMontadoraModal";
import { CadastroRamoAtividadeModal } from "../pages/cadastro/clientes/ramo_atividade/components/CadastroRamoAtividadeModal";
import { HintLabel } from "./HintLabel";
import { CadastroTomadorModal } from "../pages/docs_eletron/transporte/manifesto_mdfe/cadastro/tomador/components/CadastroTomadorModal";
import { CadastroCondutorModal } from "../pages/docs_eletron/transporte/manifesto_mdfe/cadastro/condutor/components/CadastroCondutorModal";
import { CadastroPercursoModal } from "../pages/docs_eletron/transporte/manifesto_mdfe/cadastro/percurso/components/CadastroPercursoModal";
import { CadastroServicoModal } from "../pages/cadastro/servicos/servico/components/CadastroServicoModal";
import { CadastroTipoDocumentoModal } from "../pages/cadastro/tributacao/tipo_documento/components/CadastroTipoDocumentoModal";
import { CadastroVeicClienteModal } from "../pages/cadastro/servicos/veic_cliente/components/CadastroVeicClienteModal";
import { CadastroSubGrupoModal } from "../pages/cadastro/produtos/sub_grupo/components/CadastroSubGrupoModal";
import { CadastroBancoModal } from "../pages/cadastro/financeiro/banco/components/CadastroBancoModal";

const AsyncComboBox = forwardRef(
  (
    {
      loadOptions,
      onChange,
      isClearable = true,
      isMulti = false,
      isSearchable = true,
      hideShortcut = false,
      md,
      label,
      placeholder = "Selecione...",
      name,
      className,
      defaultOptions,
      isConcatField = true,
      concatModelName,
      defaultValue,
      options,
      disabled,
      clearOnDisable,
      autoFocus,
      tabOrder,
      onBlur,
      style,
      id,
      additionalButton,
      outraEmpresa,
      required,
      menuPlacement,
      openMenuOnClick,
      openMenuOnFocus,
      components,
    },
    ref
  ) => {
    const [selectedOption, setSelectOption] = useState({});
    const selectRef = useRef();
    const [loadedOptions, setLoadedOptions] = useState(false);
    const [internalId] = useState(id ?? Math.floor(Math.random() * Date.now()));
    const [cadastroOpen, setCadastroOpen] = useState(false);
    const [cadastroAction, setCadastroAction] = useState(MODAL_ACTIONS.ADD);
    const shortcutDivRef = useRef();

    const getFunction = outraEmpresa
      ? ComboBoxService.fetchOptionsOutraEmpresa
      : ComboBoxService.fetchOptions;

    const fetchOptions = async (inputValue, searchById = false) => {
      let _result = [];
      if (isConcatField && typeof concatModelName == "string") {
        const filters = {};
        if (inputValue !== "") {
          if (searchById === true) {
            if (!isNaN(inputValue)) {
              filters["pk"] = inputValue;
            }
          } else {
            if (["produto", "fornecedor"].includes(concatModelName)) {
              filters["pista"] = inputValue;
            } else {
              if (!isNaN(inputValue)) {
                if (concatModelName === "cliente") {
                  filters["pk"] = inputValue;
                } else if (concatModelName === "cfop") {
                  filters["codigo"] = inputValue;
                } else {
                  filters["id"] = inputValue;
                }
              } else {
                let nomeCampo = "nome";
                if (concatModelName === "conta_banc") {
                  nomeCampo = "banco__nome";
                } else if (
                  [
                    "centro_custo",
                    "plano_conta",
                    "tipo_cliente",
                    "plano_contratado",
                    "regra_trib",
                    "grupo_trib",
                    "cond_pag",
                    "cfop",
                    "ramo_atividade",
                    "percurso_mdfe",
                    "motivo",
                    "terceirizacao",
                    "classe_prod",
                    "perfil",
                  ].includes(concatModelName)
                ) {
                  nomeCampo = "descricao";
                } else if (concatModelName === "produto_intermediario") {
                  nomeCampo = "nome_produto";
                } else if (concatModelName === "layout_impressoras_app") {
                  nomeCampo = "modelo_impressora";
                }
                if (concatModelName === "cliente") {
                  filters[nomeCampo] = inputValue;
                } else {
                  filters[`${nomeCampo}__icontains`] = inputValue;
                }
              }
            }
          }
        }
        const ret = await getFunction(concatModelName, filters);
        setLoadedOptions(true);
        _result = ret;
      } else {
        _result = options;
      }
      return _result;
    };

    const clearValue = () => {
      if (!selectRef?.current) return;
      selectRef.current.select.select.clearValue();
    };

    const setValue = (value) => {
      if (!selectRef?.current) return;
      selectRef.current.select.select.setValue(value);
    };

    const setValueByID = useCallback(async (id) => {
      let i = 0;
      let checked = false;
      while (!checked && i < 10) {
        const options = getOptions();
        if (options.length > 0) {
          setValue(options.find((v) => v.value === id));
          checked = true;
        }
        i++;
        await new Promise((r) => setTimeout(r, 75));
      }
    }, []);

    const getOptions = () => {
      if (!selectRef?.current) return [];
      return selectRef.current.select.select.props.options;
    };

    const setFocus = () => {
      if (!selectRef?.current) return;
      selectRef.current.select.select.focus();
    };

    useImperativeHandle(ref, () => ({
      clearValue: () => clearValue(),
      setValue: (v) => setValue(v),
      getOptions: () => getOptions(),
      setValueByID: (id) => setValueByID(id),
      getValue: () => selectedOption,
      setFocus: () => setFocus(),
      fetchOptions: () => fetchOptions("", false),
    }));

    const __onChange = (selected, target) => {
      setSelectOption(selected);
      if (onChange) {
        onChange(selected, target);
      }
    };

    // Quando o defaultValue é zero, não é atribuida a opção no Select, pois zero é falsy
    // Esta função abre uma exceção quando o concatModelName for colaborador
    const checkDefaultValue = (v) => {
      if (concatModelName === "colaborador") {
        return v === 0 ? true : v;
      } else {
        return v;
      }
    };

    useEffect(() => {
      const setarDefaultValue = async () => {
        if (
          checkDefaultValue(defaultValue) &&
          (loadedOptions || typeof defaultOptions !== Array) &&
          defaultOptions
        ) {
          new Promise(() => setTimeout(setValueByID(defaultValue), 1000));
        } else if (checkDefaultValue(defaultValue) && !defaultOptions) {
          const ret = await fetchOptions(defaultValue, true);
          if (ret.length > 0) {
            setValue(ret[0]);
          }
        }
      };
      setarDefaultValue();
    }, [setValueByID, defaultValue, loadedOptions]);

    useEffect(() => {
      if (disabled && clearOnDisable) {
        clearValue();
      }
    }, [disabled, clearOnDisable]);

    const setSelectedByShortcut = async (s) => {
      const ids = getOptions().map((e) => e.value);
      const selIndex = ids.indexOf(s);

      const newValues = await fetchOptions(s);
      if (newValues?.length > 0) {
        if (selIndex >= 0) {
          selectRef.current.select.select.props.options[selIndex] =
            newValues[0];
        } else {
          selectRef.current.select.select.props.options.push(newValues[0]);
        }
        setValueByID(s);
      }
    };

    const onKeyDownInternal = (e) => {
      if (e.key === "Enter" && isMulti) return;
      if (e.key === "F4" && shortcutDivRef.current) {
        if (shortcutDivRef.current.children.length > 0) {
          shortcutDivRef.current.children[0].click();
        }
      }

      if (e.key === "ArrowUp" && selectRef.current.select.state.menuIsOpen)
        return;

      handleFocus(e);
    };

    const toggleCadastro = (action) => {
      setCadastroAction(action);
      setTimeout(() => {
        setCadastroOpen(!cadastroOpen);
      }, 1);
    };

    return (
      <Col md={md} className={className} style={style}>
        <FormGroup onKeyDown={onKeyDownInternal}>
          {label && (
            <HintLabel
              for={`react-select-${internalId}-input`}
              label={label}
              required={required}
            />
          )}
          <div style={{ display: "flex", width: "100%" }}>
            <AsyncSelect
              placeholder={placeholder}
              className="react-select-container"
              classNamePrefix="react-select"
              loadOptions={isConcatField ? fetchOptions : loadOptions}
              name={name}
              isSearchable={isSearchable}
              isClearable={isClearable}
              isMulti={isMulti}
              defaultOptions={defaultOptions}
              onChange={__onChange}
              ref={selectRef}
              isDisabled={disabled}
              autoFocus={autoFocus}
              noOptionsMessage={() =>
                isConcatField ? "Digite Algo" : "Sem Dados"
              }
              loadingMessage={() => "Carregando..."}
              isLoading={
                typeof defaultOptions === Boolean &&
                defaultOptions &&
                !loadedOptions
              }
              tabOrder={tabOrder}
              onBlur={onBlur}
              instanceId={internalId}
              menuPlacement={menuPlacement}
              openMenuOnClick={openMenuOnClick}
              openMenuOnFocus={openMenuOnFocus}
              components={components}
              onClick={(e) => console.log(e)} // não está capturando o clique
            />
            {!hideShortcut && isConcatField && !disabled && (
              <div style={{ display: "flex" }} ref={shortcutDivRef}>
                {concatModelName === "cidade" ? (
                  <CadastroCidadeModal
                    isOpen={cadastroOpen}
                    toggle={toggleCadastro}
                    action={cadastroAction}
                    selected={selectedOption?.value}
                    notifyEvent={setSelectedByShortcut}
                    concatShortcut
                  />
                ) : concatModelName === "banco" ? (
                  <CadastroBancoModal
                    isOpen={cadastroOpen}
                    toggle={toggleCadastro}
                    action={cadastroAction}
                    selected={selectedOption?.value}
                    notifyEvent={setSelectedByShortcut}
                    concatShortcut
                  />
                ) : concatModelName === "grupo" ? (
                  <CadastroGrupoModal
                    isOpen={cadastroOpen}
                    toggle={toggleCadastro}
                    action={cadastroAction}
                    selected={selectedOption?.value}
                    notifyEvent={setSelectedByShortcut}
                    concatShortcut
                  />
                ) : concatModelName === "fabricante" ? (
                  <CadastroFabricanteModal
                    isOpen={cadastroOpen}
                    toggle={toggleCadastro}
                    action={cadastroAction}
                    selected={selectedOption?.value}
                    notifyEvent={setSelectedByShortcut}
                    concatShortcut
                  />
                ) : concatModelName === "montadora" ? (
                  <CadastroMontadoraModal
                    isOpen={cadastroOpen}
                    toggle={toggleCadastro}
                    action={cadastroAction}
                    selected={selectedOption?.value}
                    notifyEvent={setSelectedByShortcut}
                    concatShortcut
                  />
                ) : concatModelName === "ramo_atividade" ? (
                  <CadastroRamoAtividadeModal
                    isOpen={cadastroOpen}
                    toggle={toggleCadastro}
                    action={cadastroAction}
                    selected={selectedOption?.value}
                    notifyEvent={setSelectedByShortcut}
                    concatShortcut
                  />
                ) : concatModelName === "tomador_mdfe" ? (
                  <CadastroTomadorModal
                    isOpen={cadastroOpen}
                    toggle={toggleCadastro}
                    action={cadastroAction}
                    selected={selectedOption?.value}
                    notifyEvent={setSelectedByShortcut}
                    concatShortcut
                  />
                ) : concatModelName === "condutor_mdfe" ? (
                  <CadastroCondutorModal
                    isOpen={cadastroOpen}
                    toggle={toggleCadastro}
                    action={cadastroAction}
                    selected={selectedOption?.value}
                    notifyEvent={setSelectedByShortcut}
                    concatShortcut
                  />
                ) : concatModelName === "percurso_mdfe" ? (
                  <CadastroPercursoModal
                    isOpen={cadastroOpen}
                    toggle={toggleCadastro}
                    action={cadastroAction}
                    selected={selectedOption?.value}
                    notifyEvent={setSelectedByShortcut}
                    concatShortcut
                  />
                ) : concatModelName === "servico" ? (
                  <CadastroServicoModal
                    isOpen={cadastroOpen}
                    toggle={toggleCadastro}
                    action={cadastroAction}
                    selected={selectedOption?.value}
                    notifyEvent={setSelectedByShortcut}
                    concatShortcut
                  />
                ) : concatModelName === "tipo_documento" ? (
                  <CadastroTipoDocumentoModal
                    isOpen={cadastroOpen}
                    toggle={toggleCadastro}
                    action={cadastroAction}
                    selected={selectedOption?.value}
                    notifyEvent={setSelectedByShortcut}
                    concatShortcut
                  />
                ) : concatModelName === "veiculo" ? (
                  <CadastroVeicClienteModal
                    isOpen={cadastroOpen}
                    toggle={toggleCadastro}
                    action={cadastroAction}
                    selected={selectedOption?.value}
                    notifyEvent={setSelectedByShortcut}
                    concatShortcut
                  />
                ) : concatModelName === "sub_grupo" ? (
                  <CadastroSubGrupoModal
                    isOpen={cadastroOpen}
                    toggle={toggleCadastro}
                    action={cadastroAction}
                    selected={selectedOption?.value}
                    notifyEvent={setSelectedByShortcut}
                    concatShortcut
                  />
                ) : (
                  <ConcatShortcut
                    selected={selectedOption?.value}
                    concatModelName={concatModelName}
                    setSelected={setSelectedByShortcut}
                  />
                )}
              </div>
            )}
            {additionalButton && additionalButton}
          </div>
        </FormGroup>
      </Col>
    );
  }
);

export default AsyncComboBox;
